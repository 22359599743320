import * as actionTypes from "../../actions/exportCsv/actionTypes";
import { updateObject } from "../../utility/utility";

const initialState = {
  exportError: [],
  exportLoading: false,
  exportFrontUserList: [],
  exportRePaymentDistributionList: [],
  exportDistributionViewList: [],
  exportRePaymentReceivedList: [],
  exportWithdrawList: [],
  exportTransactionsList: [],
  exportManualKYCList: [],
};

const exportingStart = (state, action) => {
  return updateObject(state, {
    exportLoading: true,
    exportError: [],
    exportFrontUserList: [],
    exportRePaymentDistributionList: [],
    exportDistributionViewList: [],
    exportRePaymentReceivedList: [],
    exportWithdrawList: [],
    exportTransactionsList: [],
    exportManualKYCList: [],
  });
};

const exportCSVFail = (state, action) => {
  return updateObject(state, {
    exportError: action.error,
    exportLoading: false,
  });
};

const getFrontUserCSVListSuccess = (state, action) => {
  return updateObject(state, {
    exportLoading: false,
    exportError: [],
    exportFrontUserList: action.response,
  });
};

const getRePaymentDistributionListSuccess = (state, action) => {
  return updateObject(state, {
    exportLoading: false,
    exportError: [],
    exportRePaymentDistributionList: action.response,
  });
};

const getDistributionViewCSVListSuccess = (state, action) => {
  return updateObject(state, {
    exportLoading: false,
    exportError: [],
    exportDistributionViewList: action.response,
  });
};

const getRePaymentReceivedCSVListSuccess = (state, action) => {
  return updateObject(state, {
    exportLoading: false,
    exportError: [],
    exportRePaymentReceivedList: action.response,
  });
};

const getWithdrawExportStart = (state, action) => {
  return updateObject(state, {
    exportLoading: false,
    exportError: [],
    exportWithdrawList: [],
  });
};

const getWithdrawCSVListSuccess = (state, action) => {
  return updateObject(state, {
    exportLoading: false,
    exportError: [],
    exportWithdrawList: action.response,
  });
};

const getTransactionsExportStart = (state, action) => {
  return updateObject(state, {
    exportLoading: false,
    exportError: [],
    exportTransactionsList: [],
  });
};

const getTransactionsCSVListSuccess = (state, action) => {
  return updateObject(state, {
    exportLoading: false,
    exportError: [],
    exportTransactionsList: action.response,
  });
};

const getManualKYCExportStart = (state, action) => {
  return updateObject(state, {
    exportLoading: false,
    exportError: [],
    exportManualKYCList: [],
  });
};

const getManualKYCCSVListSuccess = (state, action) => {
  return updateObject(state, {
    exportLoading: false,
    exportError: [],
    exportManualKYCList: action.response,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.EXPORT_CSV_START:
      return exportingStart(state, action);
    case actionTypes.EXPORT_CSV_COMMON_ERROR:
      return exportCSVFail(state, action);
    case actionTypes.EXPORT_CSV_FRONT_USERS_SUCCESS:
      return getFrontUserCSVListSuccess(state, action);
    case actionTypes.GET_REPAYMENT_DISTRIBUTION_CSV_LIST_SUCCESS:
      return getRePaymentDistributionListSuccess(state, action);
    case actionTypes.GET_DISTRIBUTION_VIEW_CSV_LIST_SUCCESS:
      return getDistributionViewCSVListSuccess(state, action);
    case actionTypes.GET_REPAYMENT_RECEIVED_CSV_LIST_SUCCESS:
      return getRePaymentReceivedCSVListSuccess(state, action);

    case actionTypes.START_EXPORT_CSV_WITHDRAW_REQUESTS:
      return getWithdrawExportStart(state, action);
    case actionTypes.EXPORT_CSV_WITHDRAW_REQUESTS_SUCCESS:
      return getWithdrawCSVListSuccess(state, action);
    case actionTypes.START_EXPORT_CSV_TRANSACTIONS_REQUESTS:
      return getTransactionsExportStart(state, action);
    case actionTypes.EXPORT_CSV_TRANSACTIONS_REQUESTS_SUCCESS:
      return getTransactionsCSVListSuccess(state, action);

    case actionTypes.START_EXPORT_CSV_MANUALKYC_REQUESTS:
      return getManualKYCExportStart(state, action);
    case actionTypes.EXPORT_CSV_MANUALKYC_REQUESTS_SUCCESS:
      return getManualKYCCSVListSuccess(state, action);

    default:
      return state;
  }
};

export default reducer;
