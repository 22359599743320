import * as actionTypes from "./actiontTypes";

export const getChildrenRegistrationStart = () => {
  return {
    type: actionTypes.GET_CHILDREN_REGISTRATION_START,
  };
};

export const startIsLoading = () => {
  return {
    type: actionTypes.START_ISLOADING,
  };
};

export const getOmaniChildrenRegistration = (search, pageLimit) => {
  return {
    type: actionTypes.GET_OMANI_CHILDREN_REGISTRATION,
    search,
    pageLimit,
  };
};

export const getOmaniChildrenRegistrationSuccess = (response) => {
  return {
    type: actionTypes.GET_OMANI_CHILDREN_REGISTRATION_SUCCESS,
    response,
  };
};

export const getOmaniChildrenRegistrationDetail = (id) => {
  return {
    type: actionTypes.GET_OMANI_CHILDREN_REGISTRATION_DETAIL,
    id,
  };
};

export const getOmaniChildrenRegistrationDetailSuccess = (response) => {
  return {
    type: actionTypes.GET_OMANI_CHILDREN_REGISTRATION_DETAIL_SUCCESS,
    response,
  };
};

export const getForeignChildrenRegistration = (search, pageLimit) => {
  return {
    type: actionTypes.GET_FOREIGN_CHILDREN_REGISTRATION,
    search,
    pageLimit,
  };
};

export const getForeignChildrenRegistrationSuccess = (response) => {
  return {
    type: actionTypes.GET_FOREIGN_CHILDREN_REGISTRATION_SUCCESS,
    response,
  };
};

export const getForeignChildrenRegistrationDetail = (id) => {
  return {
    type: actionTypes.GET_FOREIGN_CHILDREN_REGISTRATION_DETAIL,
    id,
  };
};

export const getForeignChildrenRegistrationDetailSuccess = (response) => {
  return {
    type: actionTypes.GET_FOREIGN_CHILDREN_REGISTRATION_DETAIL_SUCCESS,
    response,
  };
};

export const updateChildrenRegistrationDetail = (accountData) => {
  return {
    type: actionTypes.UPDATE_CHILDREN_REGISTRATION_DETAIL,
    accountData,
  };
};

export const updateChildrenRegistration = (accountData) => {
  return {
    type: actionTypes.UPDATE_CHILDREN_REGISTRATION,
    accountData,
  };
};

export const updateChildrenRegistrationSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_CHILDREN_REGISTRATION_SUCCESS,
    response,
  };
};

export const updateChildrenRegistrationFail = (error) => {
  return {
    type: actionTypes.UPDATE_CHILDREN_REGISTRATION_FAIL,
    error,
  };
};
