export const GET_WALLET_TRANSACTIONS = "GET_WALLET_TRANSACTIONS";
export const GET_WALLET_TRANSACTIONS_SUCCESS =
  "GET_WALLET_TRANSACTIONS_SUCCESS";
export const UPDATE_WALLET_TRANSACTION = "UPDATE_WALLET_TRANSACTION";
export const UPDATE_WALLET_TRANSACTION_SUCCESS =
  "UPDATE_WALLET_TRANSACTION_SUCCESS";
export const WALLET_FAIL = "WALLET_FAIL";
export const WALLET_START = "WALLET_START";

export const GET_USER_WALLET_DETAIL = "GET_USER_WALLET_DETAIL";
export const GET_USER_WALLET_DETAIL_SUCCESS = "GET_USER_WALLET_DETAIL_SUCCESS";
export const ADMIN_USER_WALLET_TOPUP = "ADMIN_USER_WALLET_TOPUP";
export const ADMIN_USER_WALLET_TOPUP_SUCCESS =
  "ADMIN_USER_WALLET_TOPUP_SUCCESS";
