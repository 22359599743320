import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  MixedWidget1,
  ListsWidget9,
  StatsWidget11,
  StatsWidget12,
  AdvanceTablesWidget2,
} from "../widgets";

export function Demo1Dashboard() {
  const { currentPermissions } = useSelector((state) => state.role);

  return (
    <>
      <div className="row">
        {currentPermissions?.dashboard_revenue && (
          <div className="col-lg-6 col-xxl-4">
            <MixedWidget1 className="card-stretch gutter-b" />
          </div>
        )}
        {currentPermissions?.dashboard_recent_activities && (
          <div className="col-lg-6 col-xxl-4">
            <ListsWidget9 className="card-stretch gutter-b" />
          </div>
        )}
        {currentPermissions?.dashboard_total_investments && (
          <div className="col-lg-6 col-xxl-4">
            <StatsWidget11 className="card-stretch gutter-b" />
          </div>
        )}
        {currentPermissions?.dashboard_re_payments && (
          <div className="col-lg-6 col-xxl-4 order-1 order-xxl-1">
            <StatsWidget12 className="card-stretch gutter-b" />
          </div>
        )}
        {currentPermissions?.dashboard_pending_campaigns && (
          <div className="col-xxl-8 order-2 order-xxl-1">
            <AdvanceTablesWidget2 className="card-stretch gutter-b" />
          </div>
        )}
      </div>
    </>
  );
}
