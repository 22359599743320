import { TESTIMONIALS_API } from "../actions/apiCollections";
import { put, call } from "redux-saga/effects";
import axios from "../../config/AxiosConfig";
import { bulkDeleteTestimonialSuccess, bulkUpdateTestimonialSuccess, createTestimonialSuccess, deleteTestimonialSuccess, getAllTestimonialsSuccess, getTestimonialSuccess, testimonialFail, testimonialStart, updateTestimonialSuccess } from "../actions/testimonials";

export function* getAllTestimonialsSaga() {
    yield put(testimonialStart());
    try {
      const response = yield axios.get(TESTIMONIALS_API);
      yield put(getAllTestimonialsSuccess(response.data.data.data));
    } catch (error) {
      yield put(testimonialFail(error.response));
    }
}

export function* getTestimonialSaga(action) {
    yield put(testimonialStart());
    try {
      const response = yield axios.get(TESTIMONIALS_API + action.id);
      yield put(getTestimonialSuccess(response.data.data.data));
    } catch (error) {
      yield put(testimonialFail(error.response));
    }
}

export function* addTestimonialSaga(action) {
    yield put(testimonialStart());
    try {
      const response = yield axios.post(TESTIMONIALS_API,action?.testimonial);
      yield put(createTestimonialSuccess(response.data.data.data));
      yield call(getAllTestimonialsSaga)
    } catch (error) {
      yield put(testimonialFail(error.response));
    }
}

export function* updateTestimonialSaga(action) {
    yield put(testimonialStart());
    try {
      const response = yield axios.patch(TESTIMONIALS_API + action?.id , action?.testimonial);
      yield put(updateTestimonialSuccess(response.data.data.data));
      yield call(getAllTestimonialsSaga)
    } catch (error) {
      yield put(testimonialFail(error.response));
    }
}

export function* deleteTestimonialSaga(action) {
    yield put(testimonialStart());
    try {
      const response = yield axios.delete(TESTIMONIALS_API + action.id);
      yield call(getAllTestimonialsSaga)
    } catch (error) {
      yield put(testimonialFail(error.response));
    }
}

export function* bulkUpdateTestimonialSaga(action) {
    yield put(testimonialStart());
    try {
      const response = yield axios.post(TESTIMONIALS_API + "bulk-updates", action?.bulkData);
      yield call(getAllTestimonialsSaga)
    } catch (error) {
      yield put(testimonialFail(error.response));
    }
}

export function* bulkDeleteTestimonialSaga(action) {
    yield put(testimonialStart());
    try {
      const response = yield axios.post(TESTIMONIALS_API + "bulk-delete", action?.bulkData);
      yield call(getAllTestimonialsSaga)
    } catch (error) {
      yield put(testimonialFail(error.response));
    }
}