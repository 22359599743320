/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getActivities } from "../../../../store/actions";
import { toAbsoluteUrl } from "../../../_helpers/AssetsHelpers";
import { getBaseImageURL } from "../../../../helpers";
import LanguageReplaceText from "../../../../_metronic/_helpers/activityHelper";
import { getRelativeTime } from "../../../../helpers/timezoneConverterHelper";
import * as actions from "../../../../store/actions/index";

export function ListsWidget9({ className }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const globalImagePath = useRef(getBaseImageURL());

  const { activities } = useSelector((state) => state.activity);
  const { generalSettings } = useSelector((state) => state.settings);

  useEffect(() => {
    dispatch(getActivities(1));
    dispatch(actions.getGeneralSettings());
  }, [dispatch]);

  const activity =
    activities?.length > 0 ? (
      activities.map((activity, key) => (
        <div key={key}>
          <div className="d-flex align-items-center py-7 border-bottom">
            <div className="symbol symbol-40 symbol-light-primary mr-5">
              <img
                className="symbol-label"
                alt="user"
                src={
                  activity?.thumbnail === undefined
                    ? toAbsoluteUrl(`/media/users/default.jpg`)
                    : `${globalImagePath.current}${activity?.thumbnail}`
                }
              />
            </div>
            <div className="font-weight-bold">
              <LanguageReplaceText
                languageText={activity?.languageText}
                textToReplace={activity?.textToReplace}
                defaultText={activity?.text}
              />
              <span className="text-muted d-block">
                {getRelativeTime(
                  activity?.createdAt,
                  generalSettings?.timezone
                )}
              </span>
            </div>
          </div>
        </div>
      ))
    ) : (
      <h1>No New Data</h1>
    );
  function viewAll() {
    history.push("/activities");
  }
  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* Header */}
        <div className="card-header align-items-center border-0">
          <h3 className="card-title font-weight-bolder text-dark">
            Recent Activities
          </h3>
        </div>
        {/* Body */}
        <div className="card-body pt-0">
          {activity?.slice(0, 5)}
          <div className="text-center mt-10">
            <button
              type="button"
              onClick={viewAll}
              className="btn btn-outline-primary font-weight-bolder"
            >
              View All Activity
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
