export const EXPORT_CSV_START = "EXPORT_CSV_START";
export const EXPORT_CSV_COMMON_ERROR = "EXPORT_CSV_COMMON_ERROR";
// Front User All Data Export
export const EXPORT_CSV_FRONT_USERS = "EXPORT_CSV_FRONT_USERS";
export const EXPORT_CSV_FRONT_USERS_SUCCESS = "EXPORT_CSV_FRONT_USERS_SUCCESS";
export const GET_REPAYMENT_DISTRIBUTION_CSV_LIST =
  "GET_REPAYMENT_DISTRIBUTION_CSV_LIST";
export const GET_REPAYMENT_DISTRIBUTION_CSV_LIST_SUCCESS =
  "GET_REPAYMENT_DISTRIBUTION_CSV_LIST_SUCCESS";
export const GET_DISTRIBUTION_VIEW_CSV_LIST = "GET_DISTRIBUTION_VIEW_CSV_LIST";
export const GET_DISTRIBUTION_VIEW_CSV_LIST_SUCCESS =
  "GET_DISTRIBUTION_VIEW_CSV_LIST_SUCCESS";
export const GET_REPAYMENT_RECEIVED_CSV_LIST =
  "GET_REPAYMENT_RECEIVED_CSV_LIST";
export const GET_REPAYMENT_RECEIVED_CSV_LIST_SUCCESS =
  "GET_REPAYMENT_RECEIVED_CSV_LIST_SUCCESS";

export const START_EXPORT_CSV_WITHDRAW_REQUESTS =
  "START_EXPORT_CSV_WITHDRAW_REQUESTS";
export const EXPORT_CSV_WITHDRAW_REQUESTS = "EXPORT_CSV_WITHDRAW_REQUESTS";
export const EXPORT_CSV_WITHDRAW_REQUESTS_SUCCESS =
  "EXPORT_CSV_WITHDRAW_REQUESTS_SUCCESS";

export const START_EXPORT_CSV_TRANSACTIONS_REQUESTS =
  "START_EXPORT_CSV_TRANSACTIONS_REQUESTS";
export const EXPORT_CSV_TRANSACTIONS_REQUESTS =
  "EXPORT_CSV_TRANSACTIONS_REQUESTS";
export const EXPORT_CSV_TRANSACTIONS_REQUESTS_SUCCESS =
  "EXPORT_CSV_TRANSACTIONS_REQUESTS_SUCCESS";

export const START_EXPORT_CSV_MANUALKYC_REQUESTS =
  "START_EXPORT_CSV_MANUALKYC_REQUESTS";
export const EXPORT_CSV_MANUALKYC_REQUESTS = "EXPORT_CSV_MANUALKYC_REQUESTS";
export const EXPORT_CSV_MANUALKYC_REQUESTS_SUCCESS =
  "EXPORT_CSV_MANUALKYC_REQUESTS_SUCCESS";
