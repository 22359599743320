/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Formik, Field, Form } from "formik";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Input } from "../../../../_metronic/_partials/controls";
import * as actions from "../../../../store/actions/index";
import { compose } from "redux";
import * as Yup from "yup";
import { FormGroup, Alert, Modal, Button } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { toast, ToastContainer } from "react-toastify";
import OtpInput from "react-otp-input";

import { toastifyConstant } from "../../../../helpers";
import {
  loadCaptchaEnginge,
  LoadCanvasTemplateNoReload,
  validateCaptcha,
} from "react-simple-captcha";
import { getPublicRecaptcha } from "../../../../store/actions/index";

const initialValues = {
  email: "",
  password: undefined,
};

let validationSchema = {};

function Login(props) {
  const adminLoginStatus = props?.recaptcha?.adminLoginStatus;
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [otp, setOtp] = useState();
  const [disableButton, setDisableButton] = useState(true);

  useEffect(() => {
    if (props.enableTwoFactorAuth) {
      setShow(true);
      setOtp();
      setDisableButton(true);
      setIsLoading(false);
    }
  }, [props.enableTwoFactorAuth]);

  useEffect(() => {
    if (props.twoFactorAuthError) {
      setIsLoading(false);
    }
  }, [props.twoFactorAuthError]);

  useEffect(() => {
    if (adminLoginStatus) {
      loadCaptchaEnginge(6, "#dcebff");
    }
  }, [adminLoginStatus]);

  if (adminLoginStatus) {
    initialValues.user_captcha_input = "";
    validationSchema = Yup.object().shape({
      email: Yup.string().required("Email is required"),
      password: Yup.string().required("Password is required"),
      user_captcha_input: Yup.string().required("Captcha is required"),
    });
  } else {
    validationSchema = Yup.object().shape({
      email: Yup.string().required("Email is required"),
      password: Yup.string().required("Password is required"),
    });
  }

  useEffect(() => {
    props.onGetPublicRecaptcha();
  }, []);

  const [showingAlert, setShowingAlert] = useState(false);

  const handleChange = (otp) => {
    props.onClearTwoFactorError();
    setOtp(otp);
    if (otp.length === 6) {
      setDisableButton(false);
      props.onVerifyMFA(props.enableTwoFactorAuth.email, otp);
      setIsLoading(true);
    } else {
      setDisableButton(true);
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    props.onClearTwoFactorData();
    props.onClearTwoFactorError();
    setShow(false);
    setOtp("");
    setDisableButton(true);
  };

  const clearOtp = () => {
    props.onClearTwoFactorError();
    setOtp("");
    setDisableButton(true);
    setIsLoading(false);
  };

  const handleVerify = () => {
    props.onVerifyMFA(props.enableTwoFactorAuth.email, otp);
  };

  const getInputClasses = (meta, fieldname) => {
    let result = "form-control form-control-solid h-auto py-5 px-6 ";
    if (meta.touched && meta.error) {
      result += " is-invalid";
    }

    if (meta.touched && !meta.error) {
      result += " is-valid";
    }

    return result;
  };
  let errorMessage = "";
  let authRedirect = "";
  if (props.error) {
    errorMessage = (
      <Alert
        variant="danger"
        onClose={() => setShowingAlert(false)}
        dismissible
      >
        {props.error}
      </Alert>
    );
  }

  return (
    <div className="login-form login-signin">
      <ToastContainer />
      {/* begin::Head */}
      {authRedirect}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">
          Enter your email and password
        </p>
      </div>
      {errorMessage}

      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(fields) => {
          if (adminLoginStatus) {
            let user_captcha_value = fields.user_captcha_input;
            if (validateCaptcha(user_captcha_value) === true) {
              props.onAuth(fields.email, fields.password);
              window.scrollTo({ top: 0, behavior: "smooth" });
              loadCaptchaEnginge(6);
            } else {
              toast.error("Please Enter Valid Captcha", {
                toastifyConstant,
                toastId: "error",
              });
            }
          } else {
            props.onAuth(fields.email, fields.password);
          }
        }}
      >
        {({ isValid, dirty }) => (
          <Form className="form">
            <FormGroup>
              <Field
                name="email"
                component={Input}
                placeholder="Email"
                label="Email"
              >
                {({ field, form, meta }) => (
                  <div>
                    <input
                      type="email"
                      {...field}
                      className={`${getInputClasses(meta)}`}
                      id="loginEmail"
                      placeholder="Enter Email"
                    />
                    {meta.touched && meta.error && (
                      <div className="error invalid-feedback">{meta.error}</div>
                    )}
                  </div>
                )}
              </Field>
            </FormGroup>

            <FormGroup>
              <Field
                name="password"
                component={Input}
                placeholder="Password"
                label="Password"
              >
                {({ field, form, meta }) => (
                  <div>
                    <input
                      maxLength="20"
                      type="password"
                      {...field}
                      className={`${getInputClasses(meta)}`}
                      id="loginPassword"
                      placeholder="Enter Password"
                    />
                    {meta.touched && meta.error && (
                      <div className="error invalid-feedback">{meta.error}</div>
                    )}
                  </div>
                )}
              </Field>
            </FormGroup>
            {adminLoginStatus ? (
              <FormGroup>
                <LoadCanvasTemplateNoReload />
                <Field
                  name="user_captcha_input"
                  id="user_captcha_input"
                  component={Input}
                  placeholder="Enter captcha"
                  label="Captcha"
                >
                  {({ field, form, meta }) => (
                    <div>
                      <input
                        maxLength="20"
                        type="text"
                        {...field}
                        className={`${getInputClasses(meta)}`}
                        placeholder="Enter Captcha"
                      />
                      {meta.touched && meta.error && (
                        <div className="error invalid-feedback">
                          {meta.error}
                        </div>
                      )}
                    </div>
                  )}
                </Field>
              </FormGroup>
            ) : null}
            {/* begin::Actions */}
            <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
              <Link
                to="/auth/forgot-password"
                className="text-dark-50 text-hover-primary my-3 mr-2"
                id="loginForgotPassword"
              >
                <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
              </Link>

              <button
                id="loginSubmit"
                type="submit"
                disabled={!isValid}
                className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
              >
                {"Sign In"}
              </button>
            </div>
            {/* end::Actions */}
          </Form>
        )}
      </Formik>
      {/*end::Form*/}

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {props.enableTwoFactorAuth?.twoFactorAuthVerified === "no"
              ? "Enable 2FA"
              : "Verify 2FA"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {props.twoFactorAuthError ? (
            <Alert variant="danger">{props.twoFactorAuthError}</Alert>
          ) : null}
          <p>
            1. Download and install an app Google Authenticator on your mobile
            device.{" "}
            <a
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2"
              className="mr-3"
              target="_blank"
              rel="noreferrer"
            >
              Goole Playstore
            </a>
            <a
              href="https://apps.apple.com/us/app/google-authenticator/id388497605"
              target="_blank"
              rel="noreferrer"
            >
              App store
            </a>
          </p>
          {props.enableTwoFactorAuth?.twoFactorAuthVerified === "no" && (
            <>
              <p>2. Scan the QR code.</p>
              <div className="d-flex justify-content-center mb-3">
                <img
                  src={props.enableTwoFactorAuth?.image}
                  width={"200px"}
                  alt="QR Code"
                />
              </div>
            </>
          )}
          <p>
            {props.enableTwoFactorAuth?.twoFactorAuthVerified === "no"
              ? "3"
              : "2"}{" "}
            Enter and verify the authentication code generated by the app.
          </p>
          <div className="d-flex justify-content-center">
            <OtpInput
              value={otp}
              inputStyle={{
                width: "3rem",
                height: "3rem",
                margin: "0 1rem",
                fontSize: "2rem",
                borderRadius: "4px",
                border: "1px solid rgba(0, 0, 0, .3)",
                textAlign: "center",
              }}
              onChange={(e) => handleChange(e)}
              numInputs={6}
              inputType="tel"
              renderSeparator={<span>-</span>}
              renderInput={(props) => <input {...props} />}
            />
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-between align-items-center">
          <Button variant="info" onClick={() => clearOtp()}>
            Clear
          </Button>
          <div>
            <Button
              variant="secondary"
              onClick={() => handleClose()}
              className="mr-2"
            >
              Cancel
            </Button>

            <Button
              variant="primary"
              onClick={() => handleVerify()}
              disabled={disableButton}
            >
              {isLoading ? "Wait..." : "Verify"}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    loading: state.auth.loading,
    error: state.auth.error,
    isAuthorized: state.auth.token,
    recaptcha: state.recaptcha.publicRecaptcha,
    enableTwoFactorAuth: state.auth.enableTwoFactorAuth,
    twoFactorAuthError: state.auth.twoFactorAuthError,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuth: (email, password) => dispatch(actions.auth(email, password)),
    onVerifyMFA: (email, mfaCode) =>
      dispatch(actions.verifyMFA(email, mfaCode)),
    onClearTwoFactorData: () => dispatch(actions.clearTwoFactorLoginData()),
    onClearTwoFactorError: () => dispatch(actions.clearTwoFactorLoginError()),
    onGetPublicRecaptcha: () => dispatch(getPublicRecaptcha()),
  };
};

export default compose(
  withTranslation(),
  connect(mapStateToProps, mapDispatchToProps)
)(Login);
