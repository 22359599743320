import { call, put } from "redux-saga/effects";
import axios from "../../config/AxiosConfig";

import { BANK_API } from "../actions/apiCollections";

import {
  banksStart,
  getBanksSuccess,
  getBankInformationSuccess,
} from "../actions/index";

export function* getAllBanksSaga(action) {
  yield put(banksStart());
  try {
    const lang = action.language ? `&language=${action.language}` : "";
    const response = yield axios.get(`${BANK_API}?sort=name${lang}`);
    yield put(getBanksSuccess(response.data.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* createBankSaga(action) {
  try {
    const response = yield axios.post(`${BANK_API}`, action.bankData);
    yield call(getAllBanksSaga, { language: action.bankData.language });
  } catch (error) {
    console.log(error);
  }
}

export function* updateBankSaga(action) {
  try {
    yield axios.patch(`${BANK_API}/${action.bankData.id}`, action.bankData);
    yield call(getAllBanksSaga, { language: action.bankData.language });
  } catch (error) {
    console.log(error);
  }
}

export function* deleteBankSaga(action) {
  try {
    yield axios.delete(`${BANK_API}/${action.id}`);
    yield call(getAllBanksSaga, { language: action.language });
  } catch (error) {
    console.log(error);
  }
}

export function* getBankInformationSaga(action) {
  try {
    const response = yield axios.get(`${BANK_API}/${action.id}`, {
      params: {
        language: action.language,
      },
    });
    yield put(getBankInformationSuccess(response.data.data.data));
  } catch (error) {
    console.log(error);
  }
}
