/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Tooltip,
  OverlayTrigger,
  Modal,
  Button,
  Form,
} from "react-bootstrap";
import { useIntl } from "react-intl";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { CSVLink } from "react-csv";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";
import classNames from "classnames";
import SVG from "react-inlinesvg";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  LoadingDialog,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { getSiteBaseURL, getDemoMode } from "../../../helpers";
import {
  getTransactions,
  updateTransaction,
  getSearchTransactions,
} from "../../../store/actions";
import { formatCurrency } from "../../../helpers/numberFormat";
import { convertNumberToDateString } from "../../../helpers/timezoneConverterHelper";
import SearchTransaction from "./SearchTransaction";
import TotalTransaction from "./TotalTransaction";
import * as actions from "../../../store/actions/index";

function Transaction({ history }) {
  const demoMode = getDemoMode();
  const siteBaseUrl = useRef(getSiteBaseURL());
  const methods = useForm({ mode: "onTouched" });
  const dispatch = useDispatch();
  const intl = useIntl();
  const [refund, setRefund] = useState(false);
  const [show, setShow] = useState(false);
  const [transactionInfo, setTransactionInfo] = useState();
  const [showMore, setShowMore] = useState(2);
  const [TransactionFilterData, setTransactionFilterData] = useState();

  const [exportDataModel, setExportDataModel] = useState(false);
  const [csvTransactionsList, setCsvTransactionsList] = useState([]);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm();

  const {
    formState: { errors: exportDataErrors },
    reset: exportDataReset,
    handleSubmit: exportDataHandleSubmit,
    control: exportDataControl,
  } = useForm({ mode: "onChange" });

  const { generalSettings } = useSelector((state) => state.settings);
  const { currentPermissions } = useSelector((state) => state.role);

  useEffect(() => {
    if (!currentPermissions?.admin_show_transactions) {
      history.push(`/dashboard`);
    }
  }, [currentPermissions?.admin_show_transactions]);

  useEffect(() => {
    dispatch(getTransactions(1));
  }, [dispatch]);

  const { transactions } = useSelector((state) => state.transaction);

  const { exportTransactionsList, exportLoading } = useSelector(
    (state) => state.exportAllCSV
  );

  const statusFilterList = [
    {
      value: "PENDING",
      label: intl.formatMessage({ id: "transaction.search.pending" }),
    },
    {
      value: "SUCCESS",
      label: intl.formatMessage({ id: "transaction.search.success" }),
    },
    {
      value: "FAIL",
      label: intl.formatMessage({ id: "transaction.search.fail" }),
    },
    {
      value: "REFUNDED",
      label: intl.formatMessage({ id: "transaction.search.refunded" }),
    },
  ];

  const loadMore = () => {
    setShowMore(showMore + 1);
    if (
      TransactionFilterData != undefined &&
      Object.keys(TransactionFilterData).length > 0
    ) {
      dispatch(getSearchTransactions(TransactionFilterData, showMore));
    } else {
      dispatch(getTransactions(showMore));
    }
  };

  const showTransactionDetail = (data) => {
    setShow(true);
    setTransactionInfo(data);
  };

  const handleClose = () => {
    setShow(false);
    setTransactionInfo();
  };

  const refundShow = (data) => {
    setRefund(true);
    setTransactionInfo(data);
    setValue("tranId", data?._id);
    setValue("preapprovalStatus", "REFUNDED");
    setValue("paymentStatus", 3);
  };

  const refundClose = () => {
    setRefund(false);
    reset();
  };

  const onSubmitRefund = (data) => {
    dispatch(updateTransaction(data));
    setRefund(false);
    reset();
  };
  const onSubmit = (data) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setTransactionFilterData(data);
    setShowMore(2);
    dispatch(getSearchTransactions(data, 1));
  };

  const handleExportClose = () => {
    exportDataReset();
    setExportDataModel(false);
    setCsvTransactionsList([]);
    dispatch(actions.getWithdrawExportStart());
  };

  const onSubmitExportWithdrawRequests = (data) => {
    dispatch(actions.getWithdrawExportStart());
    setCsvTransactionsList([]);
    data.startDate = Date.parse(data.startDate);
    data.endDate = Date.parse(data.endDate);
    dispatch(actions.getTransactionsCSVList(data));
  };

  // Export Data
  const transactionsRequestCSVHeaders = [
    { label: "Campaign Name", key: "campaignName" },
    { label: "Investor Name", key: "investorName" },
    { label: "Amount", key: "amount" },
    { label: "Earned Amount", key: "earnedAmount" },
    { label: "Transaction Number", key: "transactionNumber" },
    { label: "Gateway Name", key: "gatewayName" },
    { label: "Gateway Transaction ID", key: "gatewayTransactionID" },
    { label: "Transaction Status", key: "status" },
    { label: "Transaction Date", key: "createdAt" },
  ];

  useEffect(() => {
    if (exportTransactionsList?.docs?.length > 0) {
      let result = exportTransactionsList?.docs?.map((tsRequest) => ({
        campaignName: tsRequest?.companyId?.companyName,
        investorName: tsRequest?.user?.fullName,
        investorName: tsRequest?.user?.fullName,
        amount:
          tsRequest?.amount + " " + tsRequest?.campaignId?.equityCurrencyCode,
        earnedAmount:
          tsRequest?.earnedFee +
          " " +
          tsRequest?.campaignId?.equityCurrencyCode,
        transactionNumber: tsRequest?.transactionKey,
        gatewayName:
          tsRequest?.gatewayId?.paymentType === "offline"
            ? "Bank Transfer"
            : "Wallet Transfer",
        gatewayTransactionID: "-",
        status: tsRequest?.preapprovalStatus,
        createdAt: moment(tsRequest?.createdAt).format("DD/MM/YYYY HH:mm:ss"),
      }));
      setCsvTransactionsList(result);
    }
  }, [exportTransactionsList]);

  const transactionList =
    transactions?.docs?.length > 0 ? (
      transactions?.docs?.map((transaction, key) => (
        <tr key={key}>
          <td>
            <a
              href={`${siteBaseUrl.current}campaign-detail-page/${transaction?.companyId?.companySlug}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {generalSettings?.projectScriptType === 1
                ? transaction?.campaignId?.propertyName
                : transaction?.companyId?.companyName}
            </a>
          </td>
          <td>
            <a
              href={`${siteBaseUrl.current}profile/${transaction.user?.slug}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {transaction?.user?.firstName} {transaction?.user?.lastName}
            </a>
          </td>
          <td>
            {formatCurrency(
              transaction.amount ?? 0,
              generalSettings.currencySymbolSide,
              transaction?.campaignId?.equityCurrencySymbol,
              transaction.campaignId?.equityCurrencyCode,
              generalSettings.decimalPoints
            )}
          </td>
          <OverlayTrigger
            key={key}
            placement={"right"}
            overlay={
              <Tooltip>
                <span>
                  Transaction Fees:
                  <b> {transaction?.earnedFee ?? 0}</b>
                </span>
                <br />
                <span>
                  Earned Fees Percentage:
                  <b> {transaction?.earnedFeeDetail?.earnedFeesPercentage}</b>
                </span>
                <br />
                <span>
                  Earned Flat Fees:
                  <b> {transaction?.earnedFeeDetail?.earnedFlatFees}</b>
                </span>
              </Tooltip>
            }
          >
            <td>
              {formatCurrency(
                transaction.earnedFee ?? 0,
                generalSettings.currencySymbolSide,
                transaction?.campaignId?.equityCurrencySymbol,
                transaction.campaignId?.equityCurrencyCode,
                generalSettings.decimalPoints
              )}
            </td>
          </OverlayTrigger>
          <td>
            {convertNumberToDateString(
              transaction?.createdAt,
              generalSettings?.timezone,
              generalSettings.dateFormat
            ) ?? "-"}
          </td>
          <td>
            <span
              className={`label label-lg ${
                transaction.preapprovalStatus === "SUCCESS"
                  ? "label-light-success"
                  : transaction.preapprovalStatus === "PENDING"
                  ? "label-light-default"
                  : "label-light-warning"
              } label-inline`}
            >
              {transaction.preapprovalStatus === "SUCCESS"
                ? "Success"
                : transaction.preapprovalStatus === "PENDING"
                ? "Pending"
                : "Refunded"}
            </span>
          </td>
          {currentPermissions?.admin_view_transaction ||
          currentPermissions?.admin_refund_transaction ? (
            <td>
              {currentPermissions?.admin_view_transaction && (
                <OverlayTrigger
                  popperConfig={{
                    modifiers: {
                      preventOverflow: {
                        enabled: false,
                      },
                      hide: {
                        enabled: false,
                      },
                    },
                  }}
                  placement="bottom"
                  overlay={<Tooltip>View</Tooltip>}
                >
                  <button
                    onClick={() => showTransactionDetail(transaction)}
                    id={`kt_view_transaction_toggle_${key}`}
                    className="btn btn-icon btn-light btn-hover-success btn-sm ml-3"
                  >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG
                        title=" "
                        src={toAbsoluteUrl(
                          "/media/svg/icons/General/Visible.svg"
                        )}
                      />
                    </span>
                  </button>
                </OverlayTrigger>
              )}
              {currentPermissions?.admin_refund_transaction ? (
                <>
                  {transaction?.campaignId?.status === 2 &&
                  transaction?.paymentStatus !== 2 ? (
                    <OverlayTrigger
                      popperConfig={{
                        modifiers: {
                          preventOverflow: {
                            enabled: false,
                          },
                          hide: {
                            enabled: false,
                          },
                        },
                      }}
                      placement="bottom"
                      overlay={<Tooltip>Refund</Tooltip>}
                    >
                      <button
                        disabled={
                          transaction.preapprovalStatus === "REFUNDED" ||
                          transaction.preapprovalStatus === "PENDING"
                        }
                        onClick={() => refundShow(transaction)}
                        id="kt_view_transaction_toggle"
                        className="btn btn-icon btn-light btn-hover-success btn-sm ml-3"
                      >
                        <span className="svg-icon svg-icon-md svg-icon-success">
                          <SVG
                            title=" "
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Shopping/Money.svg"
                            )}
                          />
                        </span>
                      </button>
                    </OverlayTrigger>
                  ) : null}
                </>
              ) : null}
            </td>
          ) : null}
        </tr>
      ))
    ) : (
      <tr className="text-center">
        <td colSpan={7}>
          {transactions?.docs?.length === 0 ? "No Record Found" : "Loading..."}
        </td>
      </tr>
    );
  if (transactionList?.docs?.length < 1) {
    return (
      <>
        <LoadingDialog />
      </>
    );
  } else {
    return (
      <>
        <FormProvider {...methods}>
          <Form
            name="searchTransaction"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <SearchTransaction></SearchTransaction>
          </Form>
        </FormProvider>
        {transactions?.totalAmountWiseDetail?.length > 0 ? (
          <TotalTransaction
            totalAmountWiseDetail={transactions?.totalAmountWiseDetail}
          ></TotalTransaction>
        ) : null}
        <Card>
          <CardHeader title="Transactions">
            <CardHeaderToolbar>
              {currentPermissions?.admin_add_transaction && (
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => history.push(`/add-transaction`)}
                >
                  Add Transaction
                </button>
              )}
              {currentPermissions?.admin_export_transactions && (
                <Button
                  className="btn btn-primary ml-2"
                  onClick={() => {
                    setExportDataModel(true);
                    exportDataReset();
                    setCsvTransactionsList([]);
                    dispatch(actions.getTransactionsExportStart());
                  }}
                >
                  Export Data
                </Button>
              )}
            </CardHeaderToolbar>
          </CardHeader>

          <CardBody>
            <Table
              responsive
              className="table-head-custom table-vertical-center"
            >
              <thead>
                <tr>
                  <th>Campaign</th>
                  <th>Investor</th>
                  <th>Amount</th>
                  <th>Earned</th>
                  <th>Date</th>
                  <th>Status</th>
                  {currentPermissions?.admin_view_transaction ||
                  currentPermissions?.admin_refund_transaction ? (
                    <th className="width110">Action</th>
                  ) : null}
                </tr>
              </thead>
              <tbody>{transactionList}</tbody>
            </Table>
            {transactions?.displayLoadMore ? (
              <div className="mt-5 text-center">
                <Button
                  type="button"
                  variant="primary"
                  onClick={() => loadMore()}
                >
                  Load More
                </Button>
              </div>
            ) : null}
          </CardBody>
        </Card>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              Transaction Detail: {transactionInfo?.companyId?.companyName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="offcanvas-content pr-5 mr-n5">
              <ul className="p-0 m-0">
                <li className="d-flex justify-content-between border-bottom py-3">
                  <span>Investor Name</span>
                  <strong className="ml-4">
                    {transactionInfo?.user?.firstName}{" "}
                    {transactionInfo?.user?.lastName}
                  </strong>
                </li>
                <li className="d-flex justify-content-between border-bottom py-3">
                  <span>Transaction ID</span>
                  <strong className="ml-4">
                    {transactionInfo?.preapprovalKey ?? "-"}
                  </strong>
                </li>
                <li className="d-flex justify-content-between border-bottom py-3">
                  <span>Gateway Name</span>
                  <strong className="ml-4">
                    {transactionInfo?.gatewayId?.title}
                  </strong>
                </li>
                <li className="d-flex justify-content-between border-bottom py-3">
                  <span>Gateway Transaction ID</span>
                  <strong className="ml-4">
                    {transactionInfo?.paymentConfirmationId ?? "-"}
                  </strong>
                </li>
                <li className="d-flex justify-content-between border-bottom py-3">
                  <span>Amount</span>
                  <strong className="ml-4">
                    {transactionInfo?.campaignId?.equityCurrencySymbol}{" "}
                    {transactionInfo?.amount}
                  </strong>
                </li>
                <li className="d-flex justify-content-between border-bottom py-3">
                  <span>Status</span>
                  <strong className="ml-4">
                    <span
                      className={`label label-lg ${
                        transactionInfo?.preapprovalStatus === "SUCCESS"
                          ? "label-light-success"
                          : transactionInfo?.preapprovalStatus === "PENDING"
                          ? "label-light-default"
                          : "label-light-warning"
                      } label-inline`}
                    >
                      {transactionInfo?.preapprovalStatus === "SUCCESS"
                        ? "Success"
                        : transactionInfo?.preapprovalStatus === "PENDING"
                        ? "Pending"
                        : "Refunded"}
                    </span>
                  </strong>
                </li>
                <li className="d-flex justify-content-between py-3">
                  <span>Transaction Date</span>
                  <strong className="ml-4">
                    {convertNumberToDateString(
                      transactionInfo?.createdAt,
                      generalSettings?.timezone,
                      generalSettings.dateFormat
                    ) ?? "-"}
                  </strong>
                </li>
              </ul>
            </div>
          </Modal.Body>
        </Modal>

        <Modal show={refund} onHide={refundClose}>
          <Form
            id="dealTypeSettings"
            className="form form-label-right"
            onSubmit={handleSubmit(onSubmitRefund)}
          >
            <Modal.Header closeButton>
              <Modal.Title>Refund</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="form-group">
                <label>
                  <label>Write reason why you refund this payment?</label>
                  <span className="text-important">*</span>
                </label>
                <Form.Control
                  name="refundReason"
                  as="textarea"
                  rows="6"
                  className={classNames("form-control", {
                    "is-invalid": errors.refundReason,
                  })}
                  {...register("refundReason", {
                    required: intl.formatMessage({ id: "COMMON.FIELD.REQ" }),
                    pattern: {
                      value: /^[^\s]+(\s+[^\s]+)*$/,
                      message: "Please enter valid refund reason",
                    },
                  })}
                />
                <ErrorMessage
                  errors={errors}
                  name="refundReason"
                  render={({ message }) => (
                    <div className="invalid-feedback d-block">{message}</div>
                  )}
                />
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button type="button" variant="secondary" onClick={refundClose}>
                Cancel
              </Button>
              <Button type="submit" variant="primary">
                Submit
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>

        <Modal
          show={exportDataModel}
          onHide={handleExportClose}
          backdrop="static"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Filter Top-up Requests </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form
              onSubmit={exportDataHandleSubmit(onSubmitExportWithdrawRequests)}
              className="form form-label-right"
            >
              <div className="row">
                <div className="col-md-6 form-group">
                  <label>Start Date</label>
                  <span className="text-danger">*</span>
                  <span name="startDate">
                    <Controller
                      name={"startDate"}
                      control={exportDataControl}
                      rules={{ required: "This field is required" }}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <DatePicker
                            className={classNames("form-control", {
                              "is-invalid": exportDataErrors.startDate,
                            })}
                            id="startDate"
                            onChange={onChange}
                            selected={value}
                            dateFormat="dd/MM/yyyy"
                            maxDate={new Date()}
                          />
                        );
                      }}
                    />
                    <ErrorMessage
                      errors={exportDataErrors}
                      name="startDate"
                      render={({ message }) => (
                        <div
                          className="invalid-feedback d-block"
                          id="startDate"
                        >
                          {message}
                        </div>
                      )}
                    />
                  </span>
                </div>
                <div className="col-md-6 form-group">
                  <label>End Date</label>
                  <span className="text-danger">*</span>
                  <span name="endDate">
                    <Controller
                      name={"endDate"}
                      control={exportDataControl}
                      rules={{ required: "This field is required" }}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <DatePicker
                            className={classNames("form-control", {
                              "is-invalid": exportDataErrors.endDate,
                            })}
                            id="endDate"
                            onChange={onChange}
                            selected={value}
                            dateFormat="dd/MM/yyyy"
                            maxDate={new Date()}
                          />
                        );
                      }}
                    />
                    <ErrorMessage
                      errors={exportDataErrors}
                      name="endDate"
                      render={({ message }) => (
                        <div className="invalid-feedback d-block" id="endDate">
                          {message}
                        </div>
                      )}
                    />
                  </span>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 form-group">
                  <label>Status</label>
                  <Controller
                    name={"status"}
                    control={exportDataControl}
                    rules={{
                      required: false,
                    }}
                    render={({ field: { value, onChange } }) => {
                      return (
                        <Select
                          placeholder={"Select Status"}
                          classNamePrefix="react-select"
                          className={classNames("react-select-container")}
                          options={statusFilterList}
                          value={statusFilterList.find(
                            (c) => c.value === value
                          )}
                          onChange={(val) => onChange(val.value)}
                        />
                      );
                    }}
                  />
                  <ErrorMessage
                    errors={exportDataErrors}
                    name="status"
                    render={({ message }) => (
                      <div className="invalid-feedback d-block">{message}</div>
                    )}
                  />
                </div>
              </div>

              <button
                type="submit"
                className="btn btn-primary mr-2"
                disabled={exportLoading}
              >
                Submit
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={handleExportClose}
                variant="secondary"
              >
                {intl.formatMessage({ id: "CLOSE" })}
              </button>
              <br />
              <br />
              {csvTransactionsList?.length > 0 ? (
                <CSVLink
                  data={csvTransactionsList}
                  headers={transactionsRequestCSVHeaders}
                  className="btn btn-primary mr-2"
                  disabled={exportLoading}
                  filename={"Transactions.csv"}
                >
                  <SVG
                    title=" "
                    src={toAbsoluteUrl("/media/svg/files/csv.svg")}
                  />{" "}
                  {exportLoading ? "Loading.." : "Export"}
                </CSVLink>
              ) : null}
              {exportTransactionsList?.docs?.length === 0 ? (
                <b className="text-danger">No record found with this filter</b>
              ) : null}
            </Form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Transaction;
