import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility/utility";

const initialState = {
  token: null,
  error: null,
  loading: false,
  user: [],
  gettingUser: true,
  enableTwoFactorAuth: null,
  twoFactorAuthError: null,
};

/**
 * @function authStart
 * @param {string} state - State before reducer.
 * @param {object} action - Action sent to reducer.
 * @returns {string} - Return new state
 */
const authStart = (state, action) => {
  return updateObject(state, {
    user: [],
    error: null,
    loading: true,
    gettingUser: true,
    twoFactorAuthError: null,
  });
};

/**
 * @function authSuccess
 * @param {string} state - State before reducer.
 * @param {object} action - Action sent to reducer.
 * @returns {string} - New state (authSuccess payload from action).
 */
const authSuccess = (state, action) => {
  return updateObject(state, {
    token: action.token,
    error: null,
    loading: false,
    enableTwoFactorAuth: null,
    twoFactorAuthError: null,
  });
};

/**
 * @function authFail
 * @param {string} state - State before reducer.
 * @param {object} action - Action sent to reducer.
 * @returns {string} - New state (authFail payload from action).
 */
const authFail = (state, action) => {
  return updateObject(state, { error: action.error, loading: false });
};

/**
 * @function authLogout
 * @param {string} state - State before reducer.
 * @param {object} action - Action sent to reducer.
 * @returns {string} - New state (authLogout payload from action).
 */
const authLogout = (state, action) => {
  return updateObject(state, {
    token: null,
    enableTwoFactorAuth: null,
    twoFactorAuthError: null,
  });
};

const authLoginAsUser = (state, action) => {
  return updateObject(state, {
    user: action.response,
    gettingUser: false,
  });
};

const enableTwoFactorAuth = (state, action) => {
  return updateObject(state, {
    enableTwoFactorAuth: action.response,
    twoFactorAuthError: null,
    gettingUser: false,
  });
};

const twoFactorLoginError = (state, action) => {
  return updateObject(state, {
    twoFactorAuthError: null,
    gettingUser: false,
  });
};

const clearTwoFactorLoginData = (state, action) => {
  return updateObject(state, {
    twoFactorAuthError: null,
    enableTwoFactorAuth: null,
    gettingUser: false,
  });
};

const mfaLoginError = (state, action) => {
  return updateObject(state, {
    twoFactorAuthError: action.error,
    gettingUser: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.AUTH_START:
      return authStart(state, action);
    case actionTypes.AUTH_SUCCESS:
      return authSuccess(state, action);
    case actionTypes.AUTH_FAIL:
      return authFail(state, action);
    case actionTypes.AUTH_LOGOUT:
      return authLogout(state, action);
    case actionTypes.AUTH_FRONT_END_SUCCESS:
      return authLoginAsUser(state, action);

    case actionTypes.TWO_FACTOR_AUTH_SUCCESS:
      return enableTwoFactorAuth(state, action);
    case actionTypes.CLEAR_TWO_FACTOR_LOGIN_DATA:
      return clearTwoFactorLoginData(state, action);
    case actionTypes.CLEAR_TWO_FACTOR_LOGIN_ERROR:
      return twoFactorLoginError(state, action);
    case actionTypes.VERIFY_TWO_FACTOR_AUTH_ERROR:
      return mfaLoginError(state, action);
    default:
      return state;
  }
};

export default reducer;
