import React, { useState, useCallback } from "react";
import SVG from "react-inlinesvg";
import axios from "../config/AxiosConfig";
import { OPEN_AWS_FILE_API } from "../store/actions/apiCollections";
import { toAbsoluteUrl } from "../_metronic/_helpers";

const S3FileOpener = ({ filePath, buttonText = "Download" }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const openFile = useCallback(async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await axios.post(OPEN_AWS_FILE_API, {
        fileName: filePath,
      });
      const s3Url = response.data.data.data;
      if (!s3Url) {
        throw new Error("No URL returned from API");
      }
      window.open(s3Url, "_blank", "noopener,noreferrer");

      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
    } catch (error) {
      console.error("Error opening file:", error);
      setError(error.message || "An error occurred while opening the file");
      setIsLoading(false);
    }
  }, [filePath]);

  return (
    <div>
      <button
        onClick={openFile}
        disabled={isLoading}
        className={`px-4 py-1 rounded border-0 ${
          isLoading ? "bg-gray-400" : "bg-blue-500 hover:bg-blue-600"
        }`}
      >
        {isLoading ? (
          "Opening..."
        ) : (
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              title=" "
              src={toAbsoluteUrl("/media/svg/icons/Files/DownloadedFile.svg")}
            />{" "}
            {buttonText}
          </span>
        )}
      </button>
      {error && <p className="text-red-500 mt-2">{error}</p>}
    </div>
  );
};

export default S3FileOpener;
