import React, { useLayoutEffect, useEffect, useRef, useState } from "react";
import {
  Form,
  Modal,
  OverlayTrigger,
  Table,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
  Button,
} from "react-bootstrap";
import { CSVLink } from "react-csv";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useForm, FormProvider, Controller } from "react-hook-form";
import SVG from "react-inlinesvg";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { ErrorMessage } from "@hookform/error-message";
import moment from "moment";
import { convertNumberToDateString } from "../../../../helpers/timezoneConverterHelper";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import SearchWithdrawRequests from "../SearchWalletTransactions";

import {
  getBaseImageURL,
  getDemoMode,
  getWalletCSSClassName,
  getWalletStatusCodes,
} from "../../../../helpers";
import { formatCurrency } from "../../../../helpers/numberFormat";
import * as actions from "../../../../store/actions/index";
import S3FileOpener from "../../../../helpers/S3FileOpener";

function WithdrawRequests({ history }) {
  const demoMode = getDemoMode();
  const dispatch = useDispatch();
  const globalImagePath = useRef(getBaseImageURL());
  const intl = useIntl();
  const methods = useForm({ mode: "onChange" });
  const {
    register: modalRegister,
    formState: { errors: modalErrors },
    reset: modalReset,
    handleSubmit: modalHandleSubmit,
    setValue: modalSetValue,
  } = useForm({ mode: "onChange" });

  const {
    formState: { errors: exportDataErrors },
    reset: exportDataReset,
    handleSubmit: exportDataHandleSubmit,
    control: exportDataControl,
  } = useForm({ mode: "onChange" });

  const [show, setShow] = useState(false);
  const [exportDataModel, setExportDataModel] = useState(false);
  const [showMore, setShowMore] = useState(2);
  const [searchData, setSearchData] = useState({ transactionType: 2 });
  const [selectedTransactionInfo, setSelectedTransactionInfo] = useState();
  const [selectedTransactionStatus, setSelectedTransactionStatus] = useState();
  const [csvWithdrawList, setCsvWithdrawList] = useState([]);
  const [paymentReceiptName, setPaymentReceiptName] = useState();

  const statusFilterList = [
    {
      value: "",
      label: intl.formatMessage({ id: "GLOBAL.SEARCH.STATUS" }),
    },
    {
      value: 0,
      label: intl.formatMessage({ id: "GLOBAL.STATUS.PENDING" }),
    },
    {
      value: 2,
      label: intl.formatMessage({ id: "GLOBAL.STATUS.SUCCESS" }),
    },
    {
      value: 3,
      label: intl.formatMessage({ id: "GLOBAL.STATUS.REJECTED" }),
    },
  ];

  const { generalSettings } = useSelector((state) => state.settings);
  const { currentPermissions } = useSelector((state) => state.role);

  useEffect(() => {
    if (!currentPermissions?.admin_show_wallet_withdraw_requests) {
      history.push(`/dashboard`);
    }
  }, [currentPermissions?.admin_show_wallet_withdraw_requests]);

  useEffect(() => {
    dispatch(actions.getWalletTransactions(1, searchData));
  }, [dispatch]);

  const { loadingTransactions, isSpinning, walletTransactions } = useSelector(
    (state) => state.wallet
  );

  const { exportWithdrawList, exportLoading } = useSelector(
    (state) => state.exportAllCSV
  );

  // Export Data
  const withdrawRequestCSVHeaders = [
    { label: "Full Name", key: "fullName" },
    { label: "Email", key: "email" },
    { label: "Wallet ID", key: "walletId" },
    { label: "Transaction Number", key: "transactionNumber" },
    { label: "Amount", key: "amount" },
    { label: "Transaction Fees", key: "transactionFees" },
    { label: "Type", key: "walletType" },
    { label: "Status", key: "status" },
    { label: "Bank Name", key: "bankName" },
    { label: "Account Number", key: "accountNumber" },
    { label: "Account Holder Name", key: "accountHolderName" },
    { label: "Country", key: "country" },
    { label: "Swift Number", key: "swiftNumber" },
    { label: "Account Type", key: "accountType" },
    { label: "Routing Number", key: "routingNumber" },
    { label: "createdAt", key: "createdAt" },
  ];

  useEffect(() => {
    if (exportWithdrawList?.docs?.length > 0) {
      let result = exportWithdrawList?.docs?.map((withdrawRequest) => ({
        fullName: withdrawRequest?.user?.fullName,
        email: withdrawRequest?.user?.email,
        walletId: withdrawRequest?.walletId,
        transactionNumber: withdrawRequest?.transactionNumber,
        amount:
          withdrawRequest?.amount + " " + withdrawRequest?.currencyId?.code,
        transactionFees:
          withdrawRequest?.feesDetails?.feesPercentage +
          " " +
          withdrawRequest?.currencyId?.code,
        walletType: withdrawRequest?.walletType,
        status: getWalletStatusCodes(withdrawRequest?.status),

        bankName: withdrawRequest?.userBankAccount?.bankName,
        accountNumber: withdrawRequest?.userBankAccount?.accountNumber,
        accountHolderName: withdrawRequest?.userBankAccount?.accountHolderName,
        country: withdrawRequest?.userBankAccount?.countryId?.name,
        swiftNumber: withdrawRequest?.userBankAccount?.swiftNumber,
        accountType: withdrawRequest?.userBankAccount?.accountType,
        routingNumber: withdrawRequest?.userBankAccount?.routingNumber,
        createdAt: moment(withdrawRequest?.createdAt).format(
          "DD/MM/YYYY HH:mm:ss"
        ),
      }));
      setCsvWithdrawList(result);
    }
  }, [exportWithdrawList]);

  useLayoutEffect(() => {
    if (generalSettings?.walletModule === "no") {
      history.push(`/`);
    }
  }, [generalSettings?.walletModule]);

  const handleClose = () => {
    modalReset();
    setSelectedTransactionInfo();
    setSelectedTransactionStatus();
    setShow(false);
  };

  const handleExportClose = () => {
    exportDataReset();
    setExportDataModel(false);
    setCsvWithdrawList([]);
    dispatch(actions.getWithdrawExportStart());
  };

  const loadMore = () => {
    setShowMore(showMore + 1);
    dispatch(actions.getWalletTransactions(showMore, searchData));
  };

  const onSubmit = (data) => {
    data.transactionType = 2;
    window.scrollTo({ top: 0, behavior: "smooth" });
    setShowMore(1);
    setSearchData(data);
    dispatch(actions.getWalletTransactions(1, data));
  };

  const onUpdateTransactionSubmit = (data) => {
    const withdrawdata = new FormData();
    withdrawdata.append("status", selectedTransactionStatus);
    withdrawdata.append("rejectReason", data.rejectReason);
    if (
      data?.acknowledgeDocument?.length > 0 &&
      typeof data?.acknowledgeDocument === "object"
    ) {
      withdrawdata.append("acknowledgeDocument", data.acknowledgeDocument[0]);
    }

    dispatch(
      actions.updateWalletTransaction(
        selectedTransactionInfo._id,
        withdrawdata,
        showMore,
        searchData
      )
    );
    handleClose();
  };

  const onSubmitExportWithdrawRequests = (data) => {
    dispatch(actions.getWithdrawExportStart());
    setCsvWithdrawList([]);
    data.startDate = Date.parse(data.startDate);
    data.endDate = Date.parse(data.endDate);
    data.transactionType = 2;
    dispatch(actions.getWithdrawCSVList(data));
  };

  const walletTransactionList =
    walletTransactions?.docs?.length > 0 ? (
      walletTransactions?.docs?.map((transaction, key) => (
        <tr key={key}>
          <td>{transaction?.user?.fullName}</td>
          <td>
            {convertNumberToDateString(
              transaction?.createdAt,
              generalSettings?.timezone,
              generalSettings.dateFormat
            ) ?? "-"}
          </td>
          <td>{transaction?.transactionNumber}</td>
          <td
            className={
              transaction?.walletType === "CREDIT"
                ? "text-success"
                : "text-danger"
            }
            style={{
              maxWidth: "15rem",
              wordBreak: "break-all",
            }}
          >
            {formatCurrency(
              transaction?.amount ?? 0,
              generalSettings?.currencySymbolSide,
              transaction?.currencyId?.symbol,
              transaction?.currencyId?.code,
              generalSettings?.decimalPoints
            )}
          </td>
          <OverlayTrigger
            key={key}
            placement={"right"}
            overlay={
              <Tooltip>
                <span className="row p-1">
                  Transaction Fees:{" "}
                  <b>
                    {" "}
                    {transaction?.feesDetails?.transactionFees.toFixed(2) ?? 0}
                  </b>
                </span>
                <span className="row p-1">
                  Gateway Fee Percentage:
                  <b> {transaction?.feesDetails?.feesPercentage.toFixed(2)}</b>
                </span>
                <span className="row p-1">
                  Gateway Flat Fee:
                  <b> {transaction?.feesDetails?.flatFees.toFixed(2)}</b>
                </span>
              </Tooltip>
            }
          >
            <td className="text-nowrap">
              {formatCurrency(
                transaction?.feesDetails?.transactionFees ?? 0,
                generalSettings?.currencySymbolSide,
                transaction?.currencyId?.symbol,
                transaction?.currencyId?.code,
                generalSettings?.decimalPoints
              )}
            </td>
          </OverlayTrigger>
          <td>{transaction?.gatewayId?.title ?? "-"}</td>
          <td>{transaction?.walletType}</td>
          <td>
            <span
              className={`label label-lg ${getWalletCSSClassName(
                transaction?.status
              )} label-inline`}
            >
              {getWalletStatusCodes(transaction?.status)}
            </span>
          </td>
          {currentPermissions?.admin_view_wallet_withdraw_requests && (
            <td>
              <>
                <OverlayTrigger
                  placement="bottom"
                  popperConfig={{
                    modifiers: {
                      preventOverflow: {
                        enabled: false,
                      },
                    },
                  }}
                  overlay={<Tooltip>Edit</Tooltip>}
                >
                  <button
                    onClick={() => {
                      setSelectedTransactionInfo(transaction);
                      setSelectedTransactionStatus(transaction?.status);
                      modalSetValue("rejectReason", transaction?.rejectReason);
                      setShow(true);
                    }}
                    className={`btn btn-icon btn-light btn-sm ml-1 mr-2 ${
                      transaction?.status === 0 ? "symbol" : ""
                    }`}
                  >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG
                        title=" "
                        src={toAbsoluteUrl(
                          "/media/svg/icons/General/Visible.svg"
                        )}
                      />
                    </span>
                    <i className="symbol-badge bg-danger" />
                  </button>
                </OverlayTrigger>
              </>
            </td>
          )}
        </tr>
      ))
    ) : (
      <tr className="text-center">
        <td colSpan={10}>
          <p>{loadingTransactions ? "Loading..." : "No Record Found!"}</p>
        </td>
      </tr>
    );

  return (
    <>
      <FormProvider {...methods}>
        <Form
          name="SearchWithdrawRequests"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <SearchWithdrawRequests searchData={{ transactionType: 2 }} />
        </Form>
      </FormProvider>
      <Card>
        <CardHeader title="Wallet Transaction List">
          <div className="header-buttons"></div>
          <CardHeaderToolbar>
            {currentPermissions?.admin_export_wallet_withdraw_requests ? (
              <Button
                className="btn btn-primary"
                onClick={() => {
                  setExportDataModel(true);
                  exportDataReset();
                  setCsvWithdrawList([]);
                  dispatch(actions.getWithdrawExportStart());
                }}
              >
                Export Data
              </Button>
            ) : null}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Table responsive className="table-head-custom table-vertical-center">
            <thead>
              <tr>
                <th>User</th>
                <th>Date</th>
                <th>Transaction ID</th>
                <th>Amount</th>
                <th>Fees</th>
                <th>Gateway</th>
                <th>Type</th>
                <th>Status</th>
                {currentPermissions?.admin_view_wallet_withdraw_requests && (
                  <th>Actions</th>
                )}
              </tr>
            </thead>
            <tbody>{walletTransactionList}</tbody>
          </Table>
          {walletTransactions?.displayLoadMore ? (
            <div className="mt-5 text-center">
              <button
                type="button"
                className="btn btn-primary custom-btn mt-5 text-center"
                onClick={() => loadMore()}
                disabled={isSpinning}
              >
                <span
                  className={
                    isSpinning
                      ? ` text-center spinner spinner-center p-5 m-5`
                      : ""
                  }
                >
                  {isSpinning ? "" : "Load More"}
                </span>
              </button>
            </div>
          ) : null}
        </CardBody>
      </Card>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="d-flex align-items-center">
              <div className="symbol symbol-50 mr-5">
                <div
                  className="symbol-label"
                  style={{
                    backgroundImage: `url(${toAbsoluteUrl(
                      selectedTransactionInfo?.user?.photo
                        ? `${globalImagePath.current}${selectedTransactionInfo?.user?.photo}`
                        : "/media/users/default.jpg"
                    )})`,
                  }}
                />
              </div>
              <div>
                <a
                  href="#"
                  className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
                >
                  {selectedTransactionInfo?.user?.fullName}
                </a>
                {selectedTransactionInfo?.user?.email && (
                  <div className="navi mt-2">
                    <a href={undefined} className="navi-item">
                      <span className="navi-link p-0 pb-2">
                        <span className="navi-icon mr-1">
                          <span className="svg-icon-lg svg-icon-primary">
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Communication/Mail-notification.svg"
                              )}
                            ></SVG>
                          </span>
                        </span>
                        <span className="navi-text text-muted text-hover-primary">
                          {selectedTransactionInfo?.user?.email}
                        </span>
                      </span>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="offcanvas-content pr-5 mr-n5">
            {selectedTransactionInfo?.gatewayId?.title === "Offline" ? (
              <>
                <div className="separator separator-dashed my-7" />
                <div>
                  <h5 className="mb-5">Acknowledgement Document</h5>
                  <ul className="p-0 m-0">
                    <li className="d-flex justify-content-between border-bottom py-3">
                      <span>Document</span>
                      <strong className="ml-4">
                        <S3FileOpener
                          filePath={
                            selectedTransactionInfo?.acknowledgeDocument
                          }
                          buttonText="View Document"
                        />
                      </strong>
                    </li>
                  </ul>
                </div>
              </>
            ) : null}
            {selectedTransactionInfo?.walletType === "DEBIT" &&
            selectedTransactionInfo?.userBankAccount ? (
              <>
                <div className="separator my-7" />
                <h5 className="mb-5">Bank Details</h5>
                <ul className="p-0 m-0">
                  {selectedTransactionInfo?.userBankAccount?.bankName ? (
                    <li className="d-flex justify-content-between border-bottom py-3">
                      <span>Bank Name</span>
                      <strong className="ml-4">
                        {selectedTransactionInfo?.userBankAccount?.bankName}
                      </strong>
                    </li>
                  ) : null}
                  {selectedTransactionInfo?.userBankAccount?.accountNumber ? (
                    <li className="d-flex justify-content-between border-bottom py-3">
                      <span>Account Number</span>
                      <strong className="ml-4">
                        {
                          selectedTransactionInfo?.userBankAccount
                            ?.accountNumber
                        }
                      </strong>
                    </li>
                  ) : null}
                  {selectedTransactionInfo?.userBankAccount
                    ?.accountHolderName ? (
                    <li className="d-flex justify-content-between border-bottom py-3">
                      <span>Account Holder Name</span>
                      <strong className="ml-4">
                        {
                          selectedTransactionInfo?.userBankAccount
                            ?.accountHolderName
                        }
                      </strong>
                    </li>
                  ) : null}
                  {selectedTransactionInfo?.userBankAccount?.countryId?.name ? (
                    <li
                      className={`d-flex justify-content-between ${
                        selectedTransactionInfo?.countryId?.countryCode !== "OM"
                          ? `border-bottom`
                          : null
                      } py-3`}
                    >
                      <span>Country</span>
                      <strong className="ml-4">
                        {
                          selectedTransactionInfo?.userBankAccount?.countryId
                            ?.name
                        }
                      </strong>
                    </li>
                  ) : null}
                  {selectedTransactionInfo?.userBankAccount?.countryId
                    ?.countryCode !== "OM" ? (
                    <>
                      {selectedTransactionInfo?.userBankAccount?.swiftNumber ? (
                        <li className="d-flex justify-content-between border-bottom py-3">
                          <span>Swift Number</span>
                          <strong className="ml-4">
                            {
                              selectedTransactionInfo?.userBankAccount
                                ?.swiftNumber
                            }
                          </strong>
                        </li>
                      ) : null}
                      {selectedTransactionInfo?.userBankAccount?.accountType ? (
                        <li className="d-flex justify-content-between border-bottom py-3">
                          <span>Account Type</span>
                          <strong className="ml-4">
                            {
                              selectedTransactionInfo?.userBankAccount
                                ?.accountType
                            }
                          </strong>
                        </li>
                      ) : null}
                      {selectedTransactionInfo?.userBankAccount
                        ?.routingNumber ? (
                        <li className="d-flex justify-content-between border-bottom py-3">
                          <span>Routing Number</span>
                          <strong className="ml-4">
                            {
                              selectedTransactionInfo?.userBankAccount
                                ?.routingNumber
                            }
                          </strong>
                        </li>
                      ) : null}
                    </>
                  ) : null}
                </ul>
              </>
            ) : null}
            <div className="separator  my-7" />

            <div>
              <h5 className="mb-5">Payment Details</h5>

              <ul className="p-0 m-0">
                {selectedTransactionInfo?.userSelfie ? (
                  <li className="d-flex justify-content-between border-bottom py-3">
                    <span>User Live photo</span>
                    <strong className="ml-4">
                      {(
                        <S3FileOpener
                          filePath={selectedTransactionInfo?.userSelfie}
                          buttonText="View Photo"
                        />
                      ) ?? "-"}
                    </strong>
                  </li>
                ) : null}

                {selectedTransactionInfo?.user?.kycDocumentFront ? (
                  <li className="d-flex justify-content-between border-bottom py-3">
                    <span>KYC Front Document</span>
                    <strong className="ml-4">
                      {(
                        <S3FileOpener
                          filePath={
                            selectedTransactionInfo?.user?.kycDocumentFront
                          }
                          buttonText="View Document"
                        />
                      ) ?? "-"}
                    </strong>
                  </li>
                ) : null}

                {selectedTransactionInfo?.user?.kycDocumentBack ? (
                  <li className="d-flex justify-content-between border-bottom py-3">
                    <span>KYC Back Document</span>
                    <strong className="ml-4">
                      {(
                        <S3FileOpener
                          filePath={
                            selectedTransactionInfo?.user?.kycDocumentBack
                          }
                          buttonText="View Document"
                        />
                      ) ?? "-"}
                    </strong>
                  </li>
                ) : null}

                <li className="d-flex justify-content-between border-bottom py-3">
                  <span>Wallet ID</span>
                  <strong className="ml-4">
                    {selectedTransactionInfo?.walletId ?? "-"}
                  </strong>
                </li>
                <li className="d-flex justify-content-between border-bottom py-3">
                  <span>Amount</span>
                  <strong className="ml-4">
                    {formatCurrency(
                      selectedTransactionInfo?.amount,
                      generalSettings.currencySymbolSide,
                      selectedTransactionInfo?.currencyId?.symbol,
                      selectedTransactionInfo?.currencyId?.code,
                      generalSettings.decimalPoints
                    )}
                  </strong>
                </li>
                <li className="d-flex justify-content-between border-bottom py-3">
                  <span>Transaction Fees:</span>
                  <strong className="ml-4">
                    {formatCurrency(
                      selectedTransactionInfo?.feesDetails?.transactionFees ??
                        0,
                      generalSettings?.currencySymbolSide,
                      selectedTransactionInfo?.currencyId?.symbol,
                      selectedTransactionInfo?.currencyId?.code,
                      generalSettings?.decimalPoints
                    )}
                  </strong>
                </li>
                <li className="d-flex justify-content-between border-bottom py-3">
                  <span>Status</span>
                  <span
                    className={`label label-lg ${getWalletCSSClassName(
                      selectedTransactionInfo?.status
                    )} label-inline`}
                  >
                    {getWalletStatusCodes(selectedTransactionInfo?.status)}
                  </span>
                </li>
                <Form
                  name="withdrawRequests"
                  onSubmit={modalHandleSubmit(onUpdateTransactionSubmit)}
                >
                  {selectedTransactionInfo?.acknowledgeDocument ? (
                    <>
                      <li className="d-flex justify-content-between border-bottom py-3">
                        <span>Payment Receipt</span>
                        <strong className="ml-4">
                          <S3FileOpener
                            filePath={
                              selectedTransactionInfo?.acknowledgeDocument
                            }
                            buttonText="View Receipt"
                          />
                        </strong>
                      </li>
                    </>
                  ) : null}
                  {selectedTransactionInfo?.status === 0 ||
                  selectedTransactionInfo?.status === 3 ? (
                    <>
                      <div
                        className="col-md-12 form-group mt-5"
                        style={{ marginLeft: "-12px" }}
                      >
                        <label>Payment Receipt</label>{" "}
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="acknowledgeDocument">
                              Please upload your payment receipt with PDF
                              format.
                            </Tooltip>
                          }
                        >
                          <i className="fa fa-question-circle fa-sm form-control-color" />
                        </OverlayTrigger>{" "}
                        <div className="custom-file-with-image">
                          <div className="custom-file text-nowrap">
                            <input
                              type="file"
                              name="acknowledgeDocument"
                              id="paymentReceiptUpload"
                              accept="pdf/*"
                              className={classNames("custom-file-input", {
                                "is-invalid": modalErrors.acknowledgeDocument,
                              })}
                              {...modalRegister("acknowledgeDocument", {
                                required: {
                                  value: false,
                                  message: "This field is required.",
                                },
                                onChange: (e) => {
                                  let paymentReceiptReader = new FileReader();
                                  const file = e.currentTarget.files[0];
                                  if (file)
                                    paymentReceiptReader.readAsDataURL(file);
                                  paymentReceiptReader.onload = (e) => {
                                    if (file)
                                      setPaymentReceiptName([
                                        paymentReceiptReader.result,
                                      ]);
                                    setPaymentReceiptName(file.name);
                                  };
                                },
                                validate: async (value) => {
                                  if (
                                    value.length > 0 &&
                                    typeof value == "object"
                                  ) {
                                    const fileTypes = ["pdf"];
                                    const fileType =
                                      value[0].name.split(".")[1];
                                    if (!fileTypes.includes(fileType))
                                      return `${intl.formatMessage({
                                        id: "FILEFORMATEVALID",
                                      })} (${fileTypes})`;

                                    const fileSize = Math.round(
                                      value[0].size / 1024
                                    );
                                    if (fileSize > 20000)
                                      return `${intl.formatMessage({
                                        id: "FILESIZE",
                                      })}`;
                                  }
                                },
                              })}
                            />
                            <label
                              className="custom-file-label custom-file-name"
                              htmlFor="paymentReceiptUpload"
                            >
                              {paymentReceiptName ??
                                "Please upload your payment receipt"}
                            </label>
                          </div>
                        </div>
                        <ErrorMessage
                          errors={modalErrors}
                          name="acknowledgeDocument"
                          render={({ message }) => (
                            <div className="invalid-feedback d-block">
                              {message}
                            </div>
                          )}
                        />
                      </div>
                    </>
                  ) : null}
                  {currentPermissions?.admin_wallet_withdraw_requests_action && (
                    <li className="d-flex justify-content-between pt-3">
                      <span>Action</span>
                      <ToggleButtonGroup type="radio" name="status">
                        <ToggleButton
                          variant="secondary"
                          size="sm"
                          className={
                            selectedTransactionStatus === 2 ? "active" : ""
                          }
                          onClick={() => setSelectedTransactionStatus(2)}
                          disabled={selectedTransactionInfo?.status === 1}
                        >
                          Approve
                        </ToggleButton>
                        <ToggleButton
                          variant="secondary"
                          className={
                            selectedTransactionStatus === 3 ? "active" : ""
                          }
                          size="sm"
                          onClick={() => setSelectedTransactionStatus(3)}
                          disabled={selectedTransactionInfo?.status === 1}
                        >
                          Reject
                        </ToggleButton>
                      </ToggleButtonGroup>
                    </li>
                  )}

                  {selectedTransactionStatus === 3 ? (
                    <>
                      <Form.Label className="mt-2">
                        Reason to Reject
                        <span className="text-important ml-2 mr-2">*</span>
                      </Form.Label>
                      <Form.Control
                        name="rejectReason"
                        as="textarea"
                        rows="3"
                        placeholder={"Reason to Reject"}
                        className={classNames("form-control", {
                          "is-invalid": modalErrors.rejectReason,
                        })}
                        {...modalRegister("rejectReason", {
                          required: intl.formatMessage({
                            id: "COMMON.FIELD.REQ",
                          }),
                        })}
                      />
                      <ErrorMessage
                        errors={modalErrors}
                        name="rejectReason"
                        render={({ message }) => (
                          <div className="invalid-feedback">{message}</div>
                        )}
                      />
                    </>
                  ) : null}
                  <div>
                    <hr />
                    {currentPermissions?.admin_wallet_withdraw_requests_action && (
                      <button
                        type="submit"
                        className="btn btn-primary ml-2"
                        disabled={
                          selectedTransactionStatus === 0 ||
                          selectedTransactionStatus ===
                            selectedTransactionInfo?.status
                        }
                      >
                        Confirm
                      </button>
                    )}

                    <button
                      type="button"
                      className="btn btn-secondary ml-2"
                      onClick={() => handleClose()}
                    >
                      Cancel
                    </button>
                  </div>
                </Form>
              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={exportDataModel}
        onHide={handleExportClose}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter Withdraw Requests </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={exportDataHandleSubmit(onSubmitExportWithdrawRequests)}
            className="form form-label-right"
          >
            <div className="row">
              <div className="col-md-6 form-group">
                <label>Start Date</label>
                <span className="text-danger">*</span>
                <span name="startDate">
                  <Controller
                    name={"startDate"}
                    control={exportDataControl}
                    rules={{ required: "This field is required" }}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <DatePicker
                          className={classNames("form-control", {
                            "is-invalid": exportDataErrors.startDate,
                          })}
                          id="startDate"
                          onChange={onChange}
                          selected={value}
                          dateFormat="dd/MM/yyyy"
                          maxDate={new Date()}
                        />
                      );
                    }}
                  />
                  <ErrorMessage
                    errors={exportDataErrors}
                    name="startDate"
                    render={({ message }) => (
                      <div className="invalid-feedback d-block" id="startDate">
                        {message}
                      </div>
                    )}
                  />
                </span>
              </div>
              <div className="col-md-6 form-group">
                <label>End Date</label>
                <span className="text-danger">*</span>
                <span name="endDate">
                  <Controller
                    name={"endDate"}
                    control={exportDataControl}
                    rules={{ required: "This field is required" }}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <DatePicker
                          className={classNames("form-control", {
                            "is-invalid": exportDataErrors.endDate,
                          })}
                          id="endDate"
                          onChange={onChange}
                          selected={value}
                          dateFormat="dd/MM/yyyy"
                          maxDate={new Date()}
                        />
                      );
                    }}
                  />
                  <ErrorMessage
                    errors={exportDataErrors}
                    name="endDate"
                    render={({ message }) => (
                      <div className="invalid-feedback d-block" id="endDate">
                        {message}
                      </div>
                    )}
                  />
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 form-group">
                <label>Status</label>
                <Controller
                  name={"status"}
                  control={exportDataControl}
                  rules={{
                    required: false,
                  }}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <Select
                        placeholder={"Select Status"}
                        classNamePrefix="react-select"
                        className={classNames("react-select-container")}
                        options={statusFilterList}
                        value={statusFilterList.find((c) => c.value === value)}
                        onChange={(val) => onChange(val.value)}
                      />
                    );
                  }}
                />
                <ErrorMessage
                  errors={exportDataErrors}
                  name="status"
                  render={({ message }) => (
                    <div className="invalid-feedback d-block">{message}</div>
                  )}
                />
              </div>
            </div>

            <button
              type="submit"
              className="btn btn-primary mr-2"
              disabled={exportLoading}
            >
              Submit
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleExportClose}
              variant="secondary"
            >
              {intl.formatMessage({ id: "CLOSE" })}
            </button>
            <br />
            <br />
            {csvWithdrawList?.length > 0 ? (
              <CSVLink
                data={csvWithdrawList}
                headers={withdrawRequestCSVHeaders}
                className="btn btn-primary mr-2"
                disabled={exportLoading}
                filename={"wallet-withdraw-requests-transactions.csv"}
              >
                <SVG
                  title=" "
                  src={toAbsoluteUrl("/media/svg/files/csv.svg")}
                />{" "}
                {exportLoading ? "Loading.." : "Export"}
              </CSVLink>
            ) : null}
            {exportWithdrawList?.docs?.length === 0 ? (
              <b className="text-danger">No record found with this filter</b>
            ) : null}
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default WithdrawRequests;
