import React, { useEffect, useState } from "react";
import { Form, Row, Col } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useIntl } from "react-intl";
import classNames from "classnames";
import { ErrorMessage } from "@hookform/error-message";
import Select from "react-select";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  Card,
  CardBody,
  CardHeader,
} from "../../../../_metronic/_partials/controls";
import { formatCurrency } from "../../../../helpers/numberFormat";
import * as actions from "../../../../store/actions/index";
import { use } from "i18next";

function AdminTopup({ history }) {
  const dispatch = useDispatch();
  const intl = useIntl();

  const { userWalletDetail, successAlert, error } = useSelector(
    (state) => state.wallet
  );
  const { generalSettings } = useSelector((state) => state.settings);

  const [paymentGatewayList] = useState([
    {
      value: "offline",
      label: "Bank Transfer",
    },
    {
      value: "oman-paymob",
      label: "Online Transfer",
    },
  ]);

  useEffect(() => {
    if (error) {
      alertBox(error, "error");
    }
  }, [error]);

  useEffect(() => {
    if (successAlert) {
      alertBox(
        "Top-up successful! User new balance has been updated.",
        "success"
      );
    }
  }, [successAlert]);

  const {
    register: register1,
    handleSubmit: handleSubmit1,
    formState: { errors: errors1 },
  } = useForm();
  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    control: control2,
    watch: watch2,
    unregister: unregister2,
  } = useForm();

  const onSubmit1 = (data) => {
    dispatch(actions.getUserWalletDetail(data.walletId));
  };

  const MySwal = withReactContent(Swal);
  const alertBox = (title, icon) => {
    MySwal.fire({
      title: title,
      icon: icon,
      allowOutsideClick: false,
      allowEscapeKey: false,
    }).then((result) => {
      if (result["isConfirmed"]) {
        history.push(`/wallet/topup-requests`);
      }
    });
  };

  const onSubmit2 = (data) => {
    data.user = userWalletDetail?.id;
    data.createdAt = Date.now();
    dispatch(actions.adminUserWalletTopup(data));
  };
  return (
    <>
      <Card>
        <CardHeader title="Add Manual Top-up">
          <div className="header-buttons"></div>
        </CardHeader>
        <CardBody>
          <Form onSubmit={handleSubmit1(onSubmit1)}>
            <Row className="justify-content-md-center">
              <Col md={12}>
                <Row className="form-group">
                  <Col md={4}>
                    <Form.Control
                      name="walletId"
                      placeholder={"Search By User Wallet ID"}
                      className={classNames("form-control")}
                      {...register1("walletId", {
                        required: intl.formatMessage({
                          id: "COMMON.FIELD.REQ",
                        }),
                      })}
                    />
                    <ErrorMessage
                      errors={errors1}
                      name="walletId"
                      render={({ message }) => (
                        <div className="invalid-feedback d-block">
                          {message}
                        </div>
                      )}
                    />
                  </Col>
                  <Col md={1}>
                    <button className="btn btn-primary" type="submit">
                      {intl.formatMessage({ id: "GLOBAL.SEARCH" })}
                    </button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
      {userWalletDetail ? (
        <>
          <Card>
            <CardBody>
              <div className="offcanvas-content pr-5 mr-n5">
                <h5 className="mb-5">User Detail</h5>
                <ul className="p-0 m-0">
                  <li className="d-flex justify-content-between border-bottom py-3">
                    <span>Wallet ID</span>
                    <strong className="ml-4">
                      {userWalletDetail?.walletId}
                    </strong>
                  </li>
                  <li className="d-flex justify-content-between border-bottom py-3">
                    <span>Legal Name</span>
                    <strong className="ml-4">
                      {userWalletDetail?.firstName} {userWalletDetail?.lastName}
                    </strong>
                  </li>
                  <li className="d-flex justify-content-between border-bottom py-3">
                    <span>Email</span>
                    <strong className="ml-4">
                      {userWalletDetail?.email ?? "-"}
                    </strong>
                  </li>
                  <li className="d-flex justify-content-between border-bottom py-3">
                    <span>Phone Number</span>
                    <strong className="ml-4">
                      {userWalletDetail?.phoneNumber}
                    </strong>
                  </li>
                  <li className="d-flex justify-content-between py-3">
                    <span>KYC Status</span>
                    <strong className="ml-4">
                      {userWalletDetail?.kycStatus ?? "-"}
                    </strong>
                  </li>
                </ul>
              </div>
            </CardBody>
          </Card>
          <Card>
            <CardHeader title="Top-up">
              <div className="header-buttons"></div>
            </CardHeader>
            <CardBody>
              <Form onSubmit={handleSubmit2(onSubmit2)}>
                <Row className="justify-content-md-center">
                  <Col md={12}>
                    <Row className="form-group">
                      <Col md={6}>
                        <label>Payment option</label>
                        <span className="text-danger">*</span>
                        <Controller
                          name={"paymentType"}
                          control={control2}
                          rules={{
                            required: intl.formatMessage({
                              id: "COMMON.FIELD.REQ",
                            }),
                          }}
                          render={({ field: { value, onChange } }) => {
                            return (
                              <Select
                                placeholder={"Select Payment option"}
                                classNamePrefix="react-select"
                                className={classNames("react-select-container")}
                                options={paymentGatewayList}
                                value={paymentGatewayList.find(
                                  (c) => c.value === value
                                )}
                                onChange={(val) => {
                                  onChange(val.value);
                                  if (val.value === "offline") {
                                    unregister2("transactionNumber");
                                  }
                                }}
                              />
                            );
                          }}
                        />
                        <ErrorMessage
                          errors={errors2}
                          name="paymentType"
                          render={({ message }) => (
                            <div className="invalid-feedback d-block">
                              {message}
                            </div>
                          )}
                        />
                      </Col>
                    </Row>
                  </Col>
                  <Col md={12}>
                    <Row className="form-group">
                      <Col md={6}>
                        <label>Amount</label>
                        <span className="text-danger">*</span>
                        <Form.Control
                          name="amount"
                          placeholder={"Enter amount"}
                          className={classNames("form-control", {
                            "is-invalid": errors2.amount,
                          })}
                          {...register2("amount", {
                            required: intl.formatMessage({
                              id: "COMMON.FIELD.REQ",
                            }),
                            pattern: {
                              value: /^\d*\.?\d*$/,
                              message: "Please enter a valid amount",
                            },
                          })}
                        />
                        <ErrorMessage
                          errors={errors2}
                          name="amount"
                          render={({ message }) => (
                            <div className="invalid-feedback d-block">
                              {message}
                            </div>
                          )}
                        />
                      </Col>
                      {watch2("paymentType") === "oman-paymob" ? (
                        <Col md={6}>
                          <label>Payment Order ID</label>
                          <span className="text-danger">*</span>
                          <Form.Control
                            name="transactionNumber"
                            placeholder={"Enter payment order id"}
                            className={classNames("form-control", {
                              "is-invalid": errors2.transactionNumber,
                            })}
                            {...register2("transactionNumber", {
                              required: {
                                value:
                                  watch2("paymentType") === "oman-paymob"
                                    ? true
                                    : false,
                                message: "Please enter paymen order id",
                              },
                              pattern: {
                                value: /^\d+$/,
                                message: "Please enter a valid paymen order id",
                              },
                            })}
                          />
                          <ErrorMessage
                            errors={errors2}
                            name="transactionNumber"
                            render={({ message }) => (
                              <div className="invalid-feedback d-block">
                                {message}
                              </div>
                            )}
                          />
                        </Col>
                      ) : null}
                    </Row>
                  </Col>
                </Row>
                <button type="submit" className="btn btn-primary">
                  Process
                </button>
              </Form>
            </CardBody>
          </Card>
        </>
      ) : null}
    </>
  );
}
export default AdminTopup;
