import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import classNames from "classnames";
import { useIntl } from "react-intl";
import moment from "moment";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Table, OverlayTrigger, Tooltip, Form, Modal } from "react-bootstrap";
import SVG from "react-inlinesvg";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardHeader,
  CardBody,
} from "../../../_metronic/_partials/controls";
import * as actions from "../../../store/actions/index";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

export const DatabaseManagement = ({ history }) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [databaseDetail, setDatabaseDetail] = useState();

  const { currentPermissions } = useSelector((state) => state.role);
  const { databaseBackups, downloadDBLink, generalSettings } = useSelector(
    (state) => state.settings
  );

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: "onChange",
  });

  useEffect(() => {
    if (!currentPermissions?.database_list) {
      history.push(`/dashboard`);
    }
  }, [currentPermissions?.database_list]);

  useEffect(() => {
    dispatch(actions.getDatabaseBackupList());
  }, [dispatch]);

  useEffect(() => {
    if (downloadDBLink) {
      if (downloadDBLink?.message) {
        alertBox(downloadDBLink?.message, "error", "error", "OK");
      }
      if (downloadDBLink?.url) {
        alertBox(downloadDBLink?.url, "success", "success", "Download");
      }
    }
  }, [downloadDBLink]);

  const downloadDatabase = (dbId) => {
    setShow(true);
    setDatabaseDetail(dbId);
  };

  const handleClose = () => {
    setShow(false);
    setDatabaseDetail();
    reset();
    dispatch(actions.clearDatabaseBackup());
    setLoading(false);
  };

  const onSubmitDatabaseBackup = (data) => {
    setLoading(true);
    let databaseBackupData = {
      databaseId: databaseDetail,
      password: data.passowrd,
    };
    dispatch(actions.downloadDatabaseBackup(databaseBackupData));
  };

  const MySwal = withReactContent(Swal);
  const alertBox = (text, icon, msgType, confirmText) => {
    MySwal.fire({
      title: msgType === "success" ? "Download database link generated." : text,
      icon: icon,
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonText: confirmText,
    }).then((result) => {
      if (result["isConfirmed"]) {
        if (msgType === "error") {
          handleClose();
          dispatch(actions.clearDatabaseBackup());
        }
        if (msgType === "success") {
          window.open(text, "_blank");
          dispatch(actions.clearDatabaseBackup());
          handleClose();
        }
      }
    });
  };

  const databaseBackupsList =
    databaseBackups?.length > 0 ? (
      databaseBackups?.map((dbdetail, key) => (
        <tr key={key}>
          <td>{dbdetail?.fileName}</td>
          <td>
            {moment(dbdetail?.createdAt).format(generalSettings.dateFormat)}
          </td>
          {currentPermissions?.download_database && (
            <td>
              <OverlayTrigger
                popperConfig={{
                  modifiers: {
                    preventOverflow: {
                      enabled: false,
                    },
                    hide: {
                      enabled: false,
                    },
                  },
                }}
                placement="bottom"
                overlay={<Tooltip>Download Database</Tooltip>}
              >
                <button
                  onClick={() => downloadDatabase(dbdetail?.id)}
                  id={`download_database_${key}`}
                  className="btn btn-icon btn-light btn-hover-primary btn-sm mr-1"
                >
                  <span className="svg-icon svg-icon-md svg-icon-primary">
                    <SVG
                      title=" "
                      src={toAbsoluteUrl("/media/svg/icons/Files/Download.svg")}
                    />
                  </span>
                </button>
              </OverlayTrigger>
            </td>
          )}
        </tr>
      ))
    ) : (
      <tr className="text-center">
        <td colSpan={3}>
          {databaseBackups?.length === 0 ? "No Record Found" : "Loading..."}
        </td>
      </tr>
    );
  return (
    <>
      <Card>
        <CardHeader title={"Database Management"} />
        <CardBody>
          <Table responsive className="table-head-custom table-vertical-center">
            <thead>
              <tr>
                <th>Database Backup Name</th>
                <th>date</th>
                {currentPermissions?.download_database && (
                  <th className="width180">Action</th>
                )}
              </tr>
            </thead>
            <tbody>{databaseBackupsList}</tbody>
          </Table>
        </CardBody>
      </Card>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Database Backup</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            "Secure database download: Admin enters password, system verifies,
            initiates encrypted file transfer if authorized."
          </p>
          <p>
            This brief description captures the essential elements of the
            feature:
          </p>
          <ul>
            <li>It's a database download function</li>
            <li>It requires a password (implying it's for admins only)</li>
            <li>The system performs authentication</li>
            <li>If authorized, the download begins</li>
            <li>The transfer is encrypted for security</li>
          </ul>

          <Form
            id="siteSettingsGeneral"
            className="form form-label-right"
            onSubmit={handleSubmit(onSubmitDatabaseBackup)}
          >
            <div className="form-group">
              <label>Password</label> <span className="text-danger">*</span>
              <Form.Control
                type="password"
                name="passowrd"
                className={classNames("form-control", {
                  "is-invalid": errors.passowrd,
                })}
                {...register("passowrd", {
                  required: "Password is required",
                })}
              />
              <ErrorMessage
                errors={errors}
                name="passowrd"
                render={({ message }) => (
                  <div className="invalid-feedback">{message}</div>
                )}
              />
            </div>
            <button
              type="submit"
              className="btn btn-primary ml-2"
              disabled={loading}
            >
              {loading ? "Loading" : "Download"}
            </button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};
