import axios from "../../../config/AxiosConfig";
import { put, call } from "redux-saga/effects";
import {
  WALLET_STATUS_UPDATE_API,
  WALLET_TRANSACTION_LIST_API,
  USER_WALLET_DETAIL_API,
  ADMIN_USER_WALLET_TOPUP_API,
} from "../../actions/apiCollections";
import * as actions from "../../actions/wallet/walletActions";

export function* getWalletTransactionsSaga(action) {
  yield put(actions.walletStart(action.pageLimit));
  try {
    const response = yield axios.get(`${WALLET_TRANSACTION_LIST_API}`, {
      params: {
        ...(action.searchData.status !== "" && {
          status: action.searchData.status,
        }),
        ...(action.searchData.search !== "" && {
          search: action.searchData.search,
        }),
        ...(action.searchData.transactionType !== "" && {
          transactionType: action.searchData.transactionType,
        }),
        ...(action.searchData.walletType !== "" && {
          walletType: action.searchData.walletType,
        }),
        limit: 10 * action.pageLimit,
      },
    });
    yield put(actions.getWalletTransactionsSuccess(response?.data?.data?.data));
  } catch (error) {
    yield put(actions.walletFail(error.response));
  }
}

export function* updateWalletTransactionSaga(action) {
  try {
    const response = yield axios.patch(
      `${WALLET_STATUS_UPDATE_API}/${action.id}/admin`,
      action.data
    );
    yield put(actions.updateWalletTransactionSuccess(response?.data?.data));
    const obj = {
      pageLimit: action.pageLimit,
      searchData: action.searchData,
    };

    yield call(getWalletTransactionsSaga, obj);
  } catch (error) {
    yield put(actions.walletFail(error.response));
  }
}

export function* getUserWalletDetailSaga(action) {
  yield put(actions.walletStart());
  try {
    const response = yield axios.get(
      `${USER_WALLET_DETAIL_API}${action.walletId}?lng=en`
    );
    yield put(actions.getUserWalletDetailSuccess(response?.data?.data?.data));
  } catch (error) {
    yield put(actions.walletFail(error.response.data.message));
  }
}

export function* adminUserWalletTopupSaga(action) {
  try {
    const response = yield axios.post(
      `${ADMIN_USER_WALLET_TOPUP_API}`,
      action.topupData
    );
    yield put(actions.adminUserWalletTopupSuccess(response?.data?.data));
    const obj = {
      pageLimit: 10,
    };

    yield call(getWalletTransactionsSaga, obj);
  } catch (error) {
    yield put(actions.walletFail(error.response));
  }
}
