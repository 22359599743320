import { put, call } from "redux-saga/effects";
import axios from "../../../config/AxiosConfig";
import { CHILDREN_REGISTRATION_API } from "../../actions/apiCollections";
import {
  startIsLoading,
  getOmaniChildrenRegistrationSuccess,
  getOmaniChildrenRegistrationDetailSuccess,
  getForeignChildrenRegistrationSuccess,
  getForeignChildrenRegistrationDetailSuccess,
  updateChildrenRegistrationSuccess,
  updateChildrenRegistrationFail,
} from "../../actions/childrenRegistration/childrenRegistrationActions";

export function* getOmaniChildrenRegistrationSaga(action) {
  try {
    const response = yield axios.get(
      `${CHILDREN_REGISTRATION_API}list-domestic-requests`,
      {
        params: {
          ...(action?.search?.search !== "" && {
            search: action?.search?.search,
          }),
          ...(action?.search?.status !== "" && {
            status: action?.search?.status,
          }),
          limit: 10 * action.pageLimit,
        },
      }
    );
    yield put(getOmaniChildrenRegistrationSuccess(response.data.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* getForeignChildrenRegistrationSaga(action) {
  try {
    const response = yield axios.get(
      `${CHILDREN_REGISTRATION_API}list-foreigner-requests`,
      {
        params: {
          ...(action?.search?.search !== "" && {
            search: action?.search?.search,
          }),
          ...(action?.search?.status !== "" && {
            status: action?.search?.status,
          }),
          limit: 10 * action.pageLimit,
        },
      }
    );
    yield put(getForeignChildrenRegistrationSuccess(response.data.data.data));
  } catch (error) {
    console.log(error);
  }
}

export function* getOmaniChildrenRegistrationDetailSaga(action) {
  try {
    const response = yield axios.get(
      `${CHILDREN_REGISTRATION_API}admin/${action.id}`
    );
    yield put(
      getOmaniChildrenRegistrationDetailSuccess(response.data.data.data)
    );
  } catch (error) {
    console.log(error);
  }
}

export function* getForeignChildrenRegistrationDetailSaga(action) {
  try {
    const response = yield axios.get(
      `${CHILDREN_REGISTRATION_API}admin/${action.id}`
    );
    yield put(
      getForeignChildrenRegistrationDetailSuccess(response.data.data.data)
    );
  } catch (error) {
    console.log(error);
  }
}

export function* updateChildrenRegistrationSaga(action) {
  try {
    yield axios.patch(
      CHILDREN_REGISTRATION_API + "admin/" + action.accountData.id,
      action.accountData
    );

    yield call(getOmaniChildrenRegistrationSaga);
    yield call(getForeignChildrenRegistrationSaga);
  } catch (error) {
    console.log(error);
  }
}

export function* editChildrenRegistrationSaga(action) {
  let id = new FormData();
  id = action.accountData;
  id = id.get("id");
  yield put(startIsLoading(true));
  try {
    const response = yield axios.patch(
      CHILDREN_REGISTRATION_API + "admin/" + id + "/update",
      action.accountData
    );
    yield put(updateChildrenRegistrationSuccess(response.data.data.data));
    yield call(getOmaniChildrenRegistrationSaga);
    yield call(getForeignChildrenRegistrationSaga);
    yield put(startIsLoading(false));
  } catch (error) {
    yield put(startIsLoading(false));
    yield put(updateChildrenRegistrationFail(error.response.data.message));
  }
}
