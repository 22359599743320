/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import {
  Table,
  Tooltip,
  OverlayTrigger,
  Modal,
  Button,
  Form,
} from "react-bootstrap";
import { useIntl } from "react-intl";
import { useForm, FormProvider } from "react-hook-form";
import SVG from "react-inlinesvg";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  LoadingDialog,
} from "../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import { getDemoMode } from "../../../helpers";
import {
  getNewsletters,
  getSearchNewsletters,
  deleteNewsletter,
} from "../../../store/actions";
import moment from "moment";
import { formatCurrency } from "../../../helpers/numberFormat";
import SearchNewsletter from "./SearchNewsletter";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

function Newsletter({ history }) {
  const demoMode = getDemoMode();
  const methods = useForm({ mode: "onTouched" });
  const dispatch = useDispatch();
  const intl = useIntl();
  const [show, setShow] = useState(false);
  const [transactionInfo, setTransactionInfo] = useState();
  const [showMore, setShowMore] = useState(2);
  const [TransactionFilterData, setTransactionFilterData] = useState();
  const campaignSWAL = withReactContent(Swal);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = useForm();

  const { generalSettings } = useSelector((state) => state.settings);
  const { currentPermissions } = useSelector((state) => state.role);

  useEffect(() => {
    if (!currentPermissions?.newsletters_list) {
      history.push(`/dashboard`);
    }
  }, [currentPermissions?.newsletters_list]);

  useEffect(() => {
    dispatch(getNewsletters(1));
  }, [dispatch]);

  const { newsletters } = useSelector((state) => state.newsletter);

  const loadMore = () => {
    setShowMore(showMore + 1);
    if (
      TransactionFilterData != undefined &&
      Object.keys(TransactionFilterData).length > 0
    ) {
      dispatch(getSearchNewsletters(TransactionFilterData, showMore));
    } else {
      dispatch(getNewsletters(showMore));
    }
  };

  const campaignActions = (id, title, text, variant) => {
    campaignSWAL
      .fire({
        title: title,
        text: text,
        icon: variant,
        confirmButtonText: "Yes",
        showCancelButton: true,
        cancelButtonText: "Cancel",
        allowOutsideClick: false,
        allowEscapeKey: false,
      })
      .then((result) => {
        if (result["isConfirmed"]) {
          dispatch(deleteNewsletter(id));
        }
      });
  };

  const showTransactionDetail = (data) => {
    setShow(true);
    setTransactionInfo(data);
  };

  const handleClose = () => {
    setShow(false);
    setTransactionInfo();
  };

  const onSubmit = (data) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setTransactionFilterData(data);
    setShowMore(2);
    dispatch(getSearchNewsletters(data, 1));
  };

  const transactionList =
    newsletters?.docs?.length > 0 ? (
      newsletters?.docs?.map((transaction, key) => (
        <tr key={key}>
          <td>
            {transaction?.firstName} {transaction?.lastName}
          </td>
          <td>{transaction?.email}</td>
          <td>
            {moment(transaction.createdAt).format(generalSettings.dateFormat) ??
              "-"}
          </td>
          <td>{transaction?.subscriptionType}</td>
          {currentPermissions?.view_newsletter ||
          currentPermissions?.delete_newsletter ? (
            <td>
              {currentPermissions?.view_newsletter && (
                <OverlayTrigger
                  popperConfig={{
                    modifiers: {
                      preventOverflow: {
                        enabled: false,
                      },
                      hide: {
                        enabled: false,
                      },
                    },
                  }}
                  placement="bottom"
                  overlay={<Tooltip>View</Tooltip>}
                >
                  <button
                    onClick={() => showTransactionDetail(transaction)}
                    id={`kt_view_transaction_toggle_${key}`}
                    className="btn btn-icon btn-light btn-hover-success btn-sm ml-3"
                  >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG
                        title=" "
                        src={toAbsoluteUrl(
                          "/media/svg/icons/General/Visible.svg"
                        )}
                      />
                    </span>
                  </button>
                </OverlayTrigger>
              )}
              {currentPermissions?.delete_newsletter && (
                <OverlayTrigger
                  popperConfig={{
                    modifiers: {
                      preventOverflow: {
                        enabled: false,
                      },
                      hide: {
                        enabled: false,
                      },
                    },
                  }}
                  placement="bottom"
                  overlay={<Tooltip>Delete</Tooltip>}
                >
                  <button
                    onClick={() =>
                      campaignActions(
                        transaction?._id,
                        "Confirm ?",
                        "Are you sure you want to delete this Subscriber ?",
                        "error"
                      )
                    }
                    id={`kt_view_transaction_toggle_${key}`}
                    className="btn btn-icon btn-light btn-hover-success btn-sm ml-3"
                  >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG
                        title=" "
                        src={toAbsoluteUrl(
                          "/media/svg/icons/General/Trash.svg"
                        )}
                      />
                    </span>
                  </button>
                </OverlayTrigger>
              )}
            </td>
          ) : null}
        </tr>
      ))
    ) : (
      <tr className="text-center">
        <td colSpan={7}>
          {newsletters?.docs?.length === 0 ? "No Record Found" : "Loading..."}
        </td>
      </tr>
    );
  if (transactionList?.docs?.length < 1) {
    return (
      <>
        <LoadingDialog />
      </>
    );
  } else {
    return (
      <>
        <FormProvider {...methods}>
          <Form
            name="searchNewsletter"
            onSubmit={methods.handleSubmit(onSubmit)}
          >
            <SearchNewsletter></SearchNewsletter>
          </Form>
        </FormProvider>
        <Card>
          <CardHeader title="Newsletters"></CardHeader>
          <CardBody>
            <Table
              responsive
              className="table-head-custom table-vertical-center"
            >
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Date</th>
                  <th>Subscription Type</th>
                  {currentPermissions?.view_newsletter ||
                  currentPermissions?.delete_newsletter ? (
                    <th className="width110">Action</th>
                  ) : null}
                </tr>
              </thead>
              <tbody>{transactionList}</tbody>
            </Table>
            {newsletters?.displayLoadMore ? (
              <div className="mt-5 text-center">
                <Button
                  type="button"
                  variant="primary"
                  onClick={() => loadMore()}
                >
                  Load More
                </Button>
              </div>
            ) : null}
          </CardBody>
        </Card>

        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              Subscriber Detail: {transactionInfo?.firstName}{" "}
              {transactionInfo?.lastName}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="offcanvas-content pr-5 mr-n5">
              <ul className="p-0 m-0">
                <li className="d-flex justify-content-between border-bottom py-3">
                  <span>Newsletter ID</span>
                  <strong className="ml-4">
                    {transactionInfo?.listId ?? "-"}
                  </strong>
                </li>
                <li className="d-flex justify-content-between border-bottom py-3">
                  <span>Email</span>
                  <strong className="ml-4">{transactionInfo?.email}</strong>
                </li>
                <li className="d-flex justify-content-between border-bottom py-3">
                  <span>Subscription Type</span>
                  <strong className="ml-4">
                    {transactionInfo?.subscriptionType ?? "-"}
                  </strong>
                </li>
                <li className="d-flex justify-content-between py-3">
                  <span>Date</span>
                  <strong className="ml-4">
                    {moment(transactionInfo?.createdAt).format(
                      generalSettings?.dateFormat
                    ) ?? "-"}
                  </strong>
                </li>
              </ul>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Newsletter;
