import React, { useLayoutEffect, useEffect, useRef, useState } from "react";
import {
  Form,
  Modal,
  OverlayTrigger,
  Table,
  ToggleButtonGroup,
  ToggleButton,
  Tooltip,
  Button,
} from "react-bootstrap";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { CSVLink } from "react-csv";
import Select from "react-select";
import DatePicker from "react-datepicker";
import moment from "moment";
import SVG from "react-inlinesvg";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import classNames from "classnames";
import { ErrorMessage } from "@hookform/error-message";
import { convertNumberToDateString } from "../../../../helpers/timezoneConverterHelper";

import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../../_metronic/_partials/controls";
import SearchTransactions from "../SearchWalletTransactions";

import {
  capitalize,
  getBaseImageURL,
  getDemoMode,
  getWalletCSSClassName,
  getWalletStatusCodes,
} from "../../../../helpers";
import { formatCurrency } from "../../../../helpers/numberFormat";
import * as actions from "../../../../store/actions/index";
import S3FileOpener from "../../../../helpers/S3FileOpener";

function WalletTransactions({ history }) {
  const demoMode = getDemoMode();
  const dispatch = useDispatch();
  const globalImagePath = useRef(getBaseImageURL());
  const intl = useIntl();
  const methods = useForm({ mode: "onChange" });
  const {
    register: modalRegister,
    formState: { errors: modalErrors },
    reset: modalReset,
    handleSubmit: modalHandleSubmit,
    setValue: modalSetValue,
  } = useForm({ mode: "onChange" });

  const {
    formState: { errors: exportDataErrors },
    reset: exportDataReset,
    handleSubmit: exportDataHandleSubmit,
    control: exportDataControl,
  } = useForm({ mode: "onChange" });

  const [show, setShow] = useState(false);
  const [showMore, setShowMore] = useState(2);
  const [searchData, setSearchData] = useState({ search: "" });
  const [selectedTransactionInfo, setSelectedTransactionInfo] = useState();
  const [selectedTransactionStatus, setSelectedTransactionStatus] = useState();
  const [exportDataModel, setExportDataModel] = useState(false);
  const [csvWithdrawList, setCsvWithdrawList] = useState([]);

  useEffect(() => {
    dispatch(actions.getWalletTransactions(1, searchData));
  }, [dispatch]);

  const { currentPermissions } = useSelector((state) => state.role);
  const { loadingTransactions, isSpinning, walletTransactions } = useSelector(
    (state) => state.wallet
  );
  const { generalSettings } = useSelector((state) => state.settings);
  const { exportWithdrawList, exportLoading } = useSelector(
    (state) => state.exportAllCSV
  );

  useEffect(() => {
    if (!currentPermissions?.show_wallet_transactions) {
      history.push(`/dashboard`);
    }
  }, [currentPermissions?.show_wallet_transactions]);

  const statusFilterList = [
    {
      value: "",
      label: intl.formatMessage({ id: "GLOBAL.SEARCH.STATUS" }),
    },
    {
      value: 0,
      label: intl.formatMessage({ id: "GLOBAL.STATUS.PENDING" }),
    },
    {
      value: 2,
      label: intl.formatMessage({ id: "GLOBAL.STATUS.SUCCESS" }),
    },
    {
      value: 3,
      label: intl.formatMessage({ id: "GLOBAL.STATUS.REJECTED" }),
    },
  ];

  useLayoutEffect(() => {
    if (generalSettings?.walletModule === "no") {
      history.push(`/`);
    }
  }, [generalSettings?.walletModule]);

  const clearSearchData = () => {
    setSearchData("");
    setShowMore(2);
    dispatch(actions.getWalletTransactions(1, ""));
  };

  const handleClose = () => {
    modalReset();
    setSelectedTransactionInfo();
    setSelectedTransactionStatus();
    setShow(false);
  };

  const loadMore = () => {
    setShowMore(showMore + 1);
    dispatch(actions.getWalletTransactions(showMore, searchData));
  };

  const onSubmit = (data) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setShowMore(1);
    setSearchData(data);
    dispatch(actions.getWalletTransactions(1, data));
  };

  const onUpdateTransactionSubmit = (data) => {
    data.status = selectedTransactionStatus;
    dispatch(
      actions.updateWalletTransaction(
        selectedTransactionInfo._id,
        data,
        showMore,
        searchData
      )
    );
    handleClose();
  };

  const handleExportClose = () => {
    exportDataReset();
    setExportDataModel(false);
    setCsvWithdrawList([]);
    dispatch(actions.getWithdrawExportStart());
  };

  const onSubmitExportWithdrawRequests = (data) => {
    dispatch(actions.getWithdrawExportStart());
    setCsvWithdrawList([]);
    data.startDate = Date.parse(data.startDate);
    data.endDate = Date.parse(data.endDate);
    dispatch(actions.getWithdrawCSVList(data));
  };

  // Export Data
  const withdrawRequestCSVHeaders = [
    { label: "Full Name", key: "fullName" },
    { label: "Email", key: "email" },
    { label: "Wallet ID", key: "walletId" },
    { label: "Transaction Number", key: "transactionNumber" },
    { label: "Amount", key: "amount" },
    { label: "Type", key: "walletType" },
    { label: "Status", key: "status" },
    { label: "Payment Gateway", key: "gatewayId" },
    { label: "Gateway Fees", key: "transactionFees" },
    { label: "Platform Fees", key: "platformFeesDetails" },
    { label: "VAT Fees", key: "vatFeesDetails" },
    { label: "Reason to Reject", key: "rejectReason" },
    { label: "Date", key: "createdAt" },
  ];

  useEffect(() => {
    if (exportWithdrawList?.docs?.length > 0) {
      let result = exportWithdrawList?.docs?.map((withdrawRequest) => ({
        fullName: withdrawRequest?.user?.fullName,
        email: withdrawRequest?.user?.email,
        walletId: withdrawRequest?.walletId,
        transactionNumber: withdrawRequest?.transactionNumber,
        amount:
          withdrawRequest?.amount + " " + withdrawRequest?.currencyId?.code,
        walletType: withdrawRequest?.walletType,
        status: getWalletStatusCodes(withdrawRequest?.status),
        gatewayId:
          withdrawRequest?.gatewayId?.paymentType === "offline"
            ? "Bank Transfer"
            : withdrawRequest?.gatewayId?.paymentType === "oman-paymob"
            ? "Online Transfer"
            : "-",
        transactionFees:
          withdrawRequest?.feesDetails?.feesPercentage +
          " " +
          withdrawRequest?.currencyId?.code,
        platformFeesDetails:
          withdrawRequest?.platformFeesDetails?.platformFees +
          " " +
          withdrawRequest?.currencyId?.code,
        vatFeesDetails:
          withdrawRequest?.vatFeesDetails?.vatFees +
          " " +
          withdrawRequest?.currencyId?.code,
        rejectReason: withdrawRequest?.rejectReason,
        createdAt: moment(withdrawRequest?.createdAt).format(
          "DD/MM/YYYY HH:mm:ss"
        ),
      }));
      setCsvWithdrawList(result);
    }
  }, [exportWithdrawList]);

  const walletTransactionList =
    walletTransactions?.docs?.length > 0 ? (
      walletTransactions?.docs?.map((transaction, key) => (
        <tr key={key}>
          <td className="text-nowrap">{transaction?.user?.fullName}</td>
          <td className="text-nowrap">
            {convertNumberToDateString(
              transaction?.createdAt,
              generalSettings?.timezone,
              generalSettings.dateFormat
            ) ?? "-"}
          </td>
          <td>{transaction?.transactionNumber}</td>
          <td
            className={
              transaction?.walletType === "CREDIT"
                ? "text-success"
                : "text-danger"
            }
            style={{
              maxWidth: "15rem",
              wordBreak: "break-all",
            }}
          >
            {formatCurrency(
              transaction?.amount ?? 0,
              generalSettings?.currencySymbolSide,
              transaction?.currencyId?.symbol,
              transaction?.currencyId?.code,
              generalSettings?.decimalPoints
            )}
          </td>
          <OverlayTrigger
            key={key}
            placement={"right"}
            overlay={
              <Tooltip>
                <span className="row p-1">
                  Gateway Feess:{" "}
                  <b>
                    {" "}
                    {transaction?.feesDetails?.transactionFees.toFixed(2) ?? 0}
                  </b>
                </span>
                <span className="row p-1">
                  Platform Fees:{" "}
                  <b>
                    {" "}
                    {transaction?.platformFeesDetails?.platformFees.toFixed(2)}
                  </b>
                </span>
                <span className="row p-1">
                  VAT Fees:{" "}
                  <b> {transaction?.vatFeesDetails?.vatFees.toFixed(3) ?? 0}</b>
                </span>
              </Tooltip>
            }
          >
            <td className="text-nowrap">
              {formatCurrency(
                transaction?.feesDetails?.transactionFees +
                  transaction?.platformFeesDetails?.platformFees +
                  transaction?.vatFeesDetails?.vatFees ?? 0,
                generalSettings?.currencySymbolSide,
                transaction?.currencyId?.symbol,
                transaction?.currencyId?.code,
                generalSettings?.decimalPoints
              )}
            </td>
          </OverlayTrigger>
          <td>
            {transaction?.gatewayId?.paymentType === "offline"
              ? "Bank Transfer"
              : transaction?.gatewayId?.paymentType === "oman-paymob"
              ? "Online Transfer"
              : "-"}
          </td>
          <td>{transaction?.walletType}</td>
          <td>
            <span
              className={`label label-lg ${getWalletCSSClassName(
                transaction?.status
              )} label-inline`}
            >
              {getWalletStatusCodes(transaction?.status)}
            </span>
          </td>
          {currentPermissions?.view_wallet_transaction && (
            <td>
              <>
                <OverlayTrigger
                  placement="bottom"
                  popperConfig={{
                    modifiers: {
                      preventOverflow: {
                        enabled: false,
                      },
                    },
                  }}
                  overlay={<Tooltip>View</Tooltip>}
                >
                  <button
                    onClick={() => {
                      setSelectedTransactionInfo(transaction);
                      setSelectedTransactionStatus(transaction?.status);
                      modalSetValue("rejectReason", transaction?.rejectReason);
                      if (transaction?.gatewayId?.paymentType === "offline") {
                        modalSetValue(
                          "adminEditedAmount",
                          transaction?.amount -
                            transaction?.platformFeesDetails?.platformFees -
                            transaction?.vatFeesDetails?.vatFees
                        );
                      }
                      setShow(true);
                    }}
                    className={`btn btn-icon btn-light btn-sm ml-1 mr-2 ${
                      transaction?.status === 0 ? "symbol" : ""
                    }`}
                  >
                    <span className="svg-icon svg-icon-md svg-icon-primary">
                      <SVG
                        title=" "
                        src={toAbsoluteUrl(
                          "/media/svg/icons/General/Visible.svg"
                        )}
                      />
                    </span>
                    <i className="symbol-badge bg-danger" />
                  </button>
                </OverlayTrigger>
              </>
            </td>
          )}
        </tr>
      ))
    ) : (
      <tr className="text-center">
        <td colSpan={10}>
          <p>{loadingTransactions ? "Loading..." : "No Record Found!"}</p>
        </td>
      </tr>
    );

  return (
    <>
      <FormProvider {...methods}>
        <Form
          name="SearchTransactions"
          onSubmit={methods.handleSubmit(onSubmit)}
        >
          <SearchTransactions
            showTransactionType={true}
            clearSearchData={() => clearSearchData()}
          />
        </Form>
      </FormProvider>
      <Card>
        <CardHeader title="Wallet Transaction List">
          <div className="header-buttons"></div>
          <CardHeaderToolbar>
            {currentPermissions?.export_wallet_transactions && (
              <Button
                className="btn btn-primary ml-2"
                onClick={() => {
                  setExportDataModel(true);
                  exportDataReset();
                  setCsvWithdrawList([]);
                  dispatch(actions.getWithdrawExportStart());
                }}
              >
                Export Data
              </Button>
            )}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Table responsive className="table-head-custom table-vertical-center">
            <thead>
              <tr>
                <th>User</th>
                <th>Date</th>
                <th>Transaction ID</th>
                <th>Amount</th>
                <th>Fees</th>
                <th>Gateway</th>
                <th>Type</th>
                <th>Status</th>
                {currentPermissions?.view_wallet_transaction && (
                  <th>Actions</th>
                )}
              </tr>
            </thead>
            <tbody>{walletTransactionList}</tbody>
          </Table>
          {walletTransactions?.displayLoadMore ? (
            <div className="mt-5 text-center">
              <button
                type="button"
                className="btn btn-primary custom-btn mt-5 text-center"
                onClick={() => loadMore()}
                disabled={isSpinning}
              >
                <span
                  className={
                    isSpinning
                      ? ` text-center spinner spinner-center p-5 m-5`
                      : ""
                  }
                >
                  {isSpinning ? "" : "Load More"}
                </span>
              </button>
            </div>
          ) : null}
        </CardBody>
      </Card>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="d-flex align-items-center">
              <div className="symbol symbol-50 mr-5">
                <div
                  className="symbol-label"
                  style={{
                    backgroundImage: `url(${toAbsoluteUrl(
                      selectedTransactionInfo?.user?.photo
                        ? `${globalImagePath.current}${selectedTransactionInfo?.user?.photo}`
                        : "/media/users/default.jpg"
                    )})`,
                  }}
                />
              </div>
              <div>
                <a
                  href="#"
                  className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
                >
                  {selectedTransactionInfo?.user?.fullName}
                </a>
                {selectedTransactionInfo?.user?.email && (
                  <div className="navi mt-2">
                    <a href={undefined} className="navi-item">
                      <span className="navi-link p-0 pb-2">
                        <span className="navi-icon mr-1">
                          <span className="svg-icon-lg svg-icon-primary">
                            <SVG
                              src={toAbsoluteUrl(
                                "/media/svg/icons/Communication/Mail-notification.svg"
                              )}
                            ></SVG>
                          </span>
                        </span>
                        <span className="navi-text text-muted text-hover-primary">
                          {selectedTransactionInfo?.user?.email}
                        </span>
                      </span>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="offcanvas-content pr-5 mr-n5">
            {selectedTransactionInfo?.gatewayId?.title === "Offline" ? (
              <>
                <div className="separator separator-dashed my-7" />
                <div>
                  <h5 className="mb-5">Acknowledgement Document</h5>
                  <ul className="p-0 m-0">
                    <li className="d-flex justify-content-between border-bottom py-3">
                      <span>Document</span>
                      <strong className="ml-4">
                        <a
                          href={`${globalImagePath.current}${selectedTransactionInfo?.acknowledgeDocument}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          download
                        >
                          Download
                        </a>
                      </strong>
                    </li>
                  </ul>
                </div>
              </>
            ) : null}

            {selectedTransactionInfo?.walletType === "DEBIT" &&
            selectedTransactionInfo?.userBankAccount ? (
              <>
                <div className="separator my-7" />
                <h5 className="mb-5">Bank Details</h5>
                <ul className="p-0 m-0">
                  {selectedTransactionInfo?.userBankAccount?.bankName ? (
                    <li className="d-flex justify-content-between border-bottom py-3">
                      <span>Bank Name</span>
                      <strong className="ml-4">
                        {selectedTransactionInfo?.userBankAccount?.bankName}
                      </strong>
                    </li>
                  ) : null}
                  {selectedTransactionInfo?.userBankAccount?.accountNumber ? (
                    <li className="d-flex justify-content-between border-bottom py-3">
                      <span>Account Number</span>
                      <strong className="ml-4">
                        {
                          selectedTransactionInfo?.userBankAccount
                            ?.accountNumber
                        }
                      </strong>
                    </li>
                  ) : null}
                  {selectedTransactionInfo?.userBankAccount
                    ?.accountHolderName ? (
                    <li className="d-flex justify-content-between border-bottom py-3">
                      <span>Account Holder Name</span>
                      <strong className="ml-4">
                        {
                          selectedTransactionInfo?.userBankAccount
                            ?.accountHolderName
                        }
                      </strong>
                    </li>
                  ) : null}
                  {selectedTransactionInfo?.userBankAccount?.countryId?.name ? (
                    <li
                      className={`d-flex justify-content-between ${
                        selectedTransactionInfo?.countryId?.countryCode !== "OM"
                          ? `border-bottom`
                          : null
                      } py-3`}
                    >
                      <span>Country</span>
                      <strong className="ml-4">
                        {
                          selectedTransactionInfo?.userBankAccount?.countryId
                            ?.name
                        }
                      </strong>
                    </li>
                  ) : null}
                  {selectedTransactionInfo?.userBankAccount?.countryId
                    ?.countryCode !== "OM" ? (
                    <>
                      {selectedTransactionInfo?.userBankAccount?.swiftNumber ? (
                        <li className="d-flex justify-content-between border-bottom py-3">
                          <span>Swift Number</span>
                          <strong className="ml-4">
                            {
                              selectedTransactionInfo?.userBankAccount
                                ?.swiftNumber
                            }
                          </strong>
                        </li>
                      ) : null}
                      {selectedTransactionInfo?.userBankAccount?.accountType ? (
                        <li className="d-flex justify-content-between border-bottom py-3">
                          <span>Account Type</span>
                          <strong className="ml-4">
                            {
                              selectedTransactionInfo?.userBankAccount
                                ?.accountType
                            }
                          </strong>
                        </li>
                      ) : null}
                      {selectedTransactionInfo?.userBankAccount
                        ?.routingNumber ? (
                        <li className="d-flex justify-content-between border-bottom py-3">
                          <span>Routing Number</span>
                          <strong className="ml-4">
                            {
                              selectedTransactionInfo?.userBankAccount
                                ?.routingNumber
                            }
                          </strong>
                        </li>
                      ) : null}
                    </>
                  ) : null}
                </ul>
              </>
            ) : null}

            <div className="separator my-7" />

            <div>
              <h5 className="mb-5">Payment Details</h5>

              <ul className="p-0 m-0">
                {selectedTransactionInfo?.gatewayId?.title ? (
                  <li className="d-flex justify-content-between border-bottom py-3">
                    <span>Payment Gateway</span>
                    <strong className="ml-4">
                      {capitalize(selectedTransactionInfo?.gatewayId?.title)}
                    </strong>
                  </li>
                ) : null}
                {selectedTransactionInfo?.userSelfie ? (
                  <li className="d-flex justify-content-between border-bottom py-3">
                    <span>User Live photo</span>
                    <strong className="ml-4">
                      {(
                        <S3FileOpener
                          filePath={selectedTransactionInfo?.userSelfie}
                          buttonText="View Photo"
                        />
                      ) ?? "-"}
                    </strong>
                  </li>
                ) : null}
                {selectedTransactionInfo?.user?.kycDocumentFront ? (
                  <li className="d-flex justify-content-between border-bottom py-3">
                    <span>KYC Front Document</span>
                    <strong className="ml-4">
                      {(
                        <S3FileOpener
                          filePath={
                            selectedTransactionInfo?.user?.kycDocumentFront
                          }
                          buttonText="View Document"
                        />
                      ) ?? "-"}
                    </strong>
                  </li>
                ) : null}

                {selectedTransactionInfo?.user?.kycDocumentBack ? (
                  <li className="d-flex justify-content-between border-bottom py-3">
                    <span>KYC Back Document</span>
                    <strong className="ml-4">
                      {(
                        <S3FileOpener
                          filePath={
                            selectedTransactionInfo?.user?.kycDocumentBack
                          }
                          buttonText="View Document"
                        />
                      ) ?? "-"}
                    </strong>
                  </li>
                ) : null}

                <li className="d-flex justify-content-between border-bottom py-3">
                  <span>Wallet ID</span>
                  <strong className="ml-4">
                    {selectedTransactionInfo?.walletId ?? "-"}
                  </strong>
                </li>
                <li className="d-flex justify-content-between border-bottom py-3">
                  <span>Amount</span>
                  <strong className="ml-4">
                    {formatCurrency(
                      selectedTransactionInfo?.amount,
                      generalSettings.currencySymbolSide,
                      selectedTransactionInfo?.currencyId?.symbol,
                      selectedTransactionInfo?.currencyId?.code,
                      generalSettings.decimalPoints
                    )}
                  </strong>
                </li>
                <li className="d-flex justify-content-between border-bottom py-3">
                  <span>Gateway Fees:</span>
                  <strong className="ml-4">
                    {formatCurrency(
                      selectedTransactionInfo?.feesDetails?.transactionFees ??
                        0,
                      generalSettings?.currencySymbolSide,
                      selectedTransactionInfo?.currencyId?.symbol,
                      selectedTransactionInfo?.currencyId?.code,
                      generalSettings?.decimalPoints
                    )}
                  </strong>
                </li>
                <li className="d-flex justify-content-between border-bottom py-3">
                  <span>Platform Fees:</span>
                  <strong className="ml-4">
                    {formatCurrency(
                      selectedTransactionInfo?.platformFeesDetails
                        ?.platformFees ?? 0,
                      generalSettings?.currencySymbolSide,
                      selectedTransactionInfo?.currencyId?.symbol,
                      selectedTransactionInfo?.currencyId?.code,
                      generalSettings?.decimalPoints
                    )}
                  </strong>
                </li>
                <li className="d-flex justify-content-between border-bottom py-3">
                  <span>VAT Fees:</span>
                  <strong className="ml-4">
                    {formatCurrency(
                      selectedTransactionInfo?.vatFeesDetails?.vatFees ?? 0,
                      generalSettings?.currencySymbolSide,
                      selectedTransactionInfo?.currencyId?.symbol,
                      selectedTransactionInfo?.currencyId?.code,
                      generalSettings?.decimalPoints
                    )}
                  </strong>
                </li>
                {selectedTransactionInfo?.status === 2 &&
                selectedTransactionInfo?.gatewayId?.paymentType ===
                  "offline" ? (
                  <li className="d-flex justify-content-between border-bottom py-3">
                    <span>Actual Wallet Amount:</span>
                    <strong className="ml-4">
                      {formatCurrency(
                        selectedTransactionInfo?.adminEditedAmount ?? 0,
                        generalSettings?.currencySymbolSide,
                        selectedTransactionInfo?.currencyId?.symbol,
                        selectedTransactionInfo?.currencyId?.code,
                        generalSettings?.decimalPoints
                      )}
                    </strong>
                  </li>
                ) : null}
                <li className="d-flex justify-content-between border-bottom py-3">
                  <span>Status</span>
                  <span
                    className={`label label-lg ${getWalletCSSClassName(
                      selectedTransactionInfo?.status
                    )} label-inline`}
                  >
                    {getWalletStatusCodes(selectedTransactionInfo?.status)}
                  </span>
                </li>
                {selectedTransactionInfo?.status === 4 ? (
                  <li className="d-flex justify-content-between border-bottom py-3">
                    <span>Reason to Failed</span>
                    <strong className="ml-4">
                      {selectedTransactionInfo?.rejectReason}
                    </strong>
                  </li>
                ) : null}
                {selectedTransactionInfo?.status !== 4 ? (
                  <>
                    <Form
                      name="Transactions"
                      onSubmit={modalHandleSubmit(onUpdateTransactionSubmit)}
                    >
                      <>
                        {selectedTransactionInfo?.gatewayId?.paymentType ===
                          "offline" && selectedTransactionInfo?.status === 0 ? (
                          <>
                            <Form.Label className="mt-2">
                              Wallet Transfer Amount
                              <span className="text-important ml-2 mr-2">
                                *
                              </span>
                            </Form.Label>
                            <Form.Control
                              name="adminEditedAmount"
                              placeholder={"Transfer Amount"}
                              className={classNames("form-control mb-2", {
                                "is-invalid": modalErrors.adminEditedAmount,
                              })}
                              {...modalRegister("adminEditedAmount", {
                                required: intl.formatMessage({
                                  id: "COMMON.FIELD.REQ",
                                }),
                              })}
                            />
                            <ErrorMessage
                              errors={modalErrors}
                              name="adminEditedAmount"
                              render={({ message }) => (
                                <div className="invalid-feedback">
                                  {message}
                                </div>
                              )}
                            />
                          </>
                        ) : null}
                      </>
                      {currentPermissions?.wallet_transaction_action && (
                        <li className="d-flex justify-content-between pt-3">
                          <span>Action</span>
                          <ToggleButtonGroup type="radio" name="status">
                            <ToggleButton
                              variant="secondary"
                              size="sm"
                              className={
                                selectedTransactionStatus === 2 ? "active" : ""
                              }
                              onClick={() => setSelectedTransactionStatus(2)}
                              disabled={selectedTransactionInfo?.status === 1}
                            >
                              Approve
                            </ToggleButton>
                            <ToggleButton
                              variant="secondary"
                              className={
                                selectedTransactionStatus === 3 ? "active" : ""
                              }
                              size="sm"
                              onClick={() => setSelectedTransactionStatus(3)}
                              disabled={selectedTransactionInfo?.status === 1}
                            >
                              Reject
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </li>
                      )}

                      {selectedTransactionStatus === 3 ? (
                        <>
                          <Form.Label className="mt-2">
                            Reason to Reject
                            <span className="text-important ml-2 mr-2">*</span>
                          </Form.Label>
                          <Form.Control
                            name="rejectReason"
                            as="textarea"
                            rows="3"
                            placeholder={"Reason to Reject"}
                            className={classNames("form-control", {
                              "is-invalid": modalErrors.rejectReason,
                            })}
                            {...modalRegister("rejectReason", {
                              required: intl.formatMessage({
                                id: "COMMON.FIELD.REQ",
                              }),
                            })}
                          />
                          <ErrorMessage
                            errors={modalErrors}
                            name="rejectReason"
                            render={({ message }) => (
                              <div className="invalid-feedback">{message}</div>
                            )}
                          />
                        </>
                      ) : null}
                      <div>
                        <hr />
                        {currentPermissions?.wallet_transaction_action && (
                          <button
                            type="submit"
                            className="btn btn-primary ml-2"
                            disabled={
                              selectedTransactionStatus === 0 ||
                              selectedTransactionStatus ===
                                selectedTransactionInfo?.status
                            }
                          >
                            Confirm
                          </button>
                        )}

                        <button
                          type="button"
                          className="btn btn-secondary ml-2"
                          onClick={() => handleClose()}
                        >
                          Cancel
                        </button>
                      </div>
                    </Form>
                  </>
                ) : null}
              </ul>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={exportDataModel}
        onHide={handleExportClose}
        backdrop="static"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Filter Top-up Requests </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form
            onSubmit={exportDataHandleSubmit(onSubmitExportWithdrawRequests)}
            className="form form-label-right"
          >
            <div className="row">
              <div className="col-md-6 form-group">
                <label>Start Date</label>
                <span className="text-danger">*</span>
                <span name="startDate">
                  <Controller
                    name={"startDate"}
                    control={exportDataControl}
                    rules={{ required: "This field is required" }}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <DatePicker
                          className={classNames("form-control", {
                            "is-invalid": exportDataErrors.startDate,
                          })}
                          id="startDate"
                          onChange={onChange}
                          selected={value}
                          dateFormat="dd/MM/yyyy"
                          maxDate={new Date()}
                        />
                      );
                    }}
                  />
                  <ErrorMessage
                    errors={exportDataErrors}
                    name="startDate"
                    render={({ message }) => (
                      <div className="invalid-feedback d-block" id="startDate">
                        {message}
                      </div>
                    )}
                  />
                </span>
              </div>
              <div className="col-md-6 form-group">
                <label>End Date</label>
                <span className="text-danger">*</span>
                <span name="endDate">
                  <Controller
                    name={"endDate"}
                    control={exportDataControl}
                    rules={{ required: "This field is required" }}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <DatePicker
                          className={classNames("form-control", {
                            "is-invalid": exportDataErrors.endDate,
                          })}
                          id="endDate"
                          onChange={onChange}
                          selected={value}
                          dateFormat="dd/MM/yyyy"
                          maxDate={new Date()}
                        />
                      );
                    }}
                  />
                  <ErrorMessage
                    errors={exportDataErrors}
                    name="endDate"
                    render={({ message }) => (
                      <div className="invalid-feedback d-block" id="endDate">
                        {message}
                      </div>
                    )}
                  />
                </span>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 form-group">
                <label>Status</label>
                <Controller
                  name={"status"}
                  control={exportDataControl}
                  rules={{
                    required: false,
                  }}
                  render={({ field: { value, onChange } }) => {
                    return (
                      <Select
                        placeholder={"Select Status"}
                        classNamePrefix="react-select"
                        className={classNames("react-select-container")}
                        options={statusFilterList}
                        value={statusFilterList.find((c) => c.value === value)}
                        onChange={(val) => onChange(val.value)}
                      />
                    );
                  }}
                />
                <ErrorMessage
                  errors={exportDataErrors}
                  name="status"
                  render={({ message }) => (
                    <div className="invalid-feedback d-block">{message}</div>
                  )}
                />
              </div>
            </div>

            <button
              type="submit"
              className="btn btn-primary mr-2"
              disabled={exportLoading}
            >
              Submit
            </button>
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleExportClose}
              variant="secondary"
            >
              {intl.formatMessage({ id: "CLOSE" })}
            </button>
            <br />
            <br />
            {csvWithdrawList?.length > 0 ? (
              <CSVLink
                data={csvWithdrawList}
                headers={withdrawRequestCSVHeaders}
                className="btn btn-primary mr-2"
                disabled={exportLoading}
                filename={"wallet-transactions.csv"}
              >
                <SVG
                  title=" "
                  src={toAbsoluteUrl("/media/svg/files/csv.svg")}
                />{" "}
                {exportLoading ? "Loading.." : "Export"}
              </CSVLink>
            ) : null}
            {exportWithdrawList?.docs?.length === 0 ? (
              <b className="text-danger">No record found with this filter</b>
            ) : null}
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default WalletTransactions;
