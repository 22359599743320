import * as actionTypes from "./actionTypes";

export const campaignStart = () => {
  return {
    type: actionTypes.CAMPAIGN_START,
  };
};

export const campaignFail = (error) => {
  return {
    type: actionTypes.CAMPAIGN_FAIL,
    error,
  };
};

export const getCampaigns = (pageLimit) => {
  return {
    type: actionTypes.GET_CAMPAIGNS,
    pageLimit,
  };
};

export const getCampaignsSuccess = (response) => {
  return {
    type: actionTypes.GET_CAMPAIGNS_SUCCESS,
    response,
  };
};

export const getSearchCampaigns = (response, pageLimit) => {
  return {
    type: actionTypes.GET_SEARCH_CAMPAIGNS,
    response,
    pageLimit,
  };
};

export const getReservations = (id) => {
  return {
    type: actionTypes.GET_TOTAL_RESERVARTIONS,
    id,
  };
};

export const getReservationsSuccess = (response) => {
  return {
    type: actionTypes.GET_TOTAL_RESERVARTIONS_SUCCESS,
    response,
  };
};

export const getCampaignDetails = (id) => {
  return {
    type: actionTypes.GET_CAMPAIGN_DETAILS,
    id,
  };
};

export const getCampaignDetailsSuccesss = (response) => {
  return {
    type: actionTypes.GET_CAMPAIGN_DETAILS_SUCCESS,
    response,
  };
};

export const getInvestors = (campaignId, status) => {
  return {
    type: actionTypes.GET_INVESTORS,
    campaignId,
    status,
  };
};

export const getInvestorsSuccess = (response) => {
  return {
    type: actionTypes.GET_INVESTORS_SUCCESS,
    response,
  };
};

/**
 * #TODO
 * @param {*} investorId
 * @returns
 */
export const getInvestor = (investorId) => {
  return {
    type: actionTypes.GET_INVESTOR,
    investorId,
  };
};
/**
 * #TODO
 * @param {*} response
 * @returns
 */
export const getInvestorSuccess = (response) => {
  return {
    type: actionTypes.GET_INVESTOR_SUCCESS,
    response,
  };
};

export const getDocuments = (id) => {
  return {
    type: actionTypes.GET_DOCUMENTS,
    id,
  };
};

export const getDocumentsSuccess = (response) => {
  return {
    type: actionTypes.GET_DOCUMENTS_SUCCESS,
    response,
  };
};

export const createContractDocument = (contractDocument) => {
  return {
    type: actionTypes.CREATE_CONTRACT_DOCUMENT,
    contractDocument,
  };
};

export const updateCampaignRisk = (id, riskData) => {
  return {
    type: actionTypes.UPDATE_CAMPAIGN_RISK,
    id,
    riskData,
  };
};

export const approveCampaign = (id, riskAnalysis) => {
  return {
    type: actionTypes.APPROVE_CAMPAIGN,
    id,
    riskAnalysis,
    status: 2,
  };
};

export const pendingCampaign = (id) => {
  return {
    type: actionTypes.PENDING_CAMPAIGN,
    id,
    status: 1,
  };
};

export const declineCampaign = (id) => {
  return {
    type: actionTypes.DECLINE_CAMPAIGN,
    id,
    status: 3,
  };
};

export const hideCampaign = (id) => {
  return {
    type: actionTypes.HIDE_CAMPAIGN,
    id,
    status: 6,
  };
};

export const inactiveCampaign = (id) => {
  return {
    type: actionTypes.INACTIVE_CAMPAIGN,
    id,
    status: 7,
  };
};

export const deleteCampaign = (id) => {
  return {
    type: actionTypes.DELETE_CAMPAIGN,
    id,
    status: 3,
  };
};

export const featureCampaign = (id) => {
  return {
    type: actionTypes.FEATURE_CAMPAIGN,
    id,
    isFeatured: 1,
  };
};

export const unfeatureCampaign = (id) => {
  return {
    type: actionTypes.UNFEATURE_CAMPAIGN,
    id,
    isFeatured: 0,
  };
};

export const featureCampaignSuccess = (response) => {
  return {
    type: actionTypes.FEATURE_CAMPAIGN_SUCCESS,
    response,
  };
};

export const getInvestorDetails = (id) => {
  return {
    type: actionTypes.GET_INVESTOR_DETAILS,
    id,
  };
};

export const getInvestorDetailsSuccess = (response) => {
  return {
    type: actionTypes.GET_INVESTOR_DETAILS_SUCCESS,
    response,
  };
};

export const getCampaignActivities = (campaignId) => {
  return {
    type: actionTypes.GET_CAMPAIGN_ACTIVITIES,
    campaignId,
  };
};

export const getCampaignActivitiesSuccess = (response) => {
  return {
    type: actionTypes.GET_CAMPAIGN_ACTIVITIES_SUCCESS,
    response,
  };
};

export const approveRepayment = (id) => {
  return {
    type: actionTypes.APPROVE_REPAYMENT,
    id,
    confirmPayoutDate: Date.now(),
  };
};

export const getRepaymentIn = (id) => {
  return {
    type: actionTypes.GET_REPAYMENT_IN,
    id,
  };
};

export const getRepaymentInSuccess = (response) => {
  return {
    type: actionTypes.GET_REPAYMENT_IN_SUCCESS,
    response,
  };
};

export const createRepaymentIn = (repaymentData) => {
  return {
    type: actionTypes.CREATE_REPAYMENT_IN,
    repaymentData,
  };
};

export const updateRepayment = (repaymentId, id, paymentStatus) => {
  return {
    type: actionTypes.UPDATE_REPAYMENT,
    repaymentId,
    id,
    paymentStatus,
    createdAt: Date.now(),
  };
};

export const getDistribution = (id) => {
  return {
    type: actionTypes.GET_DISTRIBUTION,
    id,
  };
};

export const getDistributionSuccess = (response) => {
  return {
    type: actionTypes.GET_DISTRIBUTION_SUCCESS,
    response,
  };
};

export const getDistributionDetail = (distributionId) => {
  return {
    type: actionTypes.GET_DISTRIBUTION_DETAIL,
    distributionId,
  };
};

export const getDistributionDetailSuccess = (response) => {
  return {
    type: actionTypes.GET_DISTRIBUTION_DETAIL_SUCCESS,
    response,
  };
};

export const createDistribution = (distributionData) => {
  return {
    type: actionTypes.CREATE_DISTRIBUTION,
    distributionData,
  };
};

export const deleteDistribution = (distributionData) => {
  return {
    type: actionTypes.DELETE_DISTRIBUTION,
    distributionData,
  };
};

export const updateDistribution = (distributionData) => {
  return {
    type: actionTypes.UPDATE_DISTRIBUTION,
    distributionData,
  };
};

export const updateDistributionAdjustmentAmount = (distributionData) => {
  return {
    type: actionTypes.UPDATE_DISTRIBUTION_ADJUSTMENT,
    distributionData,
  };
};

export const markDistributed = (distributionData) => {
  return {
    type: actionTypes.MARK_DISTRIBUTED,
    distributionData,
  };
};

export const removeCampaign = (id) => {
  return {
    type: actionTypes.REMOVE_CAMPAIGN,
    id,
  };
};

export const isLoading = (response) => {
  return {
    type: actionTypes.IS_LOADING,
    response,
  };
};

export const investAction = (investData, investorId) => {
  return {
    type: actionTypes.INVEST_ACTION,
    investData,
    investorId,
  };
};

export const investActionResponse = (response) => {
  return {
    type: actionTypes.INVEST_ACTION_RESPONSE,
    response,
  };
};

export const contractDocumentAction = (contractData) => {
  return {
    type: actionTypes.CONTRACT_ACTION,
    contractData,
  };
};

export const contractDocumentActionResponse = (response) => {
  return {
    type: actionTypes.CONTRACT_ACTION_RESPONSE,
    response,
  };
};

export const acknowledgeDocument = (accredetionData) => {
  return {
    type: actionTypes.ACK_CONTRACT_ACTION,
    accredetionData,
  };
};

export const acknowledgeDocumentResponse = (response) => {
  return {
    type: actionTypes.ACK_CONTRACT_ACTION_RESPONSE,
    response,
  };
};

export const updateCampaign = (campaignData) => {
  return {
    type: actionTypes.UPDATE_CAMPAIGN,
    campaignData,
  };
};

export const updateCampaignSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_CAMPAIGN_SUCCESS,
    response,
  };
};

export const clearStripeACHPaymentToken = () => {
  return {
    type: actionTypes.CLEAR_STRIPE_ACH_PAYMENT_TOKEN,
  };
};

export const getStripeACHPaymentToken = () => {
  return {
    type: actionTypes.GET_STRIPE_ACH_PAYMENT_TOKEN,
  };
};

export const getStripeACHPaymentTokenSuccess = (response) => {
  return {
    type: actionTypes.GET_STRIPE_ACH_PAYMENT_TOKEN_SUCCESS,
    response,
  };
};

export const clearStripeACHPaymentConfimation = () => {
  return {
    type: actionTypes.CLEAR_STRIPE_ACH_PAYMENT_CONFIMATION,
  };
};

export const postStripeACHPaymentConfimation = (data) => {
  return {
    type: actionTypes.POST_STRIPE_ACH_PAYMENT_CONFIMATION,
    data,
  };
};

export const postStripeACHPaymentConfimationSuccess = (response) => {
  return {
    type: actionTypes.POST_STRIPE_ACH_PAYMENT_CONFIMATION_SUCCESS,
    response,
  };
};

export const clearOneTimeError = () => {
  return {
    type: actionTypes.CLEAR_ONE_TIME_ERROR,
  };
};
