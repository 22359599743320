import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility/utility";

/**
 * An initial Object, to re-use in the upcoming reducers
 */
const initialState = {
  companyIndustry: [],
  loading: false,
  error: [],
  companyId: [],
  investingSourceList: [],
  investingSource: [],
  investingTypeList: [],
  investingType: [],
  documentList: [],
  document: [],
  accountList: [],
  account: "",
  manageOptionsLanguages: [],
  teamMemberTypeList: [],
  teamMemberType: [],
  investorList: [],
  investor: [],
  campaignList: [],
  campaign: [],
  investingRoundList: [],
  investingRound: [],
  dealTypes: [],
  dealType: [],
  investorTaxList: [],
  investorTax: [],
  isCreated: false,
  isUpdated: false,
  investmentSize: [],
  preferredInvestmentSegment: [],
};

const commonManageOptionsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
    errors: [],
    isCreated: false,
    isUpdated: false,
  });
};

const commonManageOptionsFail = (state, action) => {
  return updateObject(state, {
    error: true,
    loading: true,
    errors: action.error,
  });
};

const manageOptionsCompanyIndustrySuccess = (state, action) => {
  return updateObject(state, {
    companyIndustry: action.response,
  });
};

const manageOptionsGetCompanyIndustryById = (state, action) => {
  return updateObject(state, {
    companyId: action.response,
  });
};

const manageOptionsgetAllInvestingSourceListSuccess = (state, action) => {
  return updateObject(state, {
    investingSourceList: action.response,
  });
};

const manageOptionGetInvestingSourceSuccesss = (state, action) => {
  return updateObject(state, {
    investingSource: action.response,
  });
};

const manageOptionsGetInvestmentTypeSuccesss = (state, action) => {
  return updateObject(state, {
    investingTypeList: action.response,
  });
};

const manageOptionsGetSingleInvestmentTypeSuccess = (state, action) => {
  return updateObject(state, {
    investingType: action.response,
  });
};

const manageOptionsGetAllDocumentTypeSuccess = (state, action) => {
  return updateObject(state, {
    documentList: action.response,
  });
};

const manageOptionsGetDocumentTypeSuccess = (state, action) => {
  return updateObject(state, {
    document: action.response,
  });
};

const manageOptionsGetAllAccountTypeSuccess = (state, action) => {
  return updateObject(state, {
    accountList: action.response,
  });
};

const manageOptionsGetAccountTypeSuccess = (state, action) => {
  return updateObject(state, {
    account: action.response,
  });
};

const manageOptionsLanguages = (state, action) => {
  return updateObject(state, {
    manageOptionsLanguages: action.response,
  });
};

const manageOptionsLanguageId = (state, action) => {
  return updateObject(state, {
    manageOptionsLanguageId: action.language,
  });
};

const manageOptionsTeamMemberTypeList = (state, action) => {
  return updateObject(state, {
    teamMemberTypeList: action.response,
  });
};

const getTeamMemberType = (state, action) => {
  return updateObject(state, {
    teamMemberType: action.response,
  });
};

const getInvetorList = (state, action) => {
  return updateObject(state, {
    investorList: action.response,
  });
};

const getInvestor = (state, action) => {
  return updateObject(state, {
    investor: action.response,
  });
};

const getCampaignList = (state, action) => {
  return updateObject(state, {
    campaignList: action.response,
  });
};

const getCampaign = (state, action) => {
  return updateObject(state, {
    campaign: action.response,
  });
};
const getInvestingRoundList = (state, action) => {
  return updateObject(state, {
    investingRoundList: action.response,
  });
};

const getInvesting = (state, action) => {
  return updateObject(state, {
    investingRound: action.response,
  });
};
const getDealTypes = (state, action) => {
  return updateObject(state, {
    dealTypes: action.response,
  });
};

const getInvestorTaxList = (state, action) => {
  return updateObject(state, {
    investorTaxList: action.response,
  });
};

const getInvestorTax = (state, action) => {
  return updateObject(state, {
    investorTax: action.response,
  });
};

const getDealType = (state, action) => {
  return updateObject(state, {
    dealType: action.response,
  });
};

const isCreated = (state, action) => {
  return updateObject(state, {
    isCreated: true,
  });
};

const isUpdated = (state, action) => {
  return updateObject(state, {
    isUpdated: true,
  });
};

const getAllInvestmentSize = (state, action) => {
  return updateObject(state, {
    investmentSize: action.response,
  });
};

const getAllPreferredInvestmentSegment = (state, action) => {
  return updateObject(state, {
    preferredInvestmentSegment: action.response,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.MANAGE_OPTIONS_START:
      return commonManageOptionsStart(state, action);
    case actionTypes.MANAGE_OPTIONS_FAIL:
      return commonManageOptionsFail(state, action);
    case actionTypes.GET_COMPANY_INDUSTRY_SUCCESS:
      return manageOptionsCompanyIndustrySuccess(state, action);
    case actionTypes.GET_COMPANY_INDUSTRY_BY_SUCCESS:
      return manageOptionsGetCompanyIndustryById(state, action);
    case actionTypes.GET_ALL_INVESTING_SOURCE_LIST_SUCCESS:
      return manageOptionsgetAllInvestingSourceListSuccess(state, action);
    case actionTypes.GET_INVESTING_SOURCE_SUCCESS:
      return manageOptionGetInvestingSourceSuccesss(state, action);
    case actionTypes.GET_ALL_INVESTING_TYPE_SUCCESS:
      return manageOptionsGetInvestmentTypeSuccesss(state, action);
    case actionTypes.GET_INVESTING_TYPE_SUCCESS:
      return manageOptionsGetSingleInvestmentTypeSuccess(state, action);
    case actionTypes.GET_ALL_DOCUMENT_TYPE_SUCCESS:
      return manageOptionsGetAllDocumentTypeSuccess(state, action);
    case actionTypes.GET_DOCUMENT_TYPE_SUCCESS:
      return manageOptionsGetDocumentTypeSuccess(state, action);
    case actionTypes.GET_ALL_ACCOUNT_TYPE_SUCCESS:
      return manageOptionsGetAllAccountTypeSuccess(state, action);
    case actionTypes.GET_MANAGE_LANGUAGE_SUCCESS:
      return manageOptionsLanguages(state, action);
    case actionTypes.GET_MANAGE_DROPDOWN_LANG_ID:
      return manageOptionsLanguageId(state, action);
    case actionTypes.GET_ACCOUNT_TYPE_SUCCESS:
      return manageOptionsGetAccountTypeSuccess(state, action);
    case actionTypes.GET_ALL_TEAM_MEMBER_TYPE_SUCCESS:
      return manageOptionsTeamMemberTypeList(state, action);
    case actionTypes.GET_TEAM_MEMBER_TYPE_SUCCESS:
      return getTeamMemberType(state, action);
    case actionTypes.GET_INVESTORT_TYPE_LIST_SUCCESS:
      return getInvetorList(state, action);
    case actionTypes.GET_INVESTOR_TYPE_SUCCESS:
      return getInvestor(state, action);
    case actionTypes.GET_CAMPAIGN_STAGE_LIST_SUCCESS:
      return getCampaignList(state, action);
    case actionTypes.GET_CAMPAIGN_STAGE_SUCCEESS:
      return getCampaign(state, action);
    case actionTypes.GET_INVESTING_ROUND_LIST_SUCCESS:
      return getInvestingRoundList(state, action);
    case actionTypes.GET_INVESTING_ROUND_SUCCESS:
      return getInvesting(state, action);
    case actionTypes.GET_DEAL_TYPES_SUCCESS:
      return getDealTypes(state, action);
    case actionTypes.CREATE_DEAL_TYPE_SUCCESS:
    case actionTypes.CREATE_INVESTOR_TAX_SUCCESS:
      return isCreated(state, action);
    case actionTypes.GET_DEAL_TYPE_SUCCESS:
      return getDealType(state, action);
    case actionTypes.UPDATE_DEAL_TYPE_SUCCESS:
    case actionTypes.UPDATE_INVESTOR_TAX_SUCCESS:
      return isUpdated(state, action);
    case actionTypes.GET_INVESTOR_TAX_LIST_SUCCESS:
      return getInvestorTaxList(state, action);
    case actionTypes.GET_INVESTOR_TAX_SUCCESS:
      return getInvestorTax(state, action);
    case actionTypes.GET_ALL_INVESTMENT_SIZE_SUCCESS:
      return getAllInvestmentSize(state, action);
    case actionTypes.GET_ALL_PREFERRED_INVESTMENT_SEGMEN_SUCCESS:
      return getAllPreferredInvestmentSegment(state, action);
    default:
      return state;
  }
};

export default reducer;
