import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility/utility";

const initialState = {
  loading: false,
  errors: [],
  testimonialsCreated: false,
  testimonialsUpdated: false,
  testimonials: [],
  singleTestimonial: null,
};

const testimonialReset = (state, action) => {
  return updateObject(state, {
    errors: null,
    loading: true,
    testimonialsCreated: false,
    testimonialsUpdated: false,
    singleTestimonial: null,
  });
};

const testimonialStart = (state, action) => {
  return updateObject(state, {
    errors: null,
    loading: true,
    testimonialsCreated: false,
    testimonialsUpdated: false,
    singleTestimonial: null,
  });
};

const testimonialFail = (state, action) => {
  return updateObject(state, {
    errors: action.error,
    loading: false,
  });
};

const getTestimonials = (state, action) => {
  return updateObject(state, {
    testimonials: action.response
  });
};

const getSingleTestimonial = (state, action) => {
  return updateObject(state, {
    singleTestimonial: action.response,
  });
};

const createTestimonial = (state, action) => {
  return updateObject(state, {
    testimonialsCreated: true
  });
};

const updateTestimonial = (state, action) => {
  return updateObject(state, {
    testimonialsUpdated: true,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.TESTIMONIALS_RESET:
      return testimonialReset(state, action);
    case actionTypes.TESTIMONIALS_START:
      return testimonialStart(state, action);
    case actionTypes.TESTIMONIALS_FAIL:
      return testimonialFail(state, action);
    case actionTypes.GET_TESTIMONIALS_SUCCESS:
      return getTestimonials(state, action);
    case actionTypes.GET_SINGLE_TESTIMONIAL_SUCCESS:
      return getSingleTestimonial(state, action);
    case actionTypes.ADD_TESTIMONIALS_SUCCESS:
      return createTestimonial(state, action);
    case actionTypes.UPDATE_TESTIMONIALS_SUCCESS:
      return updateTestimonial(state, action);
    default:
      return state;
  }
};

export default reducer;
