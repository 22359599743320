export const GET_ACCOUNT_VERIFICATION_START = "GET_ACCOUNT_VERIFICATION_START";
export const GET_OMANI_ACCOUNT_VERIFICATIONS =
  "GET_OMANI_ACCOUNT_VERIFICATIONS";
export const GET_OMANI_ACCOUNT_VERIFICATIONS_SUCCESS =
  "GET_OMANI_ACCOUNT_VERIFICATIONS_SUCCESS";

export const GET_FOREIGN_ACCOUNT_VERIFICATIONS =
  "GET_FOREIGN_ACCOUNT_VERIFICATIONS";
export const GET_FOREIGN_ACCOUNT_VERIFICATIONS_SUCCESS =
  "GET_FOREIGN_ACCOUNT_VERIFICATIONS_SUCCESS";
export const GET_FOREIGN_INVESTOR_DETAIL = "GET_FOREIGN_INVESTOR_DETAIL";
export const GET_FOREIGN_INVESTOR_DETAIL_SUCCESS =
  "GET_FOREIGN_INVESTOR_DETAIL_SUCCESS";
export const GET_OMANI_INVESTOR_DETAIL = "GET_OMANI_INVESTOR_DETAIL";
export const GET_OMANI_INVESTOR_DETAIL_SUCCESS =
  "GET_OMANI_INVESTOR_DETAIL_SUCCESS";
export const UPDATE_INVESTOR_DETAIL = "UPDATE_INVESTOR_DETAIL";
export const UPDATE_INVESTOR_DETAIL_SUCCESS = "UPDATE_INVESTOR_DETAIL_SUCCESS";
