import { put, call } from "redux-saga/effects";
import axios from "../../../config/AxiosConfig";
import {
  OMANI_ACCOUNT_VERIFICATION_API,
  FOREIGNER_ACCOUNT_VERIFICATION_API,
  ACCOUNT_VERIFICATION_DETAIL_API,
} from "../../actions/apiCollections";
import {
  getOmaniAccountVerificationsSuccess,
  getForeignAccountVerificationsSuccess,
  getOmaniInvestorDetailSuccess,
  getForeignInvestorDetailSuccess,
} from "../../actions/accountVerifications/accountVerificationsActions";

export function* getOmaniAccountVerificationsSaga(action) {
  try {
    const response = yield axios.get(OMANI_ACCOUNT_VERIFICATION_API, {
      params: {
        ...(action?.search?.search !== "" && {
          search: action?.search?.search,
        }),
        ...(action?.search?.status !== "" && {
          status: action?.search?.status,
        }),
        ...(action?.search?.investmentSize !== "" && {
          investmentSize: action?.search?.investmentSize,
        }),
        ...(action?.search?.preferredInvestmentSegment !== "" && {
          preferredInvestmentSegment:
            action?.search?.preferredInvestmentSegment,
        }),
        ...(action?.search?.documentExpiryDateRange !== undefined &&
          action?.search?.documentExpiryDateRange !== "" && {
            documentExpiryDateRange: JSON.stringify(
              action?.search?.documentExpiryDateRange
            ),
          }),
        limit: 10 * action.pageLimit,
      },
    });
    yield put(getOmaniAccountVerificationsSuccess(response.data.data.data));
  } catch (error) {
    console.log(error);
    //   yield put(getInvestorsFail(error));
  }
}

export function* getForeignAccountVerificationsSaga(action) {
  try {
    const response = yield axios.get(FOREIGNER_ACCOUNT_VERIFICATION_API, {
      params: {
        ...(action?.search?.search !== "" && {
          search: action?.search?.search,
        }),
        ...(action?.search?.status !== "" && {
          status: action?.search?.status,
        }),
        ...(action?.search?.investmentSize !== "" && {
          investmentSize: action?.search?.investmentSize,
        }),
        ...(action?.search?.preferredInvestmentSegment !== "" && {
          preferredInvestmentSegment:
            action?.search?.preferredInvestmentSegment,
        }),
        ...(action?.search?.documentExpiryDateRange !== undefined &&
          action?.search?.documentExpiryDateRange !== "" && {
            documentExpiryDateRange: JSON.stringify(
              action?.search?.documentExpiryDateRange
            ),
          }),
        limit: 10 * action.pageLimit,
      },
    });
    yield put(getForeignAccountVerificationsSuccess(response.data.data.data));
  } catch (error) {
    console.log(error);
    //   yield put(getInvestorsFail(error));
  }
}

export function* getOmaniInvestorDetailSaga(action) {
  try {
    const response = yield axios.get(
      `${ACCOUNT_VERIFICATION_DETAIL_API}${action.id}`
    );
    yield put(getOmaniInvestorDetailSuccess(response.data.data.data));
  } catch (error) {
    console.log(error);
    // yield put(frontEndUsersFail(error.response));
  }
}

export function* getForeignInvestorDetailSaga(action) {
  try {
    const response = yield axios.get(
      `${ACCOUNT_VERIFICATION_DETAIL_API}${action.id}`
    );
    yield put(getForeignInvestorDetailSuccess(response.data.data.data));
  } catch (error) {
    console.log(error);
    // yield put(frontEndUsersFail(error.response));
  }
}

export function* updateInvestorDetailSaga(action) {
  try {
    yield axios.patch(
      ACCOUNT_VERIFICATION_DETAIL_API + action.accountData.id,
      action.accountData
    );
    yield call(getForeignAccountVerificationsSaga);
    yield call(getOmaniAccountVerificationsSaga);
  } catch (error) {
    console.log(error);
    // yield put(getInvestorsFail(error));
  }
}
