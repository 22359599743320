import * as actionTypes from "./actionTypes";

export const getWalletTransactions = (pageLimit, searchData) => {
  return {
    type: actionTypes.GET_WALLET_TRANSACTIONS,
    pageLimit,
    searchData,
  };
};

export const getWalletTransactionsSuccess = (response) => {
  return {
    type: actionTypes.GET_WALLET_TRANSACTIONS_SUCCESS,
    response,
  };
};

export const updateWalletTransaction = (id, data, pageLimit, searchData) => {
  return {
    type: actionTypes.UPDATE_WALLET_TRANSACTION,
    id,
    data,
    pageLimit,
    searchData,
  };
};

export const updateWalletTransactionSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_WALLET_TRANSACTION_SUCCESS,
    response,
  };
};

export const walletFail = (response) => {
  return {
    type: actionTypes.WALLET_FAIL,
    response,
  };
};

export const walletStart = (pageLimit) => {
  return {
    type: actionTypes.WALLET_START,
    pageLimit,
  };
};

export const getUserWalletDetail = (walletId) => {
  return {
    type: actionTypes.GET_USER_WALLET_DETAIL,
    walletId,
  };
};

export const getUserWalletDetailSuccess = (response) => {
  return {
    type: actionTypes.GET_USER_WALLET_DETAIL_SUCCESS,
    response,
  };
};

export const adminUserWalletTopup = (topupData) => {
  return {
    type: actionTypes.ADMIN_USER_WALLET_TOPUP,
    topupData,
  };
};

export const adminUserWalletTopupSuccess = (response) => {
  return {
    type: actionTypes.ADMIN_USER_WALLET_TOPUP_SUCCESS,
    response,
  };
};
