import * as actionTypes from "../../actions/accountVerifications/actiontTypes";
import { updateObject } from "../../utility/utility";

const initialState = {
  loading: false,
  error: null,
  omaniInvestors: [],
  foreignInvestors: [],
  foreignInvestor: [],
  omaniInvestor: [],
};

const accountVerificationsStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
    omaniInvestors: [],
    foreignInvestors: [],
  });
};

export const getOmaniAccountVerifications = (state, action) => {
  return updateObject(state, {
    omaniInvestors: action.response,
    omaniInvestor: [],
    loading: false,
  });
};

export const getForeignAccountVerifications = (state, action) => {
  return updateObject(state, {
    foreignInvestors: action.response,
    foreignInvestor: [],
    loading: false,
  });
};

export const getForeignInvestorDetail = (state, action) => {
  return updateObject(state, {
    foreignInvestor: action.response,
    loading: false,
  });
};

export const getOmaniInvestorDetail = (state, action) => {
  return updateObject(state, {
    omaniInvestor: action.response,
    loading: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_ACCOUNT_VERIFICATION_START:
      return accountVerificationsStart(state, action);
    case actionTypes.GET_OMANI_ACCOUNT_VERIFICATIONS_SUCCESS:
      return getOmaniAccountVerifications(state, action);
    case actionTypes.GET_FOREIGN_ACCOUNT_VERIFICATIONS_SUCCESS:
      return getForeignAccountVerifications(state, action);
    case actionTypes.GET_FOREIGN_INVESTOR_DETAIL_SUCCESS:
      return getForeignInvestorDetail(state, action);
    case actionTypes.GET_OMANI_INVESTOR_DETAIL_SUCCESS:
      return getOmaniInvestorDetail(state, action);
    default:
      return state;
  }
};

export default reducer;
