import { put } from "redux-saga/effects";
import axios from "../../../config/AxiosConfig";
import {
  exportCSVStart,
  exportCSVFail,
  getFrontUserCSVListSuccess,
  getRePaymentDistributionListSuccess,
  getDistributionViewCSVListSuccess,
  getRePaymentReceivedCSVListSuccess,
  getWithdrawCSVListSuccess,
  getTransactionsCSVListSuccess,
  getManualKYCCSVListSuccess,
} from "../../actions";
import {
  FRONT_END_USER,
  DISTRIBUTION_API,
  REPAYMENT_API,
  WALLET_TRANSACTION_LIST_API,
  TRANSACTIONS_EXPORT_API,
  MANUALKYC_EXPORT_API,
} from "../../actions/apiCollections";

let PageLimit = 5000;

export function* getFrontUserCSVList(action) {
  yield put(exportCSVStart());
  try {
    const response = yield axios.get(FRONT_END_USER, {
      params: { limit: 10 * PageLimit },
    });
    yield put(getFrontUserCSVListSuccess(response.data.data.data.docs));
  } catch (error) {
    yield put(exportCSVFail(error.response));
  }
}

export function* getRePaymentDistributionCSVListSaga(action) {
  yield put(exportCSVStart());
  try {
    const response = yield axios.get(`${DISTRIBUTION_API}${action.id}`);
    yield put(
      getRePaymentDistributionListSuccess(
        response.data.data.data?.distributionData
      )
    );
  } catch (error) {
    yield put(exportCSVFail(error.response));
  }
}

export function* getDistributionViewCSVListSaga(action) {
  yield put(exportCSVStart());
  try {
    const response = yield axios.get(`${DISTRIBUTION_API}${action.id}`);
    yield put(
      getDistributionViewCSVListSuccess(
        response.data.data.data?.distributionData
      )
    );
  } catch (error) {
    yield put(exportCSVFail(error.response));
  }
}

export function* getRePaymentReceivedCSVListSaga(action) {
  yield put(exportCSVStart());
  try {
    const response = yield axios.get(`${REPAYMENT_API}${action.id}`);
    yield put(
      getRePaymentReceivedCSVListSuccess(response.data.data.data?.repaymentData)
    );
  } catch (error) {
    yield put(exportCSVFail(error.response));
  }
}

export function* getWithdrawCSVListSaga(action) {
  yield put(exportCSVStart());
  try {
    const response = yield axios.get(`${WALLET_TRANSACTION_LIST_API}`, {
      params: {
        ...(action.filterData.startDate !== "" && {
          startDate: action.filterData.startDate,
        }),
        ...(action.filterData.endDate !== "" && {
          endDate: action.filterData.endDate,
        }),
        ...(action.filterData.status !== "" &&
          action.filterData.status !== undefined && {
            status: action.filterData.status,
          }),
        ...(action.filterData.transactionType !== "" && {
          transactionType: action.filterData.transactionType,
        }),
        limit: "all",
      },
    });
    yield put(getWithdrawCSVListSuccess(response.data.data.data));
  } catch (error) {
    yield put(exportCSVFail(error.response));
  }
}

export function* getTransactionsCSVListSaga(action) {
  yield put(exportCSVStart());
  try {
    const response = yield axios.get(`${TRANSACTIONS_EXPORT_API}`, {
      params: {
        ...(action.filterData.startDate !== "" && {
          startDate: action.filterData.startDate,
        }),
        ...(action.filterData.endDate !== "" && {
          endDate: action.filterData.endDate,
        }),
        ...(action.filterData.status !== "" &&
          action.filterData.status !== undefined && {
            preapprovalStatus: action.filterData.status,
          }),
        limit: "all",
      },
    });
    yield put(getTransactionsCSVListSuccess(response.data.data.data));
  } catch (error) {
    yield put(exportCSVFail(error.response));
  }
}

export function* getManualKYCCSVListSaga(action) {
  yield put(exportCSVStart());
  try {
    const response = yield axios.get(`${MANUALKYC_EXPORT_API}`, {
      params: {
        ...(action?.filterData?.search !== "" && {
          search: action?.filterData?.search,
        }),
        ...(action?.filterData?.kycStatus !== "" && {
          kycStatus: action?.filterData?.kycStatus,
        }),
        ...(action?.filterData?.createdAtRange !== undefined &&
          action?.filterData?.createdAtRange !== "" && {
            createdAtRange: JSON.stringify(action?.filterData?.createdAtRange),
          }),
        ...(action?.filterData?.investorCity !== "" && {
          investorCity: action?.filterData?.investorCity,
        }),
        ...(action?.filterData?.investorCountry !== "" && {
          investorCountry: action?.filterData?.investorCountry,
        }),
        ...(action?.filterData?.kycDocumentType !== "" && {
          kycDocumentType: action?.filterData?.kycDocumentType,
        }),
      },
    });
    yield put(getManualKYCCSVListSuccess(response.data.data.data));
  } catch (error) {
    yield put(exportCSVFail(error.response));
  }
}
