import moment from "moment-timezone";

export const convertNumberToDateString = (
  timestamp,
  timeZone = process.env.REACT_APP_DEFAULT_TIMEZONE,
  dateFormat = "DD MMMM, YYYY"
) => {
  try {
    const date = moment(timestamp).tz(timeZone);
    if (!date.isValid()) {
      throw new Error("Invalid timestamp input");
    }
    return date.format(dateFormat);
  } catch (error) {
    console.error("Conversion error:", error.message);
    return null;
  }
};

export const getRelativeTime = (
  date,
  timeZone = process.env.REACT_APP_DEFAULT_TIMEZONE
) => {
  try {
    let momentDate;
    if (typeof date === "string") {
      momentDate = moment.tz(date, timeZone);
    } else if (typeof date === "number") {
      momentDate = moment(date).tz(timeZone);
    } else {
      throw new Error("Invalid date input. Expected string or number.");
    }

    if (!momentDate.isValid()) {
      throw new Error("Invalid date");
    }

    return momentDate.fromNow();
  } catch (error) {
    console.error("Relative time error:", error.message);
    return "Invalid date";
  }
};

export const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};
