import { put, call } from "redux-saga/effects";
import axios from "../../../config/AxiosConfig";
import { INVESTORS_API, USERS_MANUAL_KYC } from "../../actions/apiCollections";
import {
  getInvestorsStart,
  getInvestorsFail,
  getInvestorsSuccess,
  getInvestorSuccess,
  getManualUsersKYCSuccess,
} from "../../actions/accreditation/accreditationActions";
import { getManualKYCExportStart } from "../../actions/exportCsv/exportCsvActions";

export function* getAccreditionInvestorsSaga(action) {
  yield put(getInvestorsStart());
  try {
    const response = yield axios.get(INVESTORS_API + "admin", {
      params: {
        ...(action?.search?.search !== "" && {
          search: action?.search?.search,
        }),
        ...(action?.search?.accredited !== "" && {
          accredited: action?.search?.accredited,
        }),
        ...(action?.search?.investorType !== "" && {
          investorType: action?.search?.investorType,
        }),
        limit: 10 * action.pageLimit,
      },
    });
    yield put(getInvestorsSuccess(response.data.data.data));
  } catch (error) {
    yield put(getInvestorsFail(error));
  }
}

export function* getAccreditionInvestorSaga(action) {
  try {
    const response = yield axios.get(INVESTORS_API + action.id + "/admin");
    yield put(getInvestorSuccess(response.data.data.data));
  } catch (error) {
    yield put(getInvestorsFail(error));
  }
}

export function* accreditationActionSaga(action) {
  try {
    yield axios.patch(
      INVESTORS_API + action.accData.id + "/admin",
      action.accData
    );
    yield call(getAccreditionInvestorsSaga);
  } catch (error) {
    yield put(getInvestorsFail(error));
  }
}

// Manual User KYC
export function* getManualUsersKYCSaga(action) {
  try {
    const response = yield axios.get(USERS_MANUAL_KYC, {
      params: {
        ...(action?.search?.search !== "" && {
          search: action?.search?.search,
        }),
        ...(action?.search?.kycStatus !== "" && {
          kycStatus: action?.search?.kycStatus,
        }),
        ...(action?.search?.createdAtRange !== undefined &&
          action?.search?.createdAtRange !== "" && {
            createdAtRange: JSON.stringify(action?.search?.createdAtRange),
          }),
        ...(action?.search?.investorCity !== "" && {
          investorCity: action?.search?.investorCity,
        }),
        ...(action?.search?.investorCountry !== "" && {
          investorCountry: action?.search?.investorCountry,
        }),
        ...(action?.search?.kycDocumentType !== "" && {
          kycDocumentType: action?.search?.kycDocumentType,
        }),
        limit: 100 * action.pageLimit,
      },
    });
    yield put(getManualUsersKYCSuccess(response.data.data.data));
    yield put(getManualKYCExportStart());
  } catch (error) {
    yield put(getInvestorsFail(error));
  }
}

export function* updateManualUserKYCSaga(action) {
  try {
    let id = new FormData();
    id = action.kycData;
    id = id.get("id");

    yield axios.patch(USERS_MANUAL_KYC + "/" + id, action.kycData);
    yield call(getManualUsersKYCSaga);
  } catch (error) {
    yield put(getInvestorsFail(error));
  }
}
