import * as CURRENCY_SYMBOL_SIDE from "./currencySymbolSideConstant";

/**
 * @description Formatting a number to comma seperated!
 * @param {Number} number
 * @returns
 */

export const numberFormat = (number) => {
  if (number !== "undefined" || number !== undefined) {
    return new Intl.NumberFormat().format(number);
  }
};

/**
 * @description dynamically format the currency amount
 * @param {Number} amount - amount received from the user, (which might from an api response)
 * @param {String} currencySymbolSide - `leftSpaceCode`,`leftCode`,`rightSpaceCode`,`rightCode`
 * @param {String} currencySymbol `$` etc.
 * @param {String} currencyCode `CAD`,`INR`,`USD` etc.
 * @param {Number} decimal `0`,`1`,`2` etc.
 * @example formatCurrency(123.22,'leftSpaceCode','$','USD',1)
 */
export const formatCurrency = (amount, currencySymbolSide, currencySymbol, currencyCode, decimal) => {
  const currentLng = localStorage.getItem("i18nextLng");
  if (amount !== "undefined" || amount !== undefined) {
    let result = Number(amount).toLocaleString(undefined, {
      minimumFractionDigits: decimal ?? 2,
    });
    result = result === "NaN" ? "-" : result;
    switch (currencySymbolSide) {
      case CURRENCY_SYMBOL_SIDE.LEFT_SPACE_CODE:
        return currentLng === "ar" ? currencySymbol + " " + result : result + " " + currencyCode;
      case CURRENCY_SYMBOL_SIDE.RIGHT_SPACE_CODE:
        return currentLng === "ar" ? result + " " + currencySymbol : result + " " + currencyCode;
      case CURRENCY_SYMBOL_SIDE.LEFT_CODE:
        return currentLng === "ar" ? currencySymbol + " " + result : result + " " + currencyCode;
      case CURRENCY_SYMBOL_SIDE.RIGHT_CODE:
        return currentLng === "ar" ? result + " " + currencySymbol : result + " " + currencyCode;
      default:
        break;
    }
  }
};
