import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility/utility";

const initialState = {
  banks: [],
  bankInformation: null,
  loading: false,
  error: null,
  manageOptionsLanguages: [],
};

const banksStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
    errors: null,
    bankInformation: null,
  });
};

const getBanks = (state, action) => {
  return updateObject(state, {
    banks: action.response,
    bankInformation: null,
  });
};

const getBankInfo = (state, action) => {
  return updateObject(state, {
    bankInformation: action.response,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.BANKS_START_START:
      return banksStart(state, action);
    case actionTypes.GET_BANKS_SUCCESS:
      return getBanks(state, action);
    case actionTypes.GET_BANK_INFORMATION_SUCCESS:
      return getBankInfo(state, action);

    default:
      return state;
  }
};

export default reducer;
