/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useDispatch, useSelector } from "react-redux";
import { getPermission } from "../../../../../store/actions/roleManagement/roleActions";
import * as actions from "../../../../../store/actions/index";
import { useIntl } from "react-intl";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const intl = useIntl();
  const getMenuItemActive = (url) => {
    return checkIsActive(location, url)
      ? " menu-item-active menu-item-open "
      : "";
  };
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPermission(localStorage.getItem("role")));
    dispatch(actions.getManageOptionsLanguages());
  }, [dispatch]);
  const { currentPermissions } = useSelector((state) => state.role);
  const { generalSettings } = useSelector((state) => state.settings);

  const { manageOptionsLanguages } = useSelector(
    (state) => state.manageOptions
  );

  const setLanguageOnSubMenuChange = () => {
    dispatch(
      actions.setManageOptionsCurrentId(
        manageOptionsLanguages?.find((lng) => lng.isDefault === true).id
      )
    );
  };

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}

        <li
          className={`menu-item ${getMenuItemActive("/dashboard")}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" id="menuDashboard" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>

        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {currentPermissions?.list_admin ||
        currentPermissions?.list_front_end_users ||
        currentPermissions?.list_front_end_users_group ||
        currentPermissions?.role_management_list ||
        currentPermissions?.accreditation_users_list ||
        currentPermissions?.manual_users_kyc_list ? (
          <>
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/user-management"
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink
                className="menu-link menu-toggle"
                id="menuDashboard"
                to="/user-management"
              >
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Group.svg"
                    )}
                  />
                </span>
                <span className="menu-text">User Management</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <ul className="menu-subnav">
                  <li
                    className="menu-item  menu-item-parent"
                    aria-haspopup="true"
                  >
                    <span className="menu-link">
                      <span className="menu-text">User Management</span>
                    </span>
                  </li>

                  {currentPermissions?.list_admin === 1 ? (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/user-management/admin"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink
                        className="menu-link"
                        id="subMenuAdminUsers"
                        to="/user-management/admin"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Admin Users</span>
                      </NavLink>
                    </li>
                  ) : null}

                  {currentPermissions?.list_front_end_users === 1 ? (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/user-management/front-end-users"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink
                        className="menu-link"
                        id="subMenuFrontEndUsers"
                        to="/user-management/front-end-users"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Front-End Users</span>
                      </NavLink>
                    </li>
                  ) : null}
                  {currentPermissions?.list_front_end_users_group && (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/user-management/front-end-user-group"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink
                        className="menu-link"
                        id="subMenuFrontEndUsersGroup"
                        to="/user-management/front-end-user-group"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Front-End Users Group</span>
                      </NavLink>
                    </li>
                  )}

                  {currentPermissions?.role_management_list === 1 ? (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/user-management/role-management"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink
                        className="menu-link"
                        id="subMenuRoleManagement"
                        to="/user-management/role-management"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Role Management</span>
                      </NavLink>
                    </li>
                  ) : null}

                  {currentPermissions?.accreditation_users_list === 1 ? (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/user-management/accreditation"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink
                        className="menu-link"
                        id="subMenuAccreditationUsers"
                        to="/user-management/accreditation"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Accreditation Users</span>
                      </NavLink>
                    </li>
                  ) : null}

                  {currentPermissions?.manual_users_kyc_list && (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/user-management/manualuserkyc"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink
                        className="menu-link"
                        id="subMenuManualUserKyc"
                        to="/user-management/manualuserkyc"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Manual User KYC</span>
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            </li>
          </>
        ) : null}

        {/*end::1 Level*/}
        {currentPermissions?.omani_account_vefification_list ||
        currentPermissions?.foreign_account_vefification_list ? (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/account-verification"
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              id="menuDashboard"
              to="/account-verification"
            >
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Group.svg"
                  )}
                />
              </span>
              <span className="menu-text">Account Verification</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu ">
              <ul className="menu-subnav">
                {currentPermissions?.manual_users_kyc_list && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/account-verification/omani-account-verifications"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      id="subMenuAdminUsers"
                      to="/account-verification/omani-account-verifications"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Omani Investors</span>
                    </NavLink>
                  </li>
                )}
                {currentPermissions?.foreign_account_vefification_list && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/account-verification/foreign-account-verifications"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      id="subMenuAdminUsers"
                      to="/account-verification/foreign-account-verifications"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Foreign Investors</span>
                    </NavLink>
                  </li>
                )}
              </ul>
            </div>
          </li>
        ) : null}

        {currentPermissions?.omani_children_registration_list ||
        currentPermissions?.foreign_children_registration_list ? (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/children-registration"
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              id="menuDashboard"
              to="/children-registration"
            >
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Group.svg"
                  )}
                />
              </span>
              <span className="menu-text">Children Registration</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu ">
              <ul className="menu-subnav">
                {currentPermissions?.omani_children_registration_list && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/children-registration/omani-children-registration"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      id="subMenuAdminUsers"
                      to="/children-registration/omani-children-registration"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Omani Children</span>
                    </NavLink>
                  </li>
                )}
                {currentPermissions?.foreign_children_registration_list && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/children-registration/foreign-children-registration"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      id="subMenuAdminUsers"
                      to="/children-registration/foreign-children-registration"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Foreign Children</span>
                    </NavLink>
                  </li>
                )}
                {currentPermissions?.child_account_setting && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/children-registration/child-account-setting"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      id="subMenuAdminUsers"
                      to="/children-registration/child-account-setting"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Child Account Setting</span>
                    </NavLink>
                  </li>
                )}
              </ul>
            </div>
          </li>
        ) : null}

        {currentPermissions?.campaign_categories_list ||
        currentPermissions?.admin_campaigns_list ? (
          <>
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/campaign"
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink
                className="menu-link menu-toggle"
                id="menuCampaign"
                to="/campaign"
              >
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Design/Layers.svg")}
                  />
                </span>
                <span className="menu-text">Campaign</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <ul className="menu-subnav">
                  <li
                    className="menu-item  menu-item-parent"
                    aria-haspopup="true"
                  >
                    <span className="menu-link">
                      <span className="menu-text">Campaign</span>
                    </span>
                  </li>

                  {/*begin::2 Level*/}
                  {currentPermissions?.admin_campaigns_list ? (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/campaign/campaigns"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink
                        className="menu-link"
                        id="subMenuCampaigns"
                        to="/campaign/campaigns"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Campaigns</span>
                      </NavLink>
                    </li>
                  ) : null}

                  {currentPermissions?.campaign_categories_list ? (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/campaign/categories"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink
                        className="menu-link"
                        id="subMenuCategories"
                        to="/campaign/categories"
                        onClick={() => setLanguageOnSubMenuChange()}
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Categories</span>
                      </NavLink>
                    </li>
                  ) : null}
                </ul>
              </div>
            </li>
          </>
        ) : null}

        {/*end::1 Level*/}

        {currentPermissions?.admin_payment_gateway_list ||
        currentPermissions?.admin_social_setup_list ||
        currentPermissions?.admin_recaptcha_list ||
        currentPermissions?.admin_show_google_map ||
        currentPermissions?.show_newsletter_settings ? (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/api-management"
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              id="menuApiManagement"
              to="/api-management"
            >
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/Tools/Tools.svg")} />
              </span>
              <span className="menu-text">API Management</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu ">
              <ul className="menu-subnav">
                <li
                  className="menu-item  menu-item-parent"
                  aria-haspopup="true"
                >
                  <span className="menu-link">
                    <span className="menu-text">API Management</span>
                  </span>
                </li>

                {/*begin::2 Level*/}
                {currentPermissions?.admin_payment_gateway_list && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/api-management/payment-gateway"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      id="subMenuPaymentGateway"
                      to="/api-management/payment-gateway"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Payment Gateway</span>
                    </NavLink>
                  </li>
                )}

                {generalSettings?.kycSubmissionType === "BOTH" ||
                generalSettings?.kycSubmissionType === "SHUFTI-PRO" ? (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/api-management/kyc-settings"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      id="subMenuKYCSettings"
                      to="/api-management/kyc-settings"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">KYC Settings</span>
                    </NavLink>
                  </li>
                ) : null}

                {currentPermissions?.admin_social_setup_list && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/api-management/social-signup"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      id="subMenuSocialSetup"
                      to="/api-management/social-signup"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Social Setup</span>
                    </NavLink>
                  </li>
                )}

                {currentPermissions?.admin_recaptcha_list && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/api-management/recaptcha"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      id="subMenuReCaptcha"
                      to="/api-management/recaptcha"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">reCaptcha</span>
                    </NavLink>
                  </li>
                )}

                {currentPermissions?.admin_show_google_map && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/api-management/google-map"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      id="subMenuGoogleMap"
                      to="/api-management/google-map"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Google Map</span>
                    </NavLink>
                  </li>
                )}

                {currentPermissions?.show_newsletter_settings && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/api-management/newsletter"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      id="subMenuNewsletterSettings"
                      to="/api-management/newsletter"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Newsletter Settings</span>
                    </NavLink>
                  </li>
                )}
              </ul>
            </div>
          </li>
        ) : null}

        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {currentPermissions?.admin_show_transactions && (
          <li
            className={`menu-item ${getMenuItemActive("/transaction")}`}
            aria-haspopup="true"
          >
            <NavLink
              className="menu-link"
              id="menuTransactions"
              to="/transaction"
            >
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Shopping/Money.svg")}
                />
              </span>
              <span className="menu-text">Transactions</span>
            </NavLink>
          </li>
        )}

        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {(currentPermissions?.show_wallet_transactions ||
          currentPermissions?.show_wallet_top_up_requests ||
          currentPermissions?.admin_show_wallet_withdraw_requests) &&
        generalSettings?.walletModule === "yes" ? (
          <li
            className={`menu-item ${getMenuItemActive("/wallet")}`}
            aria-haspopup="true"
          >
            <NavLink
              className="menu-link menu-toggle"
              id="menuWallet"
              to="/wallet"
            >
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Shopping/Wallet.svg")}
                />
              </span>
              <span className="menu-text">Wallet</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu ">
              <ul className="menu-subnav">
                <li
                  className="menu-item  menu-item-parent"
                  aria-haspopup="true"
                >
                  <span className="menu-link">
                    <span className="menu-text">Wallet</span>
                  </span>
                </li>
                {currentPermissions?.show_wallet_transactions && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/wallet/wallet-transactions"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      id="subMenuWalletTransactions"
                      to="/wallet/wallet-transactions"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Wallet Transactions</span>
                    </NavLink>
                  </li>
                )}

                {currentPermissions?.show_wallet_top_up_requests && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/wallet/topup-requests"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      id="subMenuTopupRequests"
                      to="/wallet/topup-requests"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Top-up Requests</span>
                    </NavLink>
                  </li>
                )}

                {currentPermissions?.admin_show_wallet_withdraw_requests && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/wallet/withdraw-requests"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      id="subMenuWithdrawRequests"
                      to="/wallet/withdraw-requests"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Withdraw Requests</span>
                    </NavLink>
                  </li>
                )}
              </ul>
            </div>
          </li>
        ) : null}
        {/*end::1 Level*/}

        {currentPermissions?.admin_page_list ||
        currentPermissions?.investor_contracts_list ||
        currentPermissions?.menu_list ||
        currentPermissions?.faqs_list ||
        currentPermissions?.front_end_faqs_list ||
        currentPermissions?.show_home_section ? (
          <>
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/content-page"
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink
                className="menu-link menu-toggle"
                id="menuContentPages"
                to="/content-page"
              >
                <span className="svg-icon menu-icon">
                  <SVG src={toAbsoluteUrl("/media/svg/icons/Files/File.svg")} />
                </span>
                <span className="menu-text">Content Pages</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <ul className="menu-subnav">
                  <li
                    className="menu-item  menu-item-parent"
                    aria-haspopup="true"
                  >
                    <span className="menu-link">
                      <span className="menu-text">Content Pages</span>
                    </span>
                  </li>

                  {currentPermissions?.admin_page_list && (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/content-page/pages"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink
                        className="menu-link"
                        id="subMenuPages"
                        to="/content-page/pages"
                        onClick={() => setLanguageOnSubMenuChange()}
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Pages</span>
                      </NavLink>
                    </li>
                  )}

                  {currentPermissions?.investor_contracts_list && (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/content-page/investor-contracts"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink
                        className="menu-link"
                        id="subMenuPages"
                        to="/content-page/investor-contracts"
                        onClick={() => setLanguageOnSubMenuChange()}
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Investor Contracts</span>
                      </NavLink>
                    </li>
                  )}

                  {currentPermissions?.menu_list && (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/content-page/menu"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink
                        className="menu-link"
                        id="subMenuMenu"
                        to="/content-page/menu"
                        onClick={() => setLanguageOnSubMenuChange()}
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Menu</span>
                      </NavLink>
                    </li>
                  )}

                  {currentPermissions?.faqs_list && (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/content-page/faqs"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink
                        className="menu-link"
                        id="subMenuFaqs"
                        to="/content-page/faqs"
                        onClick={() => setLanguageOnSubMenuChange()}
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">FAQs</span>
                      </NavLink>
                    </li>
                  )}

                  {currentPermissions?.front_end_faqs_list && (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/content-page/front-end-faqs"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink
                        className="menu-link"
                        id="subMenuFrontFaqs"
                        to="/content-page/front-end-faqs"
                        onClick={() => setLanguageOnSubMenuChange()}
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Front-End FAQs</span>
                      </NavLink>
                    </li>
                  )}

                  {currentPermissions?.show_home_section && (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/content-page/home-section"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink
                        className="menu-link"
                        id="subMenuHomeSection"
                        to="/content-page/home-section"
                        onClick={() => setLanguageOnSubMenuChange()}
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Home Section</span>
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            </li>
          </>
        ) : null}

        {/*end::1 Level*/}

        {currentPermissions?.deal_types_list ||
        currentPermissions?.company_industry_list ||
        currentPermissions?.team_member_type_list ||
        currentPermissions?.admin_investor_type_list ||
        currentPermissions?.campaign_stage_list ||
        currentPermissions?.investing_round_list ? (
          <li
            className={`menu-item menu-item-submenu ${getMenuItemActive(
              "/manage-options"
            )}`}
            aria-haspopup="true"
            data-menu-toggle="hover"
          >
            <NavLink
              className="menu-link menu-toggle"
              id="menuManageOptions"
              to="/manage-options"
            >
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Home/Commode1.svg")}
                />
              </span>
              <span className="menu-text">Manage Options</span>
              <i className="menu-arrow" />
            </NavLink>
            <div className="menu-submenu ">
              <ul className="menu-subnav">
                <li
                  className="menu-item  menu-item-parent"
                  aria-haspopup="true"
                >
                  <span className="menu-link">
                    <span className="menu-text">Manage Options</span>
                  </span>
                </li>

                {currentPermissions?.deal_types_list && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/manage-options/deal-type"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      id="subMenuDealTypes"
                      to="/manage-options/deal-type"
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Deal Types</span>
                    </NavLink>
                  </li>
                )}

                {currentPermissions?.company_industry_list && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/manage-options/company-industry"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      id="subMenuCompanyIndustry"
                      to="/manage-options/company-industry"
                      onClick={() => setLanguageOnSubMenuChange()}
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Company Industry</span>
                    </NavLink>
                  </li>
                )}

                {currentPermissions?.team_member_type_list && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/manage-options/team-member-type"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      id="subMenuTeamMemberType"
                      to="/manage-options/team-member-type"
                      onClick={() => setLanguageOnSubMenuChange()}
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Team Member Type</span>
                    </NavLink>
                  </li>
                )}

                {currentPermissions?.admin_investor_type_list && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/manage-options/investor-type"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      id="subMenuInvestorType"
                      to="/manage-options/investor-type"
                      onClick={() => setLanguageOnSubMenuChange()}
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Investor Type</span>
                    </NavLink>
                  </li>
                )}

                {currentPermissions?.campaign_stage_list && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/manage-options/campaign-stage"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      id="subMenuStage"
                      to="/manage-options/campaign-stage"
                      onClick={() => setLanguageOnSubMenuChange()}
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">
                        {intl.formatMessage({
                          id: `${
                            generalSettings?.projectScriptType === 0
                              ? "CAMPAIGN.STAGE"
                              : "PROPERTY.STAGE"
                          }`,
                        })}
                      </span>
                    </NavLink>
                  </li>
                )}

                {currentPermissions?.investing_round_list && (
                  <li
                    className={`menu-item ${getMenuItemActive(
                      "/manage-options/investing-round"
                    )}`}
                    aria-haspopup="true"
                  >
                    <NavLink
                      className="menu-link"
                      id="subMenuInvestingRound"
                      to="/manage-options/investing-round"
                      onClick={() => setLanguageOnSubMenuChange()}
                    >
                      <i className="menu-bullet menu-bullet-dot">
                        <span />
                      </i>
                      <span className="menu-text">Investing Round</span>
                    </NavLink>
                  </li>
                )}
              </ul>
            </div>
          </li>
        ) : null}

        {/*end::1 Level*/}

        {currentPermissions?.sliders_list ||
        currentPermissions?.language_list ||
        currentPermissions?.cron_jobs_list ||
        currentPermissions?.currency_list ||
        currentPermissions?.country_list ||
        currentPermissions?.banks_list ? (
          <>
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/miscellaneous"
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink
                className="menu-link menu-toggle"
                id="menuMiscellaneous"
                to="/miscellaneous"
              >
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Files/Group-folders.svg"
                    )}
                  />
                </span>
                <span className="menu-text">Miscellaneous</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <ul className="menu-subnav">
                  <li
                    className="menu-item  menu-item-parent"
                    aria-haspopup="true"
                  >
                    <span className="menu-link">
                      <span className="menu-text">Miscellaneous</span>
                    </span>
                  </li>

                  {/*begin::2 Level*/}
                  {currentPermissions?.banks_list && (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/miscellaneous/banks"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink
                        className="menu-link"
                        id="subMenuBanks"
                        to="/miscellaneous/banks"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Banks</span>
                      </NavLink>
                    </li>
                  )}

                  {currentPermissions?.country_list && (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/miscellaneous/countries"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink
                        className="menu-link"
                        id="subMenuCountries"
                        to="/miscellaneous/countries"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Countries</span>
                      </NavLink>
                    </li>
                  )}

                  {currentPermissions?.currency_list && (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "miscellaneous/currency"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink
                        className="menu-link"
                        id="subMenuCurrency"
                        to="/miscellaneous/currency"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Currency</span>
                      </NavLink>
                    </li>
                  )}

                  {currentPermissions?.cron_jobs_list && (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/miscellaneous/ron-jobs"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink
                        className="menu-link"
                        id="subMenuCronJobs"
                        to="/miscellaneous/cron-jobs"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Cron Jobs</span>
                      </NavLink>
                    </li>
                  )}

                  {currentPermissions?.language_list && (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/miscellaneous/language"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink
                        className="menu-link"
                        id="subMenuLanguage"
                        to="/miscellaneous/language"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Language</span>
                      </NavLink>
                    </li>
                  )}

                  {currentPermissions?.sliders_list && (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/miscellaneous/sliders"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink
                        className="menu-link"
                        id="subMenuSliders"
                        to="/miscellaneous/sliders"
                        onClick={() => setLanguageOnSubMenuChange()}
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Sliders</span>
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            </li>
          </>
        ) : null}

        {/*end::1 Level*/}
        {currentPermissions?.show_meta_settings ||
        currentPermissions?.show_amount_settings ||
        currentPermissions?.show_meta_settings ||
        currentPermissions?.show_date_time_settings ||
        currentPermissions?.show_revenue_settings ||
        currentPermissions?.show_campaign_settings ||
        currentPermissions?.show_general_settings ? (
          <>
            <li
              className={`menu-item menu-item-submenu ${getMenuItemActive(
                "/settings"
              )}`}
              aria-haspopup="true"
              data-menu-toggle="hover"
            >
              <NavLink
                className="menu-link menu-toggle"
                id="menuSettings"
                to="/settings"
              >
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/General/Settings-2.svg"
                    )}
                  />
                </span>
                <span className="menu-text">Settings</span>
                <i className="menu-arrow" />
              </NavLink>
              <div className="menu-submenu ">
                <ul className="menu-subnav">
                  <li
                    className="menu-item  menu-item-parent"
                    aria-haspopup="true"
                  >
                    <span className="menu-link">
                      <span className="menu-text">Settings</span>
                    </span>
                  </li>

                  {currentPermissions?.show_general_settings && (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/settings/general"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink
                        className="menu-link"
                        id="subMenuGeneral"
                        to="/settings/general"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">General</span>
                      </NavLink>
                    </li>
                  )}

                  {currentPermissions?.show_campaign_settings && (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/settings/settings-campaign"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink
                        className="menu-link"
                        id="subMenuCampaign"
                        to="/settings/settings-campaign"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Campaign</span>
                      </NavLink>
                    </li>
                  )}
                  {currentPermissions?.show_revenue_settings && (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/settings/revenue"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink
                        className="menu-link"
                        id="subMenuRevenue"
                        to="/settings/revenue"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Revenue</span>
                      </NavLink>
                    </li>
                  )}

                  {currentPermissions?.show_date_time_settings && (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/settings/date-time"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink
                        className="menu-link"
                        id="subMenuDateAndTime"
                        to="/settings/date-time"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Date and Time</span>
                      </NavLink>
                    </li>
                  )}

                  {currentPermissions?.show_meta_settings && (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/settings/meta"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink
                        className="menu-link"
                        id="subMenuMeta"
                        to="/settings/meta"
                        onClick={() => setLanguageOnSubMenuChange()}
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Meta</span>
                      </NavLink>
                    </li>
                  )}

                  {currentPermissions?.show_amount_settings && (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/settings/amount"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink
                        className="menu-link"
                        id="subMenuAmount"
                        to="/settings/amount"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Amount</span>
                      </NavLink>
                    </li>
                  )}

                  {currentPermissions?.show_meta_settings && (
                    <li
                      className={`menu-item ${getMenuItemActive(
                        "/settings/email-templates"
                      )}`}
                      aria-haspopup="true"
                    >
                      <NavLink
                        className="menu-link"
                        id="subMenuEmail"
                        to="/settings/email-templates"
                      >
                        <i className="menu-bullet menu-bullet-dot">
                          <span />
                        </i>
                        <span className="menu-text">Email</span>
                      </NavLink>
                    </li>
                  )}
                </ul>
              </div>
            </li>
          </>
        ) : null}

        {/*end::1 Level*/}

        {currentPermissions?.contact_enquiry_list && (
          <li
            className={`menu-item ${getMenuItemActive("/contact-list")}`}
            aria-haspopup="true"
          >
            <NavLink
              className="menu-link"
              id="menuContactEnquiry"
              to="/contact-list"
            >
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Group.svg"
                  )}
                />
              </span>
              <span className="menu-text">Contact Enquiry</span>
            </NavLink>
          </li>
        )}

        {currentPermissions?.list_testimonials && (
          <li
            className={`menu-item ${getMenuItemActive("/testimonials")}`}
            aria-haspopup="true"
          >
            <NavLink
              className="menu-link"
              id="menuTestimonial"
              to="/testimonials"
            >
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Group.svg"
                  )}
                />
              </span>
              <span className="menu-text">Testimonials</span>
            </NavLink>
          </li>
        )}

        {/*begin::1 Level*/}
        {currentPermissions?.newsletters_list && (
          <li
            className={`menu-item ${getMenuItemActive("/newsletter")}`}
            aria-haspopup="true"
          >
            <NavLink
              className="menu-link"
              id="menuNewsletters"
              to="/newsletter"
            >
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/Media/Airplay.svg")}
                />
              </span>
              <span className="menu-text">Newsletters</span>
            </NavLink>
          </li>
        )}
        {currentPermissions?.database_list && (
          <li
            className={`menu-item ${getMenuItemActive("/database-management")}`}
            aria-haspopup="true"
          >
            <NavLink
              className="menu-link"
              id="menudatabasemanagement"
              to="/database-management"
            >
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/database.svg")}
                />
              </span>
              <span className="menu-text">Database Management</span>
            </NavLink>
          </li>
        )}

        {currentPermissions?.show_maintenance_mode && (
          <li
            className={`menu-item ${getMenuItemActive("/maintenance-mode")}`}
            aria-haspopup="true"
          >
            <NavLink
              className="menu-link"
              id="menuMaintenanceMode"
              to="/maintenance-mode"
            >
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")}
                />
              </span>
              <span className="menu-text">Maintenance Mode</span>
            </NavLink>
          </li>
        )}

        {currentPermissions?.show_spammers && (
          <li
            className={`menu-item ${getMenuItemActive(
              "/spam-settings/spammer"
            )}`}
            aria-haspopup="true"
          >
            <NavLink
              className="menu-link"
              id="subMenuSpammer"
              to="/spam-settings/spammer"
            >
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Settings-1.svg")}
                />
              </span>
              <span className="menu-text">Spammers</span>
            </NavLink>
          </li>
        )}
      </ul>
    </>
  );
}
