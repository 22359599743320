import * as actionTypes from "../../actions/accreditation/actiontTypes";
import { updateObject } from "../../utility/utility";

const initialState = {
  loading: false,
  users: [],
  error: null,
  user: [],
  manualUsersKYC: [],
};

const accreditationStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
    user: [],
    manualUsersKYC: [],
  });
};

export const accreditationFaill = (state, action) => {
  return updateObject(state, {
    error: action.error,
    loading: false,
  });
};

export const getAccreditations = (state, action) => {
  return updateObject(state, {
    users: action.response,
    loading: false,
  });
};
export const getAccreditation = (state, action) => {
  return updateObject(state, {
    user: action.response,
    loading: false,
  });
};

export const getManualUsersKYCSuccess = (state, action) => {
  return updateObject(state, {
    manualUsersKYC: action.response,
    loading: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_INVESTORS_START:
      return accreditationStart(state, action);
    case actionTypes.GET_INVESTORS_FAIL:
      return accreditationFaill(state, action);
    case actionTypes.GET_INVESTORS_SUCCESS:
      return getAccreditations(state, action);
    case actionTypes.GET_INVESTOR_SUCCESS:
      return getAccreditation(state, action);
    case actionTypes.GET_MANUAL_USERS_KYC_SUCCESS:
      return getManualUsersKYCSuccess(state, action);

    default:
      return state;
  }
};

export default reducer;
