import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility/utility";

const initialState = {
  loading: false,
  errors: [],
  frontEndUsers: [],
  frontEndUser: [],
  frontEndUsersGroups: [],
  frontEndUsersByGroups: [],
  groupUpcomingCampaignList: [],
  groupMembersList: [],
  upcomingCampaignByGroupList: [],
  insertSuccess: false,
  updateSuccess: false,
  frontEndUsersAddStatus: false,
  groupCampaignAddStatus: false,
  userTypes: [],
  verificationMsg: null,
  resendKYCMsg: null,
  earlyBirdUsers: [],
};
const frontEndUsersStart = (state, action) => {
  return updateObject(state, {
    erros: null,
    loading: true,
    insertSuccess: false,
    updateSuccess: false,
    frontEndUsersAddStatus: false,
    groupCampaignAddStatus: false,
    frontEndUser: [],
    error: false,
    verificationMsg: null,
    resendKYCMsg: null,
  });
};
const frontEndUsersFail = (state, action) => {
  return updateObject(state, {
    errors: action.error,
    error: true,
  });
};

const frontEndUsers = (state, action) => {
  return updateObject(state, {
    frontEndUsers: action.response,
    loading: false,
    insertSuccess: false,
    updateSuccess: false,
  });
};

const insertFrontEndUser = (state, action) => {
  return updateObject(state, {
    insertSuccess: true,
  });
};

const updateFrontEndUser = (state, action) => {
  return updateObject(state, {
    updateSuccess: true,
  });
};

const getFrontEndUser = (state, action) => {
  return updateObject(state, {
    frontEndUser: action.response,
  });
};

const bulkUpdateUser = (state, action) => {
  return updateObject(state, {
    bulkUpdateSuccess: true,
    loading: false,
  });
};

const getUserTypes = (state, action) => {
  return updateObject(state, {
    userTypes: action.typesData,
  });
};

const resendUserVerificationURL = (state, action) => {
  return updateObject(state, {
    verificationMsg: action.response,
  });
};

const clearResendUserVerification = (state, action) => {
  return updateObject(state, {
    verificationMsg: null,
  });
};

const resendUserKYCEmailSuccess = (state, action) => {
  return updateObject(state, {
    resendKYCMsg: action.response,
  });
};

const clearResendUserKYCEmail = (state, action) => {
  return updateObject(state, {
    resendKYCMsg: null,
  });
};

const frontEndUsersGroup = (state, action) => {
  return updateObject(state, {
    frontEndUsersGroups: action.response,
    frontEndUsersAddStatus: false,
    errors: [],
    error: false,
  });
};

const frontEndUsersListByGroup = (state, action) => {
  return updateObject(state, {
    frontEndUsersByGroups: action.response,
    frontEndUsersAddStatus: false,
    errors: [],
    error: false,
  });
};

const addFrontEndUsersStatus = (state, action) => {
  return updateObject(state, {
    frontEndUsersAddStatus: true,
    errors: [],
    error: false,
  });
};
const addCampaignsInGroupStatus = (state, action) => {
  return updateObject(state, {
    groupCampaignAddStatus: true,
    errors: [],
    error: false,
  });
};

const getAllMembersInvestorGroups = (state, action) => {
  return updateObject(state, {
    groupMembersList: action.response,
    frontEndUsersAddStatus: false,
    groupCampaignAddStatus: false,
    errors: [],
    error: false,
  });
};

const getAllUpcomingCampaignGroups = (state, action) => {
  return updateObject(state, {
    groupUpcomingCampaignList: action.response,
    frontEndUsersAddStatus: false,
    groupCampaignAddStatus: false,
    errors: [],
    error: false,
  });
};

const upcomingCampaignByGroup = (state, action) => {
  return updateObject(state, {
    upcomingCampaignByGroupList: action.response,
  });
};

const earlyBirdInvestorRequestSuccess = (state, action) => {
  return updateObject(state, {
    earlyBirdUsers: action.response,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FRONT_END_USERS_START:
      return frontEndUsersStart(state, action);
    case actionTypes.FRONT_END_USERS_FAIL:
      return frontEndUsersFail(state, action);
    case actionTypes.GET_ALL_FRONT_END_USERS_SUCCESS:
      return frontEndUsers(state, action);
    case actionTypes.CREATE_FRONT_END_USER_SUCCESS:
      return insertFrontEndUser(state, action);
    case actionTypes.GET_FRONT_END_USER_SUCCESS:
      return getFrontEndUser(state, action);
    case actionTypes.UPDATE_FRONT_END_USER_SUCCESS:
      return updateFrontEndUser(state, action);
    case actionTypes.BULK_UPDATE_FRONT_END_USER_SUCCESS:
      return bulkUpdateUser(state, action);
    case actionTypes.USER_TYPE_SUCCESS:
      return getUserTypes(state, action);
    case actionTypes.RESEND_USER_VERIFICATION_EMAIL_SUCCESS:
      return resendUserVerificationURL(state, action);
    case actionTypes.CLEAR_RESEND_USER_VERIFICATION_EMAIL:
      return clearResendUserVerification(state, action);

    case actionTypes.RESEND_USER_KYC_EMAIL_SUCCESS:
      return resendUserKYCEmailSuccess(state, action);
    case actionTypes.CLEAR_RESEND_USER_KYC_EMAIL:
      return clearResendUserKYCEmail(state, action);

    case actionTypes.GET_ALL_FRONT_END_USERS_GROUP_SUCCESS:
      return frontEndUsersGroup(state, action);
    case actionTypes.GET_ALL_FRONT_END_USERS_BY_GROUP_SUCCESS:
      return frontEndUsersListByGroup(state, action);
    case actionTypes.ALL_FRONT_END_USERS_IN_GROUP_SUCCESS:
      return addFrontEndUsersStatus(state, action);

    case actionTypes.GET_ALL_MEMBERS_INVESTOR_GROUPS_SUCCESS:
      return getAllMembersInvestorGroups(state, action);
    case actionTypes.GET_ALL_UPCOMING_CAMPAIGN_GROUPS_SUCCESS:
      return getAllUpcomingCampaignGroups(state, action);

    case actionTypes.UPCOMING_CAMPAIGN_BY_GROUP_SUCCESS:
      return upcomingCampaignByGroup(state, action);
    case actionTypes.ADD_CAMPAIGNS_IN_GROUP_SUCCESS:
      return addCampaignsInGroupStatus(state, action);
    case actionTypes.EARLY_BIRD_INVESTOR_REQUEST_SUCCESS:
      return earlyBirdInvestorRequestSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
