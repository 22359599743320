import * as actionTypes from "./actiontTypes";

export const getaccountVerificationStart = () => {
  return {
    type: actionTypes.GET_ACCOUNT_VERIFICATION_START,
  };
};

export const getOmaniAccountVerifications = (search, pageLimit) => {
  return {
    type: actionTypes.GET_OMANI_ACCOUNT_VERIFICATIONS,
    search,
    pageLimit,
  };
};

export const getOmaniAccountVerificationsSuccess = (response) => {
  return {
    type: actionTypes.GET_OMANI_ACCOUNT_VERIFICATIONS_SUCCESS,
    response,
  };
};

export const getOmaniInvestorDetail = (id) => {
  return {
    type: actionTypes.GET_OMANI_INVESTOR_DETAIL,
    id,
  };
};

export const getOmaniInvestorDetailSuccess = (response) => {
  return {
    type: actionTypes.GET_OMANI_INVESTOR_DETAIL_SUCCESS,
    response,
  };
};

export const getForeignAccountVerifications = (search, pageLimit) => {
  return {
    type: actionTypes.GET_FOREIGN_ACCOUNT_VERIFICATIONS,
    search,
    pageLimit,
  };
};

export const getForeignAccountVerificationsSuccess = (response) => {
  return {
    type: actionTypes.GET_FOREIGN_ACCOUNT_VERIFICATIONS_SUCCESS,
    response,
  };
};

export const getForeignInvestorDetail = (id) => {
  return {
    type: actionTypes.GET_FOREIGN_INVESTOR_DETAIL,
    id,
  };
};

export const getForeignInvestorDetailSuccess = (response) => {
  return {
    type: actionTypes.GET_FOREIGN_INVESTOR_DETAIL_SUCCESS,
    response,
  };
};

export const updateInvestorDetail = (accountData) => {
  return {
    type: actionTypes.UPDATE_INVESTOR_DETAIL,
    accountData,
  };
};
