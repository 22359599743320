import * as actionTypes from "./actionTypes";

export const testimonialReset = () => {
  return {
    type: actionTypes.TESTIMONIALS_RESET,
  };
};

export const testimonialStart = (generalSettings) => {
    return {
      type: actionTypes.TESTIMONIALS_START,
    };
};
  
export const testimonialFail = (error) => {
    return {
      type: actionTypes.TESTIMONIALS_FAIL,
      error,
    };
};

export const createTestimonial = (testimonial) => {
    return {
      type: actionTypes.ADD_TESTIMONIALS,
      testimonial,
    };
};
  
export const createTestimonialSuccess = (response) => {
    return {
      type: actionTypes.ADD_TESTIMONIALS_SUCCESS,
      response,
    };
};

export const updateTestimonial = (testimonial, id) => {
    return {
      type: actionTypes.UPDATE_TESTIMONIALS,
      testimonial,
      id
    };
};
  
export const updateTestimonialSuccess = (response) => {
    return {
      type: actionTypes.UPDATE_TESTIMONIALS_SUCCESS,
      response,
    };
};

export const getTestimonial = (id) => {
    return {
      type: actionTypes.GET_SINGLE_TESTIMONIAL,
      id,
    };
};
  
export const getTestimonialSuccess = (response) => {
    return {
      type: actionTypes.GET_SINGLE_TESTIMONIAL_SUCCESS,
      response,
    };
};

export const getAllTestimonials = () => {
    return {
      type: actionTypes.GET_TESTIMONIALS,
    };
};
  
export const getAllTestimonialsSuccess = (response) => {
    return {
      type: actionTypes.GET_TESTIMONIALS_SUCCESS,
      response,
    };
};

export const deleteTestimonial = (id) => {
    return {
      type: actionTypes.DELETE_TESTIMONIALS,
      id,
    };
};

export const bulkUpdateTestimonial = (bulkData) => {
    return {
      type: actionTypes.BULK_UPDATE_TESTIMONIALS,
      bulkData,
    };
};

export const bulkDeleteTestimonial = (bulkData) => {
    return {
      type: actionTypes.BULK_DELETE_TESTIMONIALS,
      bulkData,
    };
};