export const GET_INVESTORS_START =
  "[Accredition Component] Get Investors Start";
export const GET_INVESTORS_FAIL = "[Accredition Component] Get Investors Fail";
export const GET_INVESTORS = "[Accredition Component] Get Investors";
export const GET_INVESTORS_SUCCESS =
  "[Accredition Component] Get Investors Success";
export const GET_INVESTOR = "[Accredition Component] Get Investor";
export const GET_INVESTOR_SUCCESS =
  "[Accredition Component] Get Investor Success";
export const ACCREDITATION_ACTION = "[Accredition Component] Acc. Action";
export const ACCREDITATION_ACTION_RESPONSE =
  "[Accredition Component] Acc. Action Response";

// Manual KYC Module
export const GET_MANUAL_USERS_KYC = "GET_MANUAL_USERS_KYC";
export const GET_MANUAL_USERS_KYC_SUCCESS = "GET_MANUAL_USERS_KYC_SUCCESS";
export const UPDATE_MANUAL_USER_KYC = "UPDATE_MANUAL_USER_KYC";
export const UPDATE_MANUAL_USER_KYC_SUCCESS = "UPDATE_MANUAL_USER_KYC_SUCCESS";
