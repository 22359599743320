import * as actionTypes from "./actionTypes";

export const frontEndUsersStart = () => {
  return {
    type: actionTypes.FRONT_END_USERS_START,
  };
};

export const getFrontEndUsers = (search, active, pageLimit) => {
  return {
    type: actionTypes.GET_ALL_FRONT_END_USERS,
    search,
    active,
    pageLimit,
  };
};

export const getFrontEndUsersSuccess = (response) => {
  return {
    type: actionTypes.GET_ALL_FRONT_END_USERS_SUCCESS,
    response,
  };
};

/**
 *
 * @param {String} id `String`
 */
export const deleteFrontEndUser = (id) => {
  return {
    type: actionTypes.DELETE_FRONT_END_USER,
    id,
  };
};

export const createFrontEndUser = (frontEndUser) => {
  return {
    type: actionTypes.CREATE_FRONT_END_USER,
    frontEndUser,
  };
};

export const createFrontEndUserSuccess = (response) => {
  return {
    type: actionTypes.CREATE_FRONT_END_USER_SUCCESS,
    response,
  };
};

export const getFrontEndUser = (id) => {
  return {
    type: actionTypes.GET_FRONT_END_USER,
    id,
  };
};

export const getFrontEndUserSuccess = (response) => {
  return {
    type: actionTypes.GET_FRONT_END_USER_SUCCESS,
    response,
  };
};

export const updateFrontEndUser = (frontEndUser) => {
  return {
    type: actionTypes.UPDATE_FRONT_END_USER,
    frontEndUser,
  };
};

export const updateFrontEndUserSuccess = (response) => {
  return {
    type: actionTypes.UPDATE_FRONT_END_USER_SUCCESS,
    response,
  };
};

export const frontEndUsersFail = (error) => {
  return {
    type: actionTypes.FRONT_END_USERS_FAIL,
    error,
  };
};

export const userTypes = (filterData) => {
  return {
    type: actionTypes.USER_TYPE,
    filterData: filterData,
  };
};

export const userTypesSuccess = (typesData) => {
  return {
    type: actionTypes.USER_TYPE_SUCCESS,
    typesData: typesData,
  };
};

// BATCH START

export const batchFrontEndUpdate = (frontEndData) => {
  return {
    type: actionTypes.BULK_UPDATE_FRONT_END_USER,
    frontEndData,
  };
};

export const batchFrontEndUpdateSuccess = (response) => {
  return {
    type: actionTypes.BULK_UPDATE_FRONT_END_USER_SUCCESS,
    response,
  };
};

export const batchDelteFrontEndUser = (frontEndData) => {
  return {
    type: actionTypes.BULK_DELETE_FRONT_END_USER,
    frontEndData,
  };
};

export const clearResendUserVerification = () => {
  return {
    type: actionTypes.CLEAR_RESEND_USER_VERIFICATION_EMAIL,
  };
};

export const resendUserVerificationURL = (userData) => {
  return {
    type: actionTypes.RESEND_USER_VERIFICATION_EMAIL,
    userData,
  };
};

export const resendUserVerificationURLSuccess = (response) => {
  return {
    type: actionTypes.RESEND_USER_VERIFICATION_EMAIL_SUCCESS,
    response,
  };
};

export const clearResendUserKYCEmail = () => {
  return {
    type: actionTypes.CLEAR_RESEND_USER_KYC_EMAIL,
  };
};

export const resendUserKYCEmail = (userData) => {
  return {
    type: actionTypes.RESEND_USER_KYC_EMAIL,
    userData,
  };
};

export const resendUserKYCEmailSuccess = (response) => {
  return {
    type: actionTypes.RESEND_USER_KYC_EMAIL_SUCCESS,
    response,
  };
};

// Front end users group

export const frontEndUsersGroup = (search, pageLimit) => {
  return {
    type: actionTypes.GET_ALL_FRONT_END_USERS_GROUP,
    search,
    pageLimit,
  };
};

export const frontEndUsersGroupSuccess = (response) => {
  return {
    type: actionTypes.GET_ALL_FRONT_END_USERS_GROUP_SUCCESS,
    response,
  };
};

export const createUsersGroup = (userData) => {
  return {
    type: actionTypes.CREATE_USERS_GROUP,
    userData,
  };
};

export const updateUsersGroup = (userData) => {
  return {
    type: actionTypes.UPDATE_USERS_GROUP,
    userData,
  };
};

export const deleteUserGroup = (id) => {
  return {
    type: actionTypes.DELETE_USER_GROUP,
    id,
  };
};

export const frontEndUsersByGroup = (id, pageLimit, search) => {
  return {
    type: actionTypes.GET_ALL_FRONT_END_USERS_BY_GROUP,
    id,
    pageLimit,
    search,
  };
};

export const frontEndUsersByGroupSuccess = (response) => {
  return {
    type: actionTypes.GET_ALL_FRONT_END_USERS_BY_GROUP_SUCCESS,
    response,
  };
};

export const addFrontEndUsersInGroup = (userData) => {
  return {
    type: actionTypes.ALL_FRONT_END_USERS_IN_GROUP,
    userData,
  };
};

export const addFrontEndUsersInGroupSuccess = () => {
  return {
    type: actionTypes.ALL_FRONT_END_USERS_IN_GROUP_SUCCESS,
  };
};

export const getAllMembersInvestorGroups = (id, pageLimit, search) => {
  return {
    type: actionTypes.GET_ALL_MEMBERS_INVESTOR_GROUPS,
    id,
    pageLimit,
    search,
  };
};

export const getAllMembersInvestorGroupsSuccess = (response) => {
  return {
    type: actionTypes.GET_ALL_MEMBERS_INVESTOR_GROUPS_SUCCESS,
    response,
  };
};

export const deleteMembersInvestorGroups = (memberId, id) => {
  return {
    type: actionTypes.DELETE_MEMBERS_INVESTOR_GROUPS,
    memberId,
    id,
  };
};

export const getAllUpcomingCampaignGroups = (id, pageLimit, search) => {
  return {
    type: actionTypes.GET_ALL_UPCOMING_CAMPAIGN_GROUPS,
    id,
    pageLimit,
    search,
  };
};

export const getAllUpcomingCampaignGroupsSuccess = (response) => {
  return {
    type: actionTypes.GET_ALL_UPCOMING_CAMPAIGN_GROUPS_SUCCESS,
    response,
  };
};

export const upcomingCampaignByGroup = (id, pageLimit, search) => {
  return {
    type: actionTypes.UPCOMING_CAMPAIGN_BY_GROUP,
    id,
    pageLimit,
    search,
  };
};

export const upcomingCampaignByGroupSuccess = (response) => {
  return {
    type: actionTypes.UPCOMING_CAMPAIGN_BY_GROUP_SUCCESS,
    response,
  };
};

export const addCampaignsInGroup = (campaignData) => {
  return {
    type: actionTypes.ADD_CAMPAIGNS_IN_GROUP,
    campaignData,
  };
};

export const addCampaignsInGroupSuccess = () => {
  return {
    type: actionTypes.ADD_CAMPAIGNS_IN_GROUP_SUCCESS,
  };
};

export const earlyBirdInvestorRequest = (search, pageLimit) => {
  return {
    type: actionTypes.EARLY_BIRD_INVESTOR_REQUEST,
    pageLimit,
    search,
  };
};

export const earlyBirdInvestorRequestSuccess = (response) => {
  return {
    type: actionTypes.EARLY_BIRD_INVESTOR_REQUEST_SUCCESS,
    response,
  };
};

export const addEarlyBirdInvestorRequest = (userData) => {
  return {
    type: actionTypes.ADD_EARLY_BIRD_INVESTOR_REQUEST,
    userData,
  };
};
