import * as actionTypes from "./actionTypes";

export const banksStart = () => {
  return {
    type: actionTypes.BANKS_START_START,
  };
};

export const getBanks = (language) => {
  return {
    type: actionTypes.GET_BANKS,
    language,
  };
};

export const getBanksSuccess = (response) => {
  return {
    type: actionTypes.GET_BANKS_SUCCESS,
    response,
  };
};

export const getBankInformation = (id, language) => {
  return {
    type: actionTypes.GET_BANK_INFORMATION,
    id,
    language,
  };
};

export const getBankInformationSuccess = (response) => {
  return {
    type: actionTypes.GET_BANK_INFORMATION_SUCCESS,
    response,
  };
};

export const createBank = (bankData) => {
  return {
    type: actionTypes.CREATE_BANK,
    bankData,
  };
};

export const updateBank = (bankData) => {
  return {
    type: actionTypes.UPDATE_BANK,
    bankData,
  };
};

export const deleteBank = (id, language) => {
  return {
    type: actionTypes.DELETE_BANK,
    id,
    language,
  };
};
