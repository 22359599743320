import { tr } from "date-fns/locale";
import * as actionTypes from "../../actions/childrenRegistration/actiontTypes";
import { updateObject } from "../../utility/utility";

const initialState = {
  loading: false,
  error: null,
  isDataUpdated: false,
  isDataUpdatedError: null,
  omaniChildrens: [],
  foreignChildrens: [],
  foreignChildren: [],
  omaniChildren: [],
};

const childrenRegistrationStart = (state, action) => {
  return updateObject(state, {
    error: null,
    loading: true,
    isDataUpdated: false,
    omaniChildrens: [],
    foreignChildrens: [],
  });
};

export const startIsLoading = (state, action) => {
  return updateObject(state, {
    loading: true,
  });
};

export const getOmaniChildrenRegistration = (state, action) => {
  return updateObject(state, {
    omaniChildrens: action.response,
    omaniChildren: [],
    loading: false,
    isDataUpdated: false,
  });
};

export const getForeignChildrenRegistration = (state, action) => {
  return updateObject(state, {
    foreignChildrens: action.response,
    foreignChildren: [],
    loading: false,
    isDataUpdated: false,
  });
};

export const getForeignChildrenRegistrationDetail = (state, action) => {
  return updateObject(state, {
    foreignChildren: action.response,
    loading: false,
    isDataUpdated: false,
  });
};

export const getOmaniChildrenRegistrationDetail = (state, action) => {
  return updateObject(state, {
    omaniChildren: action.response,
    loading: false,
    isDataUpdated: false,
  });
};

export const updateChildrenRegistration = (state, action) => {
  return updateObject(state, {
    loading: false,
    isDataUpdated: true,
  });
};

export const updateChildrenRegistrationFail = (state, action) => {
  return updateObject(state, {
    isDataUpdatedError: action.error,
    loading: false,
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_CHILDREN_REGISTRATION_START:
      return childrenRegistrationStart(state, action);
    case actionTypes.START_ISLOADING:
      return startIsLoading(state, action);
    case actionTypes.GET_OMANI_CHILDREN_REGISTRATION_SUCCESS:
      return getOmaniChildrenRegistration(state, action);
    case actionTypes.GET_FOREIGN_CHILDREN_REGISTRATION_SUCCESS:
      return getForeignChildrenRegistration(state, action);
    case actionTypes.GET_FOREIGN_CHILDREN_REGISTRATION_DETAIL_SUCCESS:
      return getForeignChildrenRegistrationDetail(state, action);
    case actionTypes.GET_OMANI_CHILDREN_REGISTRATION_DETAIL_SUCCESS:
      return getOmaniChildrenRegistrationDetail(state, action);
    case actionTypes.UPDATE_CHILDREN_REGISTRATION_SUCCESS:
      return updateChildrenRegistration(state, action);
    case actionTypes.UPDATE_CHILDREN_REGISTRATION_FAIL:
      return updateChildrenRegistrationFail(state, action);
    default:
      return state;
  }
};

export default reducer;
