/* eslint-disable no-unused-vars */
import { call, put } from "redux-saga/effects";
import {
  HELP_CENTER_API,
  HELP_CATEGORY,
  PAGES_API,
  FAQS_API,
  GET_LANGUAGES,
  FOOTER_FAQ_API,
  INVESTOR_CONTRACT_API,
  HOME_SECTION,
  CLONE_INVESTOR_CONTRACT_API,
} from "../actions/apiCollections";
import axios from "../../config/AxiosConfig";
import {
  contePagesStart,
  getFAQsSuccess,
  getManageLanguageSuccess,
  getFAQSuccess,
  createFAQSuccess,
  updateFAQSuccess,
  getFooterFAQsSuccess,
  getFooterFAQSuccess,
  createFooterFAQSuccess,
  updateFooterFAQSuccess,
  getInvestorContractsSuccess,
  getInvestorContractByIdSuccess,
  updateInvestorContractSuccess,
  getPagesSuccess,
  contentPageFail,
  createPageSuccess,
  getPageSuccess,
  updatePageSuccess,
  getHelpCategoriesSuccess,
  getHelpCategorySuccess,
  createHelpCategorySuccess,
  updateHelpCategorySuccess,
  getHelpCentersSuccess,
  getManageOptionsCurrentId,
  createHelpCenterSuccess,
  getHelpCenterSuccess,
  updateHelpCenterSuccess,
  getHomeSectionSuccess,
  updateHomeSectionuccess,
} from "../actions/index";

export function* getFAQs(action) {
  yield put(contePagesStart());
  try {
    const lang = action.language ? `&language=${action.language}` : "";
    const response = yield axios.get(`${FAQS_API}?sort=name${lang}`);
    yield put(getFAQsSuccess(response?.data?.data?.data?.docs));
  } catch (error) {
    yield put(contentPageFail(error.response));
  }
}

export function* getHomeSectionSaga(action) {
  yield put(contePagesStart());
  try {
    const lang = action.language ? `language=${action.language}` : "";
    const response = yield axios.get(`${HOME_SECTION}/front?${lang}`);
    yield put(getHomeSectionSuccess(response?.data?.data?.data));
  } catch (error) {
    yield put(contentPageFail(error.response));
  }
}

export function* updateHomeSectionSaga(action) {
  try {
    const response = yield axios.patch(
      `${HOME_SECTION}/${action.id}`,
      action.data
    );
    yield put(updateHomeSectionuccess(response.data.data.data));
    yield call(getHomeSectionSaga, action);
  } catch (error) {
    yield put(contentPageFail(error.response));
  }
}

export function* getHelpCenters(action) {
  yield put(contePagesStart());
  try {
    const lang = action.language ? `&language=${action.language}` : "";
    const response = yield axios.get(`${HELP_CENTER_API}?sort=name${lang}`);
    yield put(getHelpCentersSuccess(response.data.data.data));
  } catch (error) {
    yield put(contentPageFail(error.response));
  }
}

export function* getPages(action) {
  try {
    const response = yield axios.get(`${PAGES_API}`, {
      params: {
        ...(action.language !== "" && {
          language: action.language,
        }),
        ...(action.title !== "" && {
          title: action.title,
        }),

        limit: 10 * action.pageLimit,
      },
    });
    yield put(getPagesSuccess(response.data.data.data));
  } catch (error) {
    yield put(contentPageFail(error.response));
  }
}

export function* getHelpCategoriesSaga(action) {
  yield put(contePagesStart());
  try {
    const lang = action.language ? `&language=${action.language}` : "";
    const response = yield axios.get(`${HELP_CATEGORY}?sort=name${lang}`, {
      params: { status: action.status },
    });
    yield put(getHelpCategoriesSuccess(response.data.data.data));
  } catch (error) {
    yield put(contentPageFail(error.response));
  }
}
export function* createFAQSaga(action) {
  try {
    const response = yield axios.post(FAQS_API, action.faq);
    yield put(createFAQSuccess(response.data.data.data));
    yield call(getFAQs, action);
  } catch (error) {
    yield put(contentPageFail(error.response));
  }
}

export function* createPageSaga(action) {
  try {
    const response = yield axios.post(`${PAGES_API}`, action.page, {
      params: {
        language: action.page.get("language"),
      },
    });
    yield put(createPageSuccess(response.data.data.data));
  } catch (error) {
    yield put(contentPageFail(error.response.data.errors));
  }
}
export function* createHelpCenter(action) {
  try {
    const response = yield axios.post(`${HELP_CENTER_API}?lng=en`, action.help);
    yield put(createHelpCenterSuccess(response.data.data.data));
  } catch (error) {
    yield put(contentPageFail(error.response.data.errors));
  }
}

export function* createHelpCategory(action) {
  try {
    const response = yield axios.post(`${HELP_CATEGORY}?lng=en`, action.help);
    yield put(createHelpCategorySuccess(response.data.data.data));
  } catch (error) {
    yield put(contentPageFail(error.response.data.errors));
  }
}

export function* deleteFAQSaga(action) {
  try {
    const response = yield axios.delete(`${FAQS_API}/${action.id}`);
    yield call(getFAQs, action);
  } catch (error) {
    yield put(contentPageFail(error.response));
  }
}

export function* deletePageSaga(action) {
  try {
    const response = yield axios.delete(`${PAGES_API}/${action.id}`);
    let data = {
      language: action.language,
    };
    yield call(getPages, data);
  } catch (error) {
    yield put(contentPageFail(error.response));
  }
}

export function* deleteHelpCategory(action) {
  try {
    const response = yield axios.delete(`${HELP_CATEGORY}/${action.id}`);
    yield call(getHelpCategoriesSaga, action);
  } catch (error) {
    yield put(contentPageFail(error.response));
  }
}

export function* deleteHelpCenter(action) {
  try {
    yield axios.delete(`${HELP_CENTER_API}/${action.id}`);
    yield call(getHelpCenters, action);
  } catch (error) {
    yield put(contentPageFail(error.response));
  }
}
export function* getFAQSaga(action) {
  try {
    const response = yield axios.get(`${FAQS_API}/${action.id}`);
    yield put(getFAQSuccess(response.data.data.data));
  } catch (error) {
    yield put(contentPageFail(error.response));
  }
}

export function* getPage(action) {
  try {
    const response = yield axios.get(`${PAGES_API}/${action.id}`, {
      params: {
        ...(action.language !== "" && {
          language: action.language,
        }),
      },
    });
    yield put(getPageSuccess(response.data.data.data[0]));
  } catch (error) {
    yield put(contentPageFail(error.response));
  }
}

export function* getHelpCenter(action) {
  try {
    const response = yield axios.get(`${HELP_CENTER_API}/${action.id}`);
    yield put(getHelpCenterSuccess(response.data.data.data));
  } catch (error) {
    yield put(contentPageFail(error.response));
  }
}
export function* getHelpCategory(action) {
  try {
    const response = yield axios.get(`${HELP_CATEGORY}/${action.id}`);
    yield put(getHelpCategorySuccess(response.data.data.data));
  } catch (error) {
    yield put(contentPageFail(error.response));
  }
}
export function* updateFAQSaga(action) {
  try {
    const response = yield axios.patch(
      `${FAQS_API}/${action.faq.id}`,
      action.faq
    );
    yield put(updateFAQSuccess(response.data.data.data));
    yield call(getFAQs, action);
  } catch (error) {
    yield put(contentPageFail(error.response));
  }
}

export function* updateHelpCategory(action) {
  try {
    const response = yield axios.patch(
      `${HELP_CATEGORY}/${action.help.id}`,
      action.help
    );
    yield put(updateHelpCategorySuccess(response.data.data.data));
  } catch (error) {
    yield put(contentPageFail(error.response.data.errors));
  }
}

export function* updatePage(action) {
  let id = new FormData();
  id = action.page;
  id = id.get("id");
  try {
    const response = yield axios.patch(`${PAGES_API}/${id}`, action.page);
    yield put(updatePageSuccess(response.data.data.data));
  } catch (error) {
    yield put(contentPageFail(error.response.data.errors));
  }
}

export function* updateHelpCenter(action) {
  let id = new FormData();
  id = action.help;
  id = id.get("id");
  try {
    const response = yield axios.patch(`${HELP_CENTER_API}/${id}`, action.help);
    yield put(updateHelpCenterSuccess(response.data.data.data));
  } catch (error) {
    yield put(contentPageFail(error.response.data.errors));
  }
}

export function* getLanguagesSuccessSaga() {
  try {
    const response = yield axios.get(
      `${GET_LANGUAGES}?fields=id,name&sort=name`
    );
    yield put(getManageLanguageSuccess(response.data.data.data));
    yield put(getManageOptionsCurrentId(response.data.data.data[0].id));
  } catch (error) {
    yield put(contentPageFail(error.response.data.errors));
  }
}

export function* getFooterFAQs(action) {
  yield put(contePagesStart());
  try {
    const lang = action.language ? `?language=${action.language}` : "";
    const response = yield axios.get(`${FOOTER_FAQ_API}/${lang}`, {
      params: {
        ...(action.searchData !== "" && {
          search: action.searchData,
        }),
        limit: 10 * action.pageLimit,
      },
    });
    yield put(getFooterFAQsSuccess(response?.data?.data?.data));
  } catch (error) {
    yield put(contentPageFail(error.response));
  }
}

export function* createFooterFAQSaga(action) {
  try {
    const response = yield axios.post(`${FOOTER_FAQ_API}/`, action.faq);
    yield put(createFooterFAQSuccess(response.data.data.data));
  } catch (error) {
    yield put(contentPageFail(error.response));
  }
}

export function* deleteFooterFAQSaga(action) {
  try {
    const response = yield axios.delete(`${FOOTER_FAQ_API}/${action.id}`);
    yield call(getFooterFAQs, action);
  } catch (error) {
    yield put(contentPageFail(error.response));
  }
}

export function* getFooterFAQSaga(action) {
  try {
    const response = yield axios.get(`${FOOTER_FAQ_API}/${action.id}`);
    yield put(getFooterFAQSuccess(response.data.data.data));
  } catch (error) {
    yield put(contentPageFail(error.response));
  }
}

export function* updateFooterFAQSaga(action) {
  try {
    const response = yield axios.patch(
      `${FOOTER_FAQ_API}/${action.faq.id}`,
      action.faq
    );
    yield put(updateFooterFAQSuccess(response.data.data.data));
  } catch (error) {
    yield put(contentPageFail(error.response));
  }
}

export function* getInvestorContractsSaga(action) {
  yield put(contePagesStart());
  try {
    const response = yield axios.get(INVESTOR_CONTRACT_API, {
      params: {
        ...(action.language !== "" && {
          language: action.language,
        }),
      },
    });
    yield put(getInvestorContractsSuccess(response.data.data.data));
  } catch (error) {
    yield put(contentPageFail(error.response));
  }
}

export function* getInvestorContractByIdSaga(action) {
  yield put(contePagesStart());
  try {
    const response = yield axios.get(
      `${INVESTOR_CONTRACT_API}/${action.contractId}`
    );
    yield put(getInvestorContractByIdSuccess(response.data.data.data));
  } catch (error) {
    yield put(contentPageFail(error.response));
  }
}

export function* updateInvestorContractSaga(action) {
  yield put(contePagesStart());
  try {
    const response = yield axios.patch(
      `${INVESTOR_CONTRACT_API}/${action.investorContract.get("id")}`,
      action.investorContract
    );
    yield put(updateInvestorContractSuccess(response.data.data.data));
  } catch (error) {
    yield put(contentPageFail(error.response));
  }
}

export function* copyInvestorContractSaga(action) {
  try {
    yield axios.post(`${CLONE_INVESTOR_CONTRACT_API}`, action.copyContract);
    let data = {
      language: action.copyContract.language,
    };
    yield call(getInvestorContractsSaga, data);
  } catch (error) {
    yield put(contentPageFail(error.response));
  }
}

export function* deleteCopyInvestorContractSaga(action) {
  try {
    yield axios.delete(`${INVESTOR_CONTRACT_API}/${action.contractId}`);
    let dataObj = {
      language: action.language,
    };
    yield call(getInvestorContractsSaga, dataObj);
  } catch (error) {
    yield put(contentPageFail(error.response));
  }
}
