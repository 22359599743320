/* eslint-disable no-unused-vars */
import { put, call } from "redux-saga/effects";
import axios from "../../config/AxiosConfig";
import {
  miscStart,
  getCurrenciesSuccess,
  createCurrencySuccess,
  getCurrencySuccess,
  updateCurrencySuccess,
  languagesSuccess,
  languageId,
  miscFail,
  getLanguagesSuccess,
  getMiscLanguagesSuccess,
  createMiscLanguageSuccess,
  getMiscLanguageSuccess,
  miscLanguageUpdateSuccess,
  getMiscSliderSuccess,
  createMiscSliderSuccess,
  getSliderSuccess,
  updateMiscSliderSuccess,
  getMenuSuccess,
  getContentPageSuccess,
  getMenuItemSuccess,
  bulkUpdateCurrencySuccess,
  bulkSliderUpdateSuccess,
  getSuccessStoriesSuccess,
  getSuccessStorySuccess,
  createSuccessStoriesSucess,
} from "../actions/index";
import {
  CURRENCY_API,
  GET_LANGUAGES,
  SLIDERS_API,
  MENU_API,
  PAGES_API,
  CURRENCY_BULK_UPDATE,
  CURRENCY_BULK_DELETE,
  SLIDER_BULK_DELETE,
  SLIDER_BULK_UPDATE,
  SET_DEFAULT_LANGUAGE_API,
  STORIES_API,
} from "../actions/apiCollections";
export function* getCurrenciesSaga() {
  // yield put(miscStart());
  try {
    const response = yield axios.get(CURRENCY_API);
    yield put(getCurrenciesSuccess(response.data.data.data));
  } catch (error) {
    yield put(miscFail(error));
  }
}

export function* createCurrencySaga(action) {
  try {
    const response = yield axios.post(CURRENCY_API, action.currency);
    yield put(createCurrencySuccess(response));
  } catch (error) {
    yield put(miscFail(error));
  }
}

export function* deleteCurrencySaga(action) {
  try {
    const response = yield axios.delete(`${CURRENCY_API}/${action.id}`);
    yield call(getCurrenciesSaga);
  } catch (error) {
    yield put(miscFail(error));
  }
}

export function* bulkUpdateCurrency(action) {
  try {
    const response = yield axios.post(CURRENCY_BULK_UPDATE, action.currency);
    yield put(bulkUpdateCurrencySuccess(response.data.data.data));
    yield call(getCurrenciesSaga);
  } catch (error) {
    yield put(miscFail(error));
  }
}

export function* bulkDeleteCurrency(action) {
  try {
    yield axios.post(CURRENCY_BULK_DELETE, action.currency);
    yield call(getCurrenciesSaga);
  } catch (error) {
    yield put(miscFail(error));
  }
}

export function* getCurrencySaga(action) {
  try {
    const response = yield axios.get(`${CURRENCY_API}/${action.id}`);
    yield put(getCurrencySuccess(response.data.data.data));
  } catch (error) {
    yield put(miscFail(error));
  }
}

export function* updateCurrencySaga(action) {
  try {
    const response = yield axios.patch(
      `${CURRENCY_API}/${action.currencyData.id}`,
      action.currencyData
    );
    yield put(updateCurrencySuccess(response.data.data.data));
  } catch (error) {
    yield put(miscFail(error.response.data.error));
  }
}

export function* getMiscLanguagesSaga() {
  // yield put(miscStart());
  try {
    const response = yield axios.get(GET_LANGUAGES);
    yield put(getMiscLanguagesSuccess(response.data.data.data));
  } catch (error) {
    yield put(miscFail(error));
  }
}

export function* setDefaultLanguageSaga(action) {
  // yield put(miscStart());
  try {
    yield axios.patch(SET_DEFAULT_LANGUAGE_API + action.id);
    yield call(getMiscLanguagesSaga);
  } catch (error) {
    yield put(miscFail(error));
  }
}

export function* createMiscLanguageSaga(action) {
  try {
    const response = yield axios.post(
      `${GET_LANGUAGES}?lng=en`,
      action.languageData
    );
    yield put(createMiscLanguageSuccess(response));
  } catch (error) {
    yield put(miscFail(error.response.data.errors));
  }
}

export function* deleteLanguageSaga(action) {
  try {
    const response = yield axios.delete(`${GET_LANGUAGES}/${action.id}`);
    yield call(getMiscLanguagesSaga);
  } catch (error) {
    yield put(miscFail(error.response.data.errors));
  }
}

export function* getMiscLanguageSaga(action) {
  try {
    const response = yield axios.get(`${GET_LANGUAGES}/${action.id}`);
    yield put(getMiscLanguageSuccess(response.data.data.data));
  } catch (error) {
    yield put(miscFail(error.response.data.errors));
  }
}

export function* updateMiscLanguageSaga(action) {
  let id = new FormData();
  id = action.languageData;
  id = id.get("id");
  try {
    const response = yield axios.patch(
      `${GET_LANGUAGES}/${id}`,
      action.languageData
    );
    yield put(miscLanguageUpdateSuccess(response.data.data.data));
  } catch (error) {
    yield put(miscFail(error.response.data.errors));
  }
}

export function* getSlidersSaga(action) {
  // yield put(miscStart());
  try {
    const lang = action.language ? `?language=${action.language}` : "";
    const response = yield axios.get(`${SLIDERS_API}${lang}`);
    yield put(getMiscSliderSuccess(response.data.data.data));
  } catch (error) {
    yield put(miscFail(error.response.data.errors));
  }
}

export function* getMenuSaga(action) {
  // yield put(miscStart());
  try {
    const lang = action.language ? `?language=${action.language}` : "";
    const response = yield axios.get(`${MENU_API}${action.menuType}${lang}`);
    yield put(getMenuSuccess(response.data.data.data));
  } catch (error) {
    yield put(miscFail(error.response.data.errors));
  }
}

export function* getPageSaga(action) {
  // yield put(miscStart());
  try {
    const lang = action.language ? `?language=${action.language}` : "";
    const response = yield axios.get(`${PAGES_API}${lang}`, {
      params: {
        limit: 10 * 10,
      },
    });
    yield put(getContentPageSuccess(response.data.data.data));
  } catch (error) {
    yield put(miscFail(error.response.data.errors));
  }
}

export function* createMenuSaga(action) {
  // yield put(miscStart());
  try {
    yield axios.post(`${MENU_API}${action.menuItem.menuType}`, action.menuItem);
    yield call(getMenuSaga, action.menuItem);
  } catch (error) {
    yield put(miscFail(error.response.data.errors));
  }
}

export function* deleteMenuItemSaga(action) {
  try {
    yield axios.delete(`${MENU_API}${action.menuType}/${action.id}`);
    yield call(getMenuSaga, action);
  } catch (error) {
    yield put(miscFail(error.response.data.errors));
  }
}

export function* getMenuItem(action) {
  try {
    const response = yield axios.get(
      `${MENU_API}${action.menuType}/${action.id}`
    );
    yield put(getMenuItemSuccess(response.data.data.data));
  } catch (error) {
    yield put(miscFail(error.response.data.errors));
  }
}

export function* updateMenuItem(action) {
  // yield put(miscStart());
  try {
    yield axios.patch(
      `${MENU_API}${action.menu.menuType}/${action.menu.id}`,
      action.menu
    );
    yield call(getMenuSaga, action.menu);
  } catch (error) {
    yield put(miscFail(error));
  }
}
export function* createSliderSaga(action) {
  let sliderLanguage = new FormData();
  sliderLanguage = action.slider;
  sliderLanguage = sliderLanguage.get("language");
  try {
    const response = yield axios.post(`${SLIDERS_API}`, action.slider);
    yield put(createMiscSliderSuccess(response.data.data.data));
    let data = {
      language: sliderLanguage,
    };
    yield call(getSlidersSaga, data);
  } catch (error) {
    yield put(miscFail(error.response.data.errors));
  }
}

export function* deleteSliderSaga(action) {
  try {
    const response = yield axios.delete(`${SLIDERS_API}/${action.id}`);
    yield call(getSlidersSaga, action);
  } catch (error) {
    yield put(miscFail(error.response));
  }
}

export function* bulkSliderUpdateSaga(action) {
  try {
    const response = yield axios.post(SLIDER_BULK_UPDATE, action.slider);
    yield put(bulkSliderUpdateSuccess(response.data.data.data));
    yield call(getSlidersSaga, action);
  } catch (error) {
    yield put(miscFail(error.response));
  }
}

export function* bulkSliderDeleteSaga(action) {
  try {
    yield axios.post(SLIDER_BULK_DELETE, action.slider);
    yield call(getSlidersSaga, action);
  } catch (error) {
    yield put(miscFail(error.response));
  }
}

export function* getSliderSaga(action) {
  try {
    const response = yield axios.get(`${SLIDERS_API}/${action.id}`);
    yield put(getSliderSuccess(response.data.data.data));
  } catch (error) {
    yield put(miscFail(error.respone));
  }
}

export function* updateSliderSaga(action) {
  let id = new FormData();
  id = action.slider;
  id = id.get("id");
  let sliderLanguage = action.slider;
  sliderLanguage = sliderLanguage.get("language");
  try {
    const response = yield axios.patch(`${SLIDERS_API}/${id}`, action.slider);
    let data = {
      language: sliderLanguage,
    };
    yield call(getSlidersSaga, data);
    yield put(updateMiscSliderSuccess(response));
  } catch (error) {
    yield put(miscFail(error));
  }
}

export function* createSuccessStoriesSaga(action) {
  try {
    const response = yield axios.post(STORIES_API, action.story);
    yield put(createSuccessStoriesSucess(response.data.data.data));
  } catch (error) {
    yield put(miscFail(error));
  }
}

export function* getsuccessStoriesSaga() {
  try {
    const response = yield axios.get(STORIES_API);
    yield put(getSuccessStoriesSuccess(response.data.data.data.docs));
  } catch (error) {
    yield put(miscFail(error));
  }
}
export function* getSuccessStorySaga(action) {
  try {
    const response = yield axios.get(STORIES_API + action.id);
    yield put(getSuccessStorySuccess(response.data.data.data));
  } catch (error) {
    yield put(miscFail(error));
  }
}

/**
 * @description Global Saga for Manage language Dropdown
 */
export function* getLanguagesSaga() {
  try {
    const response = yield axios.get(`${GET_LANGUAGES}?fields=id,name`);
    yield put(languagesSuccess(response.data.data.data));
    yield put(languageId(response.data.data.data[0].id));
    yield put(getLanguagesSuccess(response.data.data.data));
  } catch (error) {
    yield put(miscFail(error));
  }
}
