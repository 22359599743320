/* eslint-disable no-unused-vars */
import { call, put } from "redux-saga/effects";
import axios from "../../config/AxiosConfig";
import {
  FRONT_END_USER,
  BULK_FRONT_END_USER,
  USER_TYPE_API,
  RESEND_USER_VERIFICATION_API,
  RESEND_USER_KYC_EMAIL_API,
  INVESTOR_GROUP_API,
} from "../actions/apiCollections";
import {
  frontEndUsersStart,
  frontEndUsersFail,
  getFrontEndUsersSuccess,
  createFrontEndUserSuccess,
  getFrontEndUserSuccess,
  updateFrontEndUserSuccess,
  batchFrontEndUpdateSuccess,
  batchDelteFrontEndUser,
  resendUserVerificationURLSuccess,
  resendUserKYCEmailSuccess,
  userTypesSuccess,
  frontEndUsersGroupSuccess,
  frontEndUsersByGroupSuccess,
  addFrontEndUsersInGroupSuccess,
  getAllMembersInvestorGroupsSuccess,
  getAllUpcomingCampaignGroupsSuccess,
  upcomingCampaignByGroupSuccess,
  addCampaignsInGroupSuccess,
  earlyBirdInvestorRequestSuccess,
} from "../actions/index";

export function* getFrontEndUsersSaga(action) {
  yield put(frontEndUsersStart());
  try {
    const response = yield axios.get(FRONT_END_USER, {
      params: {
        ...(action.search !== "" && {
          search: action.search,
        }),
        ...(action.active !== "" && {
          active: action.active,
        }),
        limit: 10 * action.pageLimit,
      },
    });
    yield put(getFrontEndUsersSuccess(response.data.data.data));
  } catch (error) {
    yield put(frontEndUsersFail(error.response));
  }
}

export function* deleteFrontEndUserSaga(action) {
  try {
    yield axios.delete(`${FRONT_END_USER}${action.id}`);
    let obj = {
      search: "",
      pageLimit: 1,
    };
    yield call(getFrontEndUsersSaga, obj);
  } catch (error) {
    yield put(frontEndUsersFail(error.response));
  }
}

export function* createFrontEndUserSaga(action) {
  try {
    const response = yield axios.post(
      `${FRONT_END_USER}?lng=en`,
      action.frontEndUser
    );
    yield put(createFrontEndUserSuccess(response.data.data.data));
  } catch (error) {
    yield put(frontEndUsersFail(error.response.data.errors));
  }
}

export function* getFrontEndUserSaga(action) {
  try {
    const response = yield axios.get(
      `${FRONT_END_USER}${action.id}?language=60221e4c5416132910ef4437`
    );
    yield put(getFrontEndUserSuccess(response.data.data.data));
  } catch (error) {
    yield put(frontEndUsersFail(error.response));
  }
}

export function* updateFrontEndUserSaga(action) {
  let id = new FormData();
  id = action.frontEndUser;
  id = id.get("id");
  try {
    const response = yield axios.patch(
      `${FRONT_END_USER}${id}?lng=en`,
      action.frontEndUser
    );
    yield put(updateFrontEndUserSuccess(response));
    let obj = {
      search: "",
      pageLimit: 1,
    };
    yield call(getFrontEndUsersSaga, obj);
  } catch (error) {
    yield put(frontEndUsersFail(error.response));
  }
}

export function* batchUpdateFrontEndUserSaga(action) {
  try {
    const response = yield axios.post(
      `${BULK_FRONT_END_USER}`,
      action.frontEndData
    );
    yield put(frontEndUsersStart());
    yield put(batchFrontEndUpdateSuccess(response.data.data.data));
    let obj = {
      search: "",
      pageLimit: 1,
    };
    yield call(getFrontEndUsersSaga, obj);
  } catch (error) {
    yield put(frontEndUsersFail(error.response));
  }
}

export function* batchDeleteFrontEndUserSaga(action) {
  yield put(frontEndUsersStart());
  try {
    yield axios.delete(`${BULK_FRONT_END_USER}`, action.frontEndData);
    let obj = {
      search: "",
      pageLimit: 1,
    };
    yield call(getFrontEndUsersSaga, obj);
  } catch (error) {
    yield put(frontEndUsersFail(error.response));
  }
}

export function* userTypeSaga(filterData) {
  try {
    const response = yield axios.get(USER_TYPE_API);
    yield put(userTypesSuccess(response.data.data.data.docs));
  } catch (error) {
    console.log(error);
    // yield put(authRegistrationFail(error.response.data.errors[0].msg));
  }
}

export function* resendUserVerificationSaga(action) {
  yield put(frontEndUsersStart());
  try {
    const response = yield axios.post(
      `${RESEND_USER_VERIFICATION_API}`,
      action.userData
    );

    yield put(resendUserVerificationURLSuccess(response.data.data.data));
  } catch (error) {
    yield put(frontEndUsersFail(error.response));
  }
}

export function* resendUserKYCEmailSaga(action) {
  yield put(frontEndUsersStart());
  try {
    const response = yield axios.post(
      `${RESEND_USER_KYC_EMAIL_API}`,
      action.userData
    );

    yield put(resendUserKYCEmailSuccess(response.data.data.data));
  } catch (error) {
    yield put(frontEndUsersFail(error.response));
  }
}

export function* frontEndUsersGroupSaga(action) {
  yield put(frontEndUsersStart());
  try {
    const response = yield axios.get(INVESTOR_GROUP_API, {
      params: {
        ...(action.search !== "" && {
          search: action.search,
        }),
        limit: 10 * action.pageLimit,
      },
    });
    yield put(frontEndUsersGroupSuccess(response.data.data.data));
  } catch (error) {
    yield put(frontEndUsersFail(error.response));
  }
}

export function* createUsersGroupSaga(action) {
  try {
    yield axios.post(`${INVESTOR_GROUP_API}`, action.userData);
    let obj = {
      search: "",
      pageLimit: 1,
    };
    yield call(frontEndUsersGroupSaga, obj);
  } catch (error) {
    yield put(frontEndUsersFail(error.response));
  }
}

export function* deleteUserGroupSaga(action) {
  try {
    yield axios.delete(`${INVESTOR_GROUP_API}/${action.id}`);
    let obj = {
      search: "",
      pageLimit: 1,
    };
    yield call(frontEndUsersGroupSaga, obj);
  } catch (error) {
    yield put(frontEndUsersFail(error.response));
  }
}

export function* updateUsersGroupSaga(action) {
  try {
    yield axios.patch(
      `${INVESTOR_GROUP_API}/${action.userData.id}`,
      action.userData
    );
    let obj = {
      search: "",
      pageLimit: 1,
    };
    yield call(frontEndUsersGroupSaga, obj);
  } catch (error) {
    yield put(frontEndUsersFail(error.response));
  }
}

export function* frontEndUsersByGroupSaga(action) {
  yield put(frontEndUsersStart());
  try {
    const response = yield axios.get(
      `${INVESTOR_GROUP_API}/get-active-investors/${action.id}`,
      {
        params: {
          ...(action.search !== "" && {
            search: action.search,
          }),
          limit: 10 * action.pageLimit,
        },
      }
    );
    yield put(frontEndUsersByGroupSuccess(response.data.data.data));
  } catch (error) {
    yield put(frontEndUsersFail(error.response));
  }
}

export function* addFrontEndUsersInGroupSaga(action) {
  try {
    yield axios.post(
      `${INVESTOR_GROUP_API}/add-member/${action.userData.id}`,
      action.userData
    );
    yield put(addFrontEndUsersInGroupSuccess());
  } catch (error) {
    yield put(frontEndUsersFail(error.response));
  }
}

export function* getAllMembersInvestorGroupsSaga(action) {
  yield put(frontEndUsersStart());
  try {
    const response = yield axios.get(
      `${INVESTOR_GROUP_API}/members-list/${action.id}`,
      {
        params: {
          ...(action.search !== "" && {
            search: action.search,
          }),
          limit: 10 * action.pageLimit,
        },
      }
    );
    yield put(getAllMembersInvestorGroupsSuccess(response.data.data.data));
  } catch (error) {
    yield put(frontEndUsersFail(error.response));
  }
}

export function* getAllUpcomingCampaignGroupsSaga(action) {
  yield put(frontEndUsersStart());
  try {
    const response = yield axios.get(
      `${INVESTOR_GROUP_API}/campaign-list/${action.id}`,
      {
        params: {
          ...(action.search !== "" && {
            search: action.search,
          }),
          limit: 10 * action.pageLimit,
        },
      }
    );
    yield put(getAllUpcomingCampaignGroupsSuccess(response.data.data.data));
  } catch (error) {
    yield put(frontEndUsersFail(error.response));
  }
}

export function* upcomingCampaignByGroupSaga(action) {
  yield put(frontEndUsersStart());
  try {
    const response = yield axios.get(
      `${INVESTOR_GROUP_API}/get-upcoming-campaigns/${action.id}`,
      {
        params: {
          ...(action.search !== "" && {
            search: action.search,
          }),
          limit: 10 * action.pageLimit,
        },
      }
    );
    yield put(upcomingCampaignByGroupSuccess(response.data.data.data));
  } catch (error) {
    yield put(frontEndUsersFail(error.response));
  }
}

export function* addCampaignsInGroupSaga(action) {
  try {
    yield axios.post(
      `${INVESTOR_GROUP_API}/assign-campaign/${action.campaignData.id}?lng=en`,
      action.campaignData
    );
    yield put(addCampaignsInGroupSuccess());
  } catch (error) {
    yield put(frontEndUsersFail(error.response.data.message));
  }
}

export function* deleteMembersInvestorGroupSaga(action) {
  try {
    yield axios.delete(`${INVESTOR_GROUP_API}/members-list/${action.memberId}`);
    let obj = {
      id: action.id,
      search: "",
      pageLimit: 1,
    };
    yield call(getAllMembersInvestorGroupsSaga, obj);
  } catch (error) {
    yield put(frontEndUsersFail(error.response));
  }
}

export function* earlyBirdInvestorRequestSaga(action) {
  yield put(frontEndUsersStart());
  try {
    const response = yield axios.get(
      `${INVESTOR_GROUP_API}/get-all-pending-investors`,
      {
        params: {
          ...(action.search !== "" && {
            search: action.search,
          }),
          limit: 10 * action.pageLimit,
        },
      }
    );
    yield put(earlyBirdInvestorRequestSuccess(response.data.data.data));
  } catch (error) {
    yield put(frontEndUsersFail(error.response));
  }
}

export function* addEarlyBirdInvestorRequestSaga(action) {
  try {
    yield axios.patch(
      `${INVESTOR_GROUP_API}/update-member-request/${action.userData.id}?lng=en`,
      action.userData
    );
    let obj = {
      search: "",
      pageLimit: 1,
    };
    yield call(earlyBirdInvestorRequestSaga, obj);
  } catch (error) {
    yield put(frontEndUsersFail(error.response.data.message));
  }
}
