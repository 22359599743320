export const GET_CHILDREN_REGISTRATION_START =
  "GET_CHILDREN_REGISTRATION_START";
export const START_ISLOADING = "START_ISLOADING";

export const GET_OMANI_CHILDREN_REGISTRATION =
  "GET_OMANI_CHILDREN_REGISTRATION";
export const GET_OMANI_CHILDREN_REGISTRATION_SUCCESS =
  "GET_OMANI_CHILDREN_REGISTRATION_SUCCESS";

export const GET_OMANI_CHILDREN_REGISTRATION_DETAIL =
  "GET_OMANI_CHILDREN_REGISTRATION_DETAIL";
export const GET_OMANI_CHILDREN_REGISTRATION_DETAIL_SUCCESS =
  "GET_OMANI_CHILDREN_REGISTRATION_DETAIL_SUCCESS";
export const GET_FOREIGN_CHILDREN_REGISTRATION =
  "GET_FOREIGN_CHILDREN_REGISTRATION";
export const GET_FOREIGN_CHILDREN_REGISTRATION_SUCCESS =
  "GET_FOREIGN_CHILDREN_REGISTRATION_SUCCESS";
export const GET_FOREIGN_CHILDREN_REGISTRATION_DETAIL =
  "GET_FOREIGN_CHILDREN_REGISTRATION_DETAIL";
export const GET_FOREIGN_CHILDREN_REGISTRATION_DETAIL_SUCCESS =
  "GET_FOREIGN_CHILDREN_REGISTRATION_DETAIL_SUCCESS";
export const UPDATE_CHILDREN_REGISTRATION_DETAIL =
  "UPDATE_CHILDREN_REGISTRATION_DETAIL";
export const UPDATE_CHILDREN_REGISTRATION = "UPDATE_CHILDREN_REGISTRATION";

export const UPDATE_CHILDREN_REGISTRATION_SUCCESS =
  "UPDATE_CHILDREN_REGISTRATION_SUCCESS";
export const UPDATE_CHILDREN_REGISTRATION_FAIL =
  "UPDATE_CHILDREN_REGISTRATION_FAIL";
